/* eslint-disable  */
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography
} from '@material-ui/core';

const FreePlanUpgradeDialog = ({ open, onClose, word, page }) => {
  const handleClose = () => {
    onClose(); // ダイアログを閉じる
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>アップグレードが必要です</DialogTitle>
      <DialogContent>
        <Typography>
          {word ? word : '商品'}を
          {page == 'seoResultDialog' ? '追加登録する' : '追加登録、削除する'}
          には有料プランへのお申込みが必要です。
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          とじる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FreePlanUpgradeDialog;
