/* eslint-disable*/
import * as React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch } from 'react-redux';
import 'moment/locale/ja';
import theme from 'theme';
import { makeStyles } from '@material-ui/styles';
import {
  changePerformanceTerm,
  changePerformanceTermList,
  fetchKeywordGraph,
  fetchReviewReportChangeTerm,
  performancereviewTerm
} from 'feactures/performancereview/performancereviewSlice';

const useStyles = makeStyles({
  customSelect: {
    color: '#575757',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: '#E6E6E6'
    }
  },
  formControl: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[400],
        borderWidth: 1
      }
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400]
    }
  }
});

const PerformancePullDown = (props) => {
  const dispatch = useDispatch();
  const { graphLoading, page } = props;
  const selectTerm = useSelector(performancereviewTerm);

  const getMonthList = (startMonth) => {
    const months = [];
    const [startYear, startMonthNum] = startMonth.split('-').map(Number);

    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1; // Months are zero-based, so we add 1

    let year = startYear;
    let month = startMonthNum;

    while (
      year < currentYear ||
      (year === currentYear && month <= currentMonth)
    ) {
      months.push(`${year}-${String(month).padStart(2, '0')}`);
      month += 1;
      if (month > 12) {
        month = 1;
        year += 1;
      }
    }

    return months;
  };

  const selectMonth = getMonthList('2024-11'); //選択年月リスト

  const getPastMonths = (baseMonth, numMonths) => {
    const months = [];
    const [year, month] = baseMonth.split('-').map(Number);
    for (let i = 0; i < numMonths; i++) {
      const date = new Date(year, month - 1 - i, 1);
      months.push(
        `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
      );
    }
    return months.reverse();
  };

  const handleChange = (e) => {
    e.stopPropagation();
    const newTerm = e.target.value;
    dispatch(changePerformanceTerm(newTerm));
    const updatedTermList = ['', ...getPastMonths(newTerm, 3)];
    dispatch(changePerformanceTermList(updatedTermList));
    if (page == 'search') {
      dispatch(fetchKeywordGraph());
    } else {
      dispatch(fetchReviewReportChangeTerm({ section: 'own' }));
      dispatch(fetchReviewReportChangeTerm({ section: 'other' }));
    }
  };

  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <Select
        className={classes.customSelect}
        value={selectTerm}
        onChange={handleChange}
        onClick={(e) => e.stopPropagation()}
        disabled={graphLoading}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          margin: 0,
          padding: 0,
          width: 110,
          height: 30,
          backgroundColor: '#FFFFFF',
          fontSize: '14px'
        }}
      >
        {selectMonth.map((yearMonth, index) => {
          return (
            <MenuItem value={yearMonth} key={index}>
              {yearMonth}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default PerformancePullDown;
