/* eslint-disable*/
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  CircularProgress,
  Modal
} from '@material-ui/core'; // eslint-disable-line
import NewButtongroup from 'components/atoms/group/NewButtongroup';
import NewPullDown from 'components/atoms/button/NewPullDown';
import {
  categorygraphStatus,
  fetchCategoryBrandGraphData
} from 'feactures/brand/categoryBrandGraphSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import { userinfo } from 'feactures/user/userSlice';
import {
  categoryLabel,
  currentAcate1,
  currentRcate1,
  currentYcate1
} from 'feactures/category/categoryGraphSlice';
import {
  amazonCategory,
  rakutenCategory,
  yahooCategory
} from 'feactures/category/categorySlice';
import {
  fetchFavoriteCategory,
  favoriteCategoryStatus
} from 'feactures/category/favoriteCategorySlice';
import StickyAnnotation from 'components/atoms/annotation/StickyAnnotation';
import NewDialogSelect from 'components/atoms/select/NewDialogSelect';
import FavoriteCategoryPullDown from 'components/atoms/button/FavoriteCategoryPullDown';
import DialogCheckbox from 'components/atoms/select/DialogCheckbox';
import BrandItemShare from 'components/organisms/content/brand/BrandItemShare';
import {
  categoryOtherBrandShareStatus,
  categoryOwnBrandgraphShareStatus,
  fetchCategoryBrandShareGraphData,
  fetchCategoryOwnBrandShareGraphData
} from 'feactures/brand/categoryBrandShareSlice';
import BrandCompare from 'components/organisms/content/brand/BrandCompare';
import OwnBrandItemShare from 'components/organisms/content/brand/OwnBrandItemShare';
import Top10Table from 'components/organisms/content/brand/Top10Table';
import { fetchOwnSales, ownSalesLoading } from 'feactures/measure/measureSlice';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { changePage } from 'feactures/date/dateSlice';
import FreePlanRegisterModal from 'components/atoms/modal/FreePlanRegisterModal';

const BrandComparison = ({
  freePlanRegisterModalOpen,
  updateFreePlanRegisterInfosSuccessFlag
}) => {
  const channel = useSelector(currentchannel);
  const userInfo = useSelector(userinfo);
  const ownSalesGraphLoading = useSelector(ownSalesLoading);
  const graphloading = useSelector(categorygraphStatus); //こっちの方がloading時間が長いので一旦こっち
  const otherShareLoading = useSelector(categoryOtherBrandShareStatus);
  const ownShareLoading = useSelector(categoryOwnBrandgraphShareStatus);
  // const brandloading = useSelector(brandLoading);
  const currentSelectAmazonCategory1 = useSelector(currentAcate1);
  const currentSelectRakutenCategory1 = useSelector(currentRcate1);
  const currentSelectYahooCategory1 = useSelector(currentYcate1);
  const amazonCategories = useSelector(amazonCategory);
  const rakutenCategories = useSelector(rakutenCategory);
  const yahooCategories = useSelector(yahooCategory);
  const favoriteCategoryLoading = useSelector(favoriteCategoryStatus);
  // const getBrandList = useSelector(brandList);
  const [yokoziku, setYokoziku] = useState([]);
  const dispatch = useDispatch();

  // 戻るボタン押されたとき用
  useEffect(() => {
    dispatch(changePage('brandComparison'));
  }, []);

  // useEffect(() => {
  //   // '選択済みの第一カテゴリがない時のみfetchAsyncCategory1をする
  //   if (
  //     Boolean(
  //       currentSelectAmazonCategory1 ||
  //         currentSelectRakutenCategory1 ||
  //         currentSelectYahooCategory1
  //     ) == false
  //   ) {
  //     dispatch(fetchFavoriteCategory());
  //     // dispatch(fetchAllCategoryGraphData());
  //     // dispatch(fetchCategoryShareGraphData());
  //     // dispatch(fetchOwnCategory());
  //   }
  //   dispatch(fetchCategoryOwnBrandShareGraphData());
  //   dispatch(fetchCategoryBrandShareGraphData());
  //   dispatch(fetchCategoryBrandGraphData());
  //   dispatch(fetchOwnSales());
  // }, [channel]);

  let categories = '';

  if (channel == 1) {
    categories = amazonCategories;
  } else if (channel == 2) {
    categories = rakutenCategories;
  } else if (channel == 3) {
    categories = yahooCategories;
  }

  // let channelBrandList = [];
  // if (channel == 1) {
  //   channelBrandList = getBrandList.amazon;
  // } else if (channel == 2) {
  //   channelBrandList = getBrandList.rakuten;
  // } else if (channel == 3) {
  //   channelBrandList = getBrandList.yahoo;
  // }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '100'
  };

  return (
    <>
      {favoriteCategoryLoading && (
        <Modal open={favoriteCategoryLoading}>
          <Box sx={style}>
            <p>カテゴリ読み込み中...</p>
            <CircularProgress />
          </Box>
        </Modal>
      )}
      <Box
        sx={{
          backgroundColor: '#FFF8E5',
          height: 'auto',
          minHeight: '100vh',
          width: '100%',
          pb: '100px'
        }}
      >
        <Container maxWidth={false} sx={{ width: 'auto', p: 0 }}>
          <Box
            position="sticky"
            top="42px"
            bgcolor="#FFF8E5"
            zIndex={3}
            height="auto"
          >
            {/* {channel == 2 && <StickyAnnotation />} */}
            <Box display="flex" alignItems="center" height="33px">
              <Typography fontSize="14px">マーケットビュー</Typography>
              <ArrowForwardIosOutlinedIcon
                sx={{ fontSize: '16px', mx: 1, color: 'gray' }}
              />
              <Typography fontSize="14px" fontWeight="bold">
                店舗/ブランド比較
              </Typography>
            </Box>
            <Box
              mb={2}
              sx={{
                display: 'flex',
                borderTop: 1,
                borderColor: '#FFFFFF',
                '@media screen and (max-width:1500px)': {
                  display: 'block'
                }
              }}
            >
              <Box display="flex" sx={{ alignItems: 'end' }}>
                <Box mr={3}>
                  <Typography fontSize="14px">&nbsp;</Typography>
                  <NewButtongroup amazonOnly={true} page="brandComparison" />
                </Box>
                {!freePlanRegisterModalOpen && (
                  <>
                    <Box mr={3}>
                      {/* <Typography fontSize="14px">期間</Typography> */}
                      <NewPullDown
                        page="brandComparison"
                        label="期間"
                        setYokoziku={setYokoziku}
                        graphLoading={
                          graphloading ||
                          ownSalesGraphLoading ||
                          ownShareLoading ||
                          otherShareLoading
                        }
                      />
                    </Box>
                  </>
                )}
              </Box>
              {!freePlanRegisterModalOpen && (
                <Box
                  display="flex"
                  sx={{
                    '@media screen and (max-width:1500px)': {
                      mt: 1
                    }
                  }}
                >
                  <Box mr={3}>
                    <Typography fontSize="14px">カテゴリを選択する</Typography>
                    <NewDialogSelect
                      categories={categories}
                      channel={channel}
                      page="brandComparison"
                      graphLoading={
                        graphloading ||
                        ownSalesGraphLoading ||
                        ownShareLoading ||
                        otherShareLoading
                      }
                    />
                  </Box>
                  <Box mr={3}>
                    <Typography fontSize="14px">お気に入りカテゴリ</Typography>
                    <FavoriteCategoryPullDown
                      page="brandComparison"
                      graphLoading={
                        graphloading ||
                        ownSalesGraphLoading ||
                        ownShareLoading ||
                        otherShareLoading
                      }
                    />
                  </Box>
                  <Box mr={3}>
                    <Typography fontSize="14px">自社を選択</Typography>
                    <DialogCheckbox
                      channel={channel}
                      page="brandComparison"
                      section="own"
                      graphLoading={
                        graphloading ||
                        ownSalesGraphLoading ||
                        ownShareLoading ||
                        otherShareLoading
                      }
                    />
                  </Box>
                  <Box mr={3}>
                    <Typography fontSize="14px">競合を選択</Typography>
                    <DialogCheckbox
                      channel={channel}
                      page="brandComparison"
                      section="other"
                      graphLoading={
                        graphloading ||
                        ownSalesGraphLoading ||
                        ownShareLoading ||
                        otherShareLoading
                      }
                    />
                  </Box>
                  {/* <Box>
                  <Typography fontSize="14px">分析単位</Typography>
                  <SectionRadioGroup />
                  </Box> */}
                </Box>
              )}
            </Box>
          </Box>
          {!freePlanRegisterModalOpen ? (
            <>
              <Box mb={2} display="flex" justifyContent="center">
                <Box
                  width="100%"
                  bgcolor="#FFFFFF"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={3}
                  height={260} //仮
                >
                  {graphloading || ownSalesGraphLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <p>グラフの読み込み中…</p>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <BrandCompare yokoziku={yokoziku} />
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                <Box
                  width="48%"
                  bgcolor="#FFFFFF"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={3}
                  height="400px" //仮
                >
                  {ownShareLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <p>グラフの読み込み中…</p>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <OwnBrandItemShare />
                  )}
                </Box>
                <Box
                  width="48%"
                  bgcolor="#FFFFFF"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={3}
                  height="400px" //仮
                >
                  {otherShareLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <p>グラフの読み込み中…</p>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <BrandItemShare />
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="start"
                justifyContent="space-between"
              >
                <Box
                  width="48%"
                  bgcolor="#FFFFFF"
                  display="flex"
                  justifyContent="center"
                  borderRadius={3}
                  minHeight={100}
                >
                  {ownShareLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                    >
                      <p>テーブルの読み込み中…</p>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Top10Table yokoziku={yokoziku} section="自社" />
                  )}
                </Box>
                <Box
                  width="48%"
                  bgcolor="#FFFFFF"
                  display="flex"
                  justifyContent="center"
                  borderRadius={3}
                  minHeight={100}
                >
                  {otherShareLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                    >
                      <p>テーブルの読み込み中…</p>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Top10Table yokoziku={yokoziku} section="競合" />
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <FreePlanRegisterModal
              updateFreePlanRegisterInfosSuccessFlag={
                updateFreePlanRegisterInfosSuccessFlag
              }
              categories={categories}
            />
          )}
        </Container>
      </Box>
    </>
  );
};

export default BrandComparison;
