/* eslint-disable */
import {
  Modal,
  Box,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Button,
  InputLabel
} from '@material-ui/core';
import {
  sellerList,
  sellerLoading,
  setSellerInfo
} from 'feactures/performancereview/performancereviewSlice';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

export default function AmazonSellerModal({ open, setOpen, registerProduct }) {
  const dispatch = useDispatch();
  const seller_list = useSelector(sellerList);
  const isLoading = useSelector(sellerLoading);
  const [sellerId, setSellerId] = useState('');
  const handleClose = () => {
    setSellerId('');
    setOpen(false);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: '350px',
        width: '250px'
      }
    }
  };

  const handleChange = (event) => {
    const selected_seller = seller_list.find(
      (seller) => seller.id === event.target.value
    );
    console.log('selected_seller', selected_seller);
    setSellerId(event.target.value);
    dispatch(
      setSellerInfo({
        id: selected_seller.id,
        name: selected_seller.name
      })
    );
  };

  const handleSubmit = () => {
    registerProduct();
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box
        sx={{
          bgcolor: '#fff',
          width: '40%',
          p: 2
        }}
      >
        <Typography
          fontSize="18px"
          fontWeight="bold"
          color="rgba(113,140,198,1)"
          sx={{ pb: 2 }}
        >
          販売元選択
        </Typography>
        {isLoading ? (
          <>
            <Typography sx={{ pb: 2 }}>
              ※販売元情報取得完了までに10秒程度時間がかかる場合があります。
            </Typography>
            <Box
              sx={{
                height: '200px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          </>
        ) : seller_list.length > 0 ? (
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="select-label">販売元を選択してください</InputLabel>
            <Select
              labelId="select-label"
              label="販売元を選択してください"
              value={sellerId}
              onChange={handleChange}
              MenuProps={MenuProps}
            >
              {seller_list.map((seller, index) => (
                <MenuItem key={index} value={seller.id}>
                  {seller.name}
                </MenuItem>
              ))}
            </Select>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                width: '100%',
                pt: 2,
                gap: '10px'
              }}
            >
              <Button color="inherit" variant="contained" onClick={handleClose}>
                キャンセル
              </Button>
              <Button
                color="groupSelected"
                variant="contained"
                onClick={handleSubmit}
                disabled={sellerId === ''}
              >
                登録
              </Button>
            </Box>
          </FormControl>
        ) : (
          <>
            <Typography sx={{ textAlign: 'center' }}>
              販売元情報の取得に失敗しました。
            </Typography>
            <Typography sx={{ textAlign: 'center' }}>
              お手数ですが、再度商品登録を行ってください。
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                width: '100%',
                pt: 2,
                gap: '10px'
              }}
            >
              <Button color="inherit" variant="contained" onClick={handleClose}>
                OK
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}
