/* eslint-disable*/
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { errorResMessage, setError } from 'feactures/errorSlice';
import {
  Box,
  Typography,
  TableRow,
  TableCell,
  Button,
  Link,
  IconButton,
  Rating
} from '@material-ui/core';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useDispatch, useSelector } from 'react-redux';
import 'chartjs-adapter-moment';
import logo_url from './images/favicon.png';
import {
  currentchannel,
  currentchannelName
} from 'feactures/channel/channelSlice';
import { userinfo } from 'feactures/user/userSlice';
import {
  productIsLoading,
  registerTrackingProduct,
  fetchMeasureAnalysisData,
  reportData,
  rankingProductIsLoading
} from 'feactures/measure/newMeasureSlice';
import FreePlanConfirmationDialog from 'components/atoms/modal/FreePlanConfirmationDialog';

const BodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: '12px',
  textAlign: 'center',
  wordBreak: 'break-all',
  padding: '8px 2px'
}));

const StyledTableRow = styled(TableRow)((props) => ({
  '&:hover': {
    backgroundColor: props.hoverColor
  }
}));

const CustomButton = styled(Button)((props) => ({
  '&:hover': {
    backgroundColor: '#8ba4e7'
  }
}));

export default function RankingProductTableRow({ item, handleClose }) {
  const url = 'https://m.media-amazon.com/images/I/';
  const dispatch = useDispatch();
  const channel = useSelector(currentchannel);
  const channelName = useSelector(currentchannelName);
  const userInfo = useSelector(userinfo);
  const registerLoading = useSelector(productIsLoading);
  const rankingLoading = useSelector(rankingProductIsLoading);
  const trackingProduct = useSelector(reportData);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  console.log(item['img_url']);

  // 既に登録済みの商品かどうかのフラグ
  const trackedFlag = Boolean(
    trackingProduct.find((trackItem) => trackItem.item_id == item['item_id'])
  );

  const registerProduct = async () => {
    dispatch(setError('')); //表示されているエラーをリセット
    await dispatch(
      registerTrackingProduct({
        item_id: item['item_id'],
        item_name: item['title'],
        shop_code: channel == 2 ? item['shop_code'] : ''
      })
    )
      .then(unwrapResult) //registerProductsで登録が成功（fullfilled）のとき
      .then(() => {
        dispatch(fetchMeasureAnalysisData());
        handleClose();
      });
  };

  // ダイアログの登録ボタンを押下したとき
  const registerHandle = async () => {
    if (!userInfo.plan[channelName].premium) {
      // 無料プランの場合は確認ダイアログを表示
      setOpenConfirmationDialog(true);
    } else {
      // 有料プラン
      registerProduct();
    }
  };

  const handleConfirmationClose = (isConfirmed) => {
    if (isConfirmed) {
      registerProduct();
    }
    setOpenConfirmationDialog(false);
  };

  return (
    <>
      <StyledTableRow hoverColor={'#f5f5f5'} key={item.id}>
        <BodyCell>
          <Box
            sx={{
              width: '70px',
              height: '70px',
              borderRadius: '4px',
              mx: 'auto'
            }}
          >
            <img
              src={
                item['img_url'] === '' ||
                item['img_url'] === '-' ||
                item['img_url'] === 0
                  ? logo_url
                  : channel == 1
                  ? url + item['img_url']
                  : item['img_url']
              }
              height="100%"
              loading="lazy"
              width="100%"
            />
          </Box>
        </BodyCell>
        <BodyCell>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                textAlign: 'left'
              }}
            >
              <Link
                sx={{
                  fontSize: '12px',
                  textDecoration: 'none'
                }}
                href={
                  item['item_id'] === 0 || item['shop_code'] === 0
                    ? ''
                    : channel === 1
                    ? `https://www.amazon.co.jp/dp/${item['item_id']}`
                    : `https://item.rakuten.co.jp/${item['shop_code']}/${item['item_id']}/`
                }
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                {item['title'] == '0' ? 'データ無し' : item['title']}
              </Link>
            </Box>
            <Typography sx={{ textAlign: 'left', mt: 1 }}>
              {item['shop_code']}:{item['item_id']}
            </Typography>
          </Box>
        </BodyCell>
        <BodyCell>{item['category'] === '0' ? '-' : item['category']}</BodyCell>
        <BodyCell>
          {item['price'] === 0 ? '-' : `¥${item['price'].toLocaleString()}`}
        </BodyCell>
        <BodyCell>
          {item['rank'] === 0
            ? '-'
            : item['rank'] === -99
            ? '圏外'
            : `${item['rank']}位`}
        </BodyCell>
        {channel == 2 && (
          <>
            <BodyCell sx={{ textAlign: 'right' }}>
              <Rating
                value={item['review']}
                precision={0.5}
                readOnly
                size="small"
              />
            </BodyCell>
            <BodyCell sx={{ textAlign: 'left' }}>
              {item['review_num'].toLocaleString()}
            </BodyCell>
          </>
        )}
        <BodyCell>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Button
              variant={trackedFlag ? 'contained' : 'outlined'}
              sx={{
                borderRadius: '4px',
                minWidth: 130
              }}
              startIcon={<RemoveRedEyeIcon />}
              onClick={registerHandle}
              disabled={registerLoading || rankingLoading}
            >
              {trackedFlag ? '登録済み' : '商品登録'}
            </Button>
          </Box>
        </BodyCell>
      </StyledTableRow>

      {/* 無料プラン確認ダイアログ */}
      <FreePlanConfirmationDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={handleConfirmationClose}
      />
    </>
  );
}
