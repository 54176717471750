/* eslint-disable */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchAsyncOtherproducts = createAsyncThunk(
  'otherproducts/get',
  async (_, thunkAPI) => {
    const { accountId, plan } = thunkAPI.getState().user;
    const { yearMonth, kubun } = thunkAPI.getState().currentsection;
    const { channel, channelName } = thunkAPI.getState().currentchannel;
    const res = await client.post(`${apiUrl}other_products`, {
      channel: channel,
      account_id: accountId,
      sales_type: kubun,
      select_month: yearMonth,
      channel_name: channelName,
      premium: plan[channelName].premium
    });
    return res.data;
  }
);

const fetchOtherproductsSlice = createSlice({
  name: 'otherproducts',
  initialState: {
    otherproducts: [[], [], []],
    graphdata: [0, 0, 0],
    totalsalesData: [0, 0, 0],
    totalunitData: [0, 0, 0],
    lastmonthRate: [0, 0, 0],
    lastyearRate: [0, 0, 0]
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncOtherproducts.fulfilled, (state, action) => {
      return {
        ...state,
        otherproducts: [
          action.payload.amazon_product_list,
          action.payload.rakuten_product_list,
          action.payload.yahoo_product_list
        ],
        graphdata: [
          action.payload.amazon_otherproducts_graphdata,
          action.payload.rakuten_otherproducts_graphdata,
          action.payload.yahoo_otherproducts_graphdata
        ],
        totalsalesData: [
          action.payload.amazon_otherproducts_total_sales,
          action.payload.rakuten_otherproducts_total_sales,
          action.payload.yahoo_otherproducts_total_sales
        ],
        totalunitData: [
          action.payload.amazon_otherproducts_total_unit,
          action.payload.rakuten_otherproducts_total_unit,
          action.payload.yahoo_otherproducts_total_unit
        ],
        lastmonthRate: [
          action.payload.amazon_otherproducts_lastmonth_rate,
          action.payload.rakuten_otherproducts_lastmonth_rate,
          action.payload.yahoo_otherproducts_lastmonth_rate
        ],
        lastyearRate: [
          action.payload.amazon_otherproducts_lastyear_rate,
          action.payload.rakuten_otherproducts_lastyear_rate,
          action.payload.yahoo_otherproducts_lastyear_rate
        ],
        isLoading: false
      };
    });
    builder.addCase(fetchAsyncOtherproducts.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(fetchAsyncOtherproducts.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    });
  }
});

export const selectOtherproducts = (state) => state.otherproducts.otherproducts;
export const selectGraphdata = (state) => state.otherproducts.graphdata;
export const selectSalesFigures = (state) => state.otherproducts.totalsalesData;
export const selectUnitFigures = (state) => state.otherproducts.totalunitData;
export const selectLastMonthRate = (state) => state.otherproducts.lastmonthRate;
export const selectLastYearRate = (state) => state.otherproducts.lastyearRate;
export const otherproductsStatus = (state) => state.otherproducts.isLoading;

export default fetchOtherproductsSlice.reducer;
