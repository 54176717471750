/* eslint-disable  */
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography
} from '@material-ui/core';

const FreePlanConfirmationDialog = ({ open, onClose, onConfirm, word }) => {
  const handleCancel = () => {
    onClose(); // キャンセルボタンでダイアログを閉じる
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      sx={{
        '& .MuiDialog-container': {
          zIndex: 1500 // 高い zIndex を設定して、他のダイアログより前面に表示
        }
      }}
    >
      <DialogTitle>確認</DialogTitle>
      <DialogContent>
        <Typography>
          無料プランでは1{word ? word : '商品'}
          しか登録できません。本当にこの{word ? word : '商品'}
          で登録してもよろしいですか？
          <br />
          ※登録後は変更できませんのでご注意ください。
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          キャンセル
        </Button>
        <Button onClick={() => onConfirm(true)} color="primary">
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FreePlanConfirmationDialog;
