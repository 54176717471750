/* eslint-disable */
import { Bar } from 'react-chartjs-2';
import { Box, Card, CardContent, useTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';

const BrandShareChangeGraph = (props) => {
  const { graphdata, yokoziku } = props; // eslint-disable-line

  const theme = useTheme();
  const bgColorList = [
    '#738DC4',
    '#D0D8ED',
    '#B7C2E2',
    '#5C71A6',
    '#9FAED7',
    '#84A0CE'
  ];

  const combined = graphdata.map((data, i) => ({
    backgroundColor: data.label == 'その他' ? '#A0A0A0' : bgColorList[i],
    ...data
  }));

  const data = {
    labels: yokoziku,
    datasets: combined.reverse()
  };
  const options = {
    interaction: {
      mode: 'nearest',
      intersect: false
    },
    animation: true,
    layout: { padding: 0 },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          fontColor: theme.palette.text.secondary,
          font: {
            family: "'M PLUS 1p', sans-serif",
            size: '10px'
          }
        },
        grid: {
          display: false,
          drawBorder: false
        },
        title: {
          display: false
        }
      },
      y: {
        stacked: true,
        ticks: {
          fontColor: theme.palette.text.secondary,
          font: {
            family: "'M PLUS 1p', sans-serif"
          },
          beginAtZero: true,
          min: 1
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const datasetLabel = context.dataset.label || ''; // データセットのラベル名
            const value = context.raw; // 値を取得
            return `${datasetLabel}: ${value}％`; // "要素名：〇％" の形で返す
          }
        },
        titleFont: {
          family: "'M PLUS 1p', sans-serif"
        },
        bodyFont: {
          family: "'M PLUS 1p', sans-serif"
        }
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 10,
          boxHeight: 10
        }
      },
      datalabels: {
        display: false
      }
    }
  };
  // prettier-ignore
  return (
    <Card {...props} sx={{padding: 0}}>
      <CardContent sx={{padding: 0, display:"flex", justifyContent: "center", alignItems:'center', ":last-child":{pb: 0}}}>
        <Box
          sx={{
            height: 300,
            width: "100%"
           
          }}
        >
          <Bar
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default BrandShareChangeGraph;
