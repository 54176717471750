/* eslint-disable*/
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography
} from '@material-ui/core';
import PerformancePullDown from 'components/atoms/button/PerformancePullDown';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  performanceModalOpen,
  performancereviewAmazonOtherItemData,
  performancereviewAmazonOwnItemData,
  performancereviewOtherDataLoading,
  performancereviewOwnDataLoading,
  performancereviewRakutenOtherItemData,
  performancereviewRakutenOwnItemData,
  setPerformanceModalOpen
} from 'feactures/performancereview/performancereviewSlice';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const PriceResultDialog = (props) => {
  const { index } = props;
  const dispatch = useDispatch();
  const modalOpen = useSelector(performanceModalOpen);
  const ownLoading = useSelector(performancereviewOwnDataLoading);
  const otherLoading = useSelector(performancereviewOtherDataLoading);
  const ownAmazonData = useSelector(performancereviewAmazonOwnItemData);
  const ownRakutenData = useSelector(performancereviewRakutenOwnItemData);
  const otherAmazonData = useSelector(performancereviewAmazonOtherItemData);
  const otherRakutenData = useSelector(performancereviewRakutenOtherItemData);
  const channel = useSelector(currentchannel);
  let ownData = [];
  let otherData = [];
  if (channel == 1) {
    ownData = ownAmazonData;
    otherData = otherAmazonData;
  } else if (channel == 2) {
    ownData = ownRakutenData;
    otherData = otherRakutenData;
  }

  const priceGap = otherData.price - ownData.price;

  let priceCompare = '';
  if (priceGap < 0) {
    priceCompare = '高い';
  } else if (priceGap > 0) {
    priceCompare = '安い';
  }

  return (
    <>
      <Button
        color="groupSelected"
        variant="contained"
        onClick={(event) => {
          event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
          dispatch(setPerformanceModalOpen({ index, open: true })); // モーダルを開く
        }}
        size="small"
        sx={{ minWidth: '50px' }}
      >
        詳細
      </Button>
      <Dialog
        open={modalOpen.open && index == modalOpen.index}
        onClose={(event) => {
          event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
          dispatch(setPerformanceModalOpen({ index: 0, open: false })); // モーダルを閉じる
        }}
        onClick={(event) => {
          event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
        }}
        maxWidth="60％"
      >
        <Box p={3}>
          <Typography
            color="rgba(113,140,198,1)"
            fontWeight="bold"
            fontSize="17px"
            pb={2}
          >
            価格の詳細
          </Typography>
          <PerformancePullDown graphLoading={ownLoading || otherLoading} />
          {ownLoading || otherLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="345px"
              height="200px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box display="flex" alignItems="start" gap={3} p={2}>
              <Box>
                <Typography fontSize="15px" fontWeight="bold">
                  自社平均価格
                </Typography>
                <Typography fontSize="25px" fontWeight="bold" pl={2}>
                  {ownData.price.toLocaleString()}円
                </Typography>
                {otherData.price != 0 && priceGap != 0 && (
                  <Typography color="text.secondary">
                    競合より{Math.abs(priceGap).toLocaleString()}円
                    {priceCompare}
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography fontSize="15px" fontWeight="bold">
                  競合平均価格
                </Typography>
                {otherData.price == 0 ? (
                  <Typography fontSize="25px" fontWeight="bold" pl={2}>
                    -
                  </Typography>
                ) : (
                  <Typography fontSize="25px" fontWeight="bold" pl={2}>
                    {otherData.price.toLocaleString()}円
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography fontSize="15px" fontWeight="bold">
                  トップ5商品の平均価格
                </Typography>
                <Typography fontSize="25px" fontWeight="bold" pl={2}>
                  {ownData.categoryTop5Price.toLocaleString()}円
                </Typography>
              </Box>
            </Box>
          )}
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              onClick={(event) => {
                event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
                dispatch(setPerformanceModalOpen({ index: 0, open: false })); // モーダルを閉じる
              }}
              variant="contained"
              color="groupSelected"
            >
              閉じる
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default PriceResultDialog;
