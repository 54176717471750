/* eslint-disable*/
import { Box, Button, Typography } from '@material-ui/core';
import { Dialog } from '@mui/material';
import { changePage, changePageflag } from 'feactures/date/dateSlice';
import { setPerformanceModalOpen } from 'feactures/performancereview/performancereviewSlice';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const NavigationDialog = (props) => {
  const { path, page } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let pageName;
  if (page == 'measureanalysis') {
    pageName = '競合施策分析';
  }
  const [navigationOpen, setNavigationOpen] = useState(false);

  const resetBodyScroll = () => {
    document.body.style.overflow = ''; // スクロールを再有効化
  };
  return (
    <div>
      <Button
        onClick={(event) => {
          event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
          setNavigationOpen(true);
        }}
        variant="contained"
        color="groupSelected"
      >
        競合施策分析
      </Button>
      <Dialog
        open={navigationOpen}
        onClose={(event) => {
          event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
          setNavigationOpen(false); // モーダルを閉じる
        }}
        onClick={(event) => {
          event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
        }}
        maxWidth="60％"
      >
        <Box p={3}>
          <Typography fontWeight="bold" fontSize="15px" pb={2}>
            {pageName}の画面に遷移します
          </Typography>
          <Typography color="text.secondary" fontSize="13px" pb={1}>
            {pageName}の画面に遷移しますか？
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              variant="contained"
              color="groupNoselected"
              onClick={(event) => {
                event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
                setNavigationOpen(false);
              }}
              sx={{ mr: 1 }}
            >
              いいえ
            </Button>
            <Button
              variant="contained"
              color="groupSelected"
              onClick={(event) => {
                event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
                setNavigationOpen(false);
                dispatch(setPerformanceModalOpen({ index: 0, open: false }));
                setTimeout(() => {
                  navigate(`/app${path}`);
                  dispatch(changePageflag(page));
                  dispatch(changePage(page));
                }, 300); //モーダルが完全に閉じるまで待つ
              }}
            >
              はい
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default NavigationDialog;
