/* eslint-disable*/

import { Container, Grid, colors, Typography } from '@material-ui/core'; // eslint-disable-line
import { useSelector } from 'react-redux';
import {
  selectAmazonCategoryGraph,
  selectRakutenCategoryGraph,
  selectYahooCategoryGraph,
  categorygraphStatus
} from 'feactures/category/categoryGraphSlice';
// import {
//   amazonCategory,
//   rakutenCategory,
//   yahooCategory
// } from 'feactures/category/categorySlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import CategoryTotalgraph from 'components/molecules/dashboard/CategoryTotalgraph';
import CategoryTotalSales from 'components/molecules/dashboard/CategoryTotalSales';
import { useState } from 'react';

const CategoryTotal = (props) => {
  // 実際のデータはuseEffectで取得しreduxへ
  const { yokoziku } = props;
  let categoryAmazonGraphData = useSelector(selectAmazonCategoryGraph);
  let categoryRakutenGraphData = useSelector(selectRakutenCategoryGraph);
  const categoryYahooGraphData = useSelector(selectYahooCategoryGraph);
  // const amazonCategories = useSelector(amazonCategory);
  // const rakutenCategories = useSelector(rakutenCategory);
  // const yahooCategories = useSelector(yahooCategory);
  const loading = useSelector(categorygraphStatus);
  const channel = useSelector(currentchannel);
  let yoko = [...yokoziku];
  const [tanni, setTanni] = useState('億円');

  let categoryGraphData = {
    graphdata: [],
    totalsalesData: '',
    totalunitData: '',
    selectCategoryLabel: '',
    lastmonthRate: '',
    lastyearRate: ''
  };
  let mall = '';
  let color = '';
  let categories = '';

  if (channel == 1) {
    // Amazonは2023/2以前は表示させない
    const targetDate = '2023/2';
    const index = yokoziku.findIndex((date) => date === targetDate);
    if (index !== -1 && categoryAmazonGraphData.graphdata.length > 0) {
      yoko.splice(0, index + 1);
      let newData = JSON.parse(JSON.stringify(categoryAmazonGraphData)); // オブジェクト全体をコピー
      newData.graphdata.splice(0, index + 1); // graphdataを変更
      categoryAmazonGraphData = newData; // 新しいオブジェクトを代入
    }

    categoryGraphData = categoryAmazonGraphData;
    // mall = 'Amazon';
    // color = colors.amber[700];
    // categories = amazonCategories;
    // console.log(categoryGraphData);
  } else if (channel == 2) {
    // 楽天は2023/7以前は表示させない
    const targetDate = '2023/7';
    const index = yokoziku.findIndex((date) => date === targetDate);
    if (index !== -1 && categoryRakutenGraphData.graphdata.length > 0) {
      yoko.splice(0, index + 1);
      let newData = JSON.parse(JSON.stringify(categoryRakutenGraphData)); // オブジェクト全体をコピー
      newData.graphdata.splice(0, index + 1); // graphdataを変更
      categoryRakutenGraphData = newData; // 新しいオブジェクトを代入
    }

    categoryGraphData = categoryRakutenGraphData;
    // mall = '楽天市場';
    // color = '#bf0000';
    // categories = rakutenCategories;
    // console.log(categoryGraphData);
  } else if (channel == 3) {
    categoryGraphData = categoryYahooGraphData;
    // mall = 'Yahoo';
    // color = '#FF0027';
    // categories = yahooCategories;
  }

  return (
    <>
      <Container maxWidth={false}>
        <Grid container rowSpacing={0.3} columnSpacing={1.5}>
          <Grid item xs={12}>
            <Typography fontSize="14px" fontWeight="bold">
              市場規模推移
            </Typography>
            {/* <Typography fontSize="12px" color={'gray'} sx={{ mt: 1 }}>
              月次の市場規模（単位：{tanni}）
            </Typography> */}
          </Grid>
          <Grid item xs={12} container justifyContent="center" mb={1}>
            <CategoryTotalSales sales={categoryGraphData.totalsalesData} />
          </Grid>
          <Grid item xs={12}>
            {/* 棒グラフ */}
            <CategoryTotalgraph
              // mall={mall}
              graphdata={categoryGraphData.graphdata}
              // color={color}
              // categories={categories}
              // channel={channel}
              yokoziku={yoko}
              setTanni={setTanni}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CategoryTotal;
