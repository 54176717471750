/* eslint-disable*/
import { Box, Container, Typography } from '@material-ui/core';
import StickyAnnotation from 'components/atoms/annotation/StickyAnnotation';
import KPIButtongroup from 'components/atoms/group/KPIButtonGroup';
import DatePicker from '../../atoms/button/DatePicker';
import React, { useState, useEffect } from 'react';
import BreadList from 'components/atoms/list/BreadList';
import SalesshareByMall from 'components/organisms/content/overview/SalesshareByMall';
import SalestrendsByMall from 'components/organisms/content/overview/SalestrensByMall';
import SalesViewer from 'components/organisms/content/overview/SalesViewer';
import KpiDatePicker from 'components/atoms/button/KpiDatePicker';
import ItemSalesList from 'components/organisms/content/overview/ItemSalesList';
import AddKpiGroupButton from 'components/atoms/button/AddKpiGroupButton';
import OverviewGroupTable from 'components/organisms/content/overview/OverviewGroupTable';
import OverviewGroupShare from 'components/organisms/content/overview/OverviewGroupShare';
import { groups } from 'feactures/monitoring/groupSlice';
import { changePage } from 'feactures/date/dateSlice';
import {
  registerAllMall,
  registerAmazon,
  registerRakuten,
  registerYahoo,
  currentchannel
} from 'feactures/channel/channelSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { userinfo } from 'feactures/user/userSlice';
import ConnectionDialog from 'components/atoms/modal/ConnectionDialog';
import ChannelStatusDialog from 'components/atoms/modal/ChannelStatusDialog';
import StickySurvey from 'components/atoms/survey/StickySurvey';
import NoticeDialog from 'components/atoms/notice/NodiceDialog';

const TotalSales = () => {
  const [term, setTerm] = useState([]);
  const groupList = useSelector(groups);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector(userinfo);
  const channel = useSelector(currentchannel);

  // 戻るボタン押されたとき用
  useEffect(() => {
    dispatch(changePage('overview'));
  }, []);

  const Overview = (props) => {
    const { groupList } = props;
    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          my={2}
        >
          <Box width="49%" display="flex" flexDirection="column" rowGap={1}>
            <Box
              bgcolor="#ffffff"
              height="100px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius={3}
              border={1}
              borderColor="#D9D9D9"
              onClick={() => {
                dispatch(registerAllMall());
                navigate('/app/monitoring');
              }}
              sx={{ cursor: 'pointer' }}
            >
              <SalesViewer channel={0} />
            </Box>
            <Box
              bgcolor="#ffffff"
              height="100px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius={3}
              border={1}
              borderColor="#D9D9D9"
              onClick={() => {
                dispatch(registerAmazon());
                navigate('/app/monitoring');
              }}
              sx={{ cursor: 'pointer' }}
            >
              <SalesViewer channel={1} />
            </Box>
            <Box
              bgcolor="#ffffff"
              height="100px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius={3}
              border={1}
              borderColor="#D9D9D9"
              onClick={() => {
                dispatch(registerRakuten());
                navigate('/app/monitoring');
              }}
              sx={{ cursor: 'pointer' }}
            >
              <SalesViewer channel={2} />
            </Box>
            <Box
              bgcolor="#ffffff"
              height="100px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius={3}
              border={1}
              borderColor="#D9D9D9"
              onClick={() => {
                dispatch(registerYahoo());
                navigate('/app/monitoring');
              }}
              sx={{ cursor: 'pointer' }}
            >
              <SalesViewer channel={3} />
            </Box>
          </Box>
          <Box width="49%" display="flex" flexDirection="column" rowGap={1}>
            <Box
              bgcolor="#ffffff"
              height="208px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius={3}
              border={1}
              borderColor="#D9D9D9"
            >
              <SalesshareByMall />
            </Box>
            <Box
              bgcolor="#ffffff"
              height="208px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius={3}
              border={1}
              borderColor="#D9D9D9"
            >
              <SalestrendsByMall />
            </Box>
          </Box>
        </Box>
        {groupList.length === 0 ? (
          <Box mb={2} display="flex" justifyContent="center">
            <AddKpiGroupButton />
          </Box>
        ) : (
          <Box mb={2} display="flex" justifyContent="center">
            <Box
              width="100%"
              bgcolor="#FFFFFF"
              borderRadius={3}
              border={1}
              borderColor="#D9D9D9"
              p={2}
              display="flex"
              justifyContent="space-between"
            >
              <OverviewGroupTable />
              <OverviewGroupShare />
            </Box>
          </Box>
        )}
        <Box mb={2} display="flex" justifyContent="center">
          <Box
            width="100%"
            bgcolor="#FFFFFF"
            borderRadius={3}
            border={1}
            borderColor="#D9D9D9"
            p={1}
          >
            <ItemSalesList pathName={'overview'} />
          </Box>
        </Box>
      </>
    );
  };
  return (
    <Box
      sx={{
        backgroundColor: '#FFF8E5',
        height: 'auto',
        minHeight: '100vh',
        width: '100%',
        pb: '80px'
      }}
    >
      {/* {(userInfo.trial.trial_notice || userInfo.trial.advertise) && (
        <NoticeDialog />
      )} */}
      <Container maxWidth={false} sx={{ width: 'auto', p: 0 }}>
        <Box
          position="sticky"
          top="42px"
          bgcolor="#FFF8E5"
          zIndex={3}
          height="auto"
        >
          {/* <StickyAnnotation /> */}
          <BreadList />
          <Box
            sx={{
              display: 'flex',
              borderTop: 1,
              borderColor: '#FFFFFF',
              alignItems: 'flex-end'
            }}
          >
            <Box mr={3}>
              <KpiDatePicker setTerm={setTerm} page={'overview'} pageNum={1} />
            </Box>
            <Box>
              {/* <Typography fontSize="12px">モール</Typography> */}
              <KPIButtongroup amazonOnly={true} page="overview" />
            </Box>
          </Box>
        </Box>
        {channel == 1 && (
          <>
            {userInfo.amazonStatus >= 4 ? (
              <>
                {/* <StickySurvey /> */}
                <Overview groupList={groupList} />
              </>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <ChannelStatusDialog />
              </Box>
            )}
          </>
        )}
        {channel == 2 && (
          <>
            {userInfo.rakutenStatus >= 7 ? (
              <>
                {/* <StickySurvey /> */}
                <Overview groupList={groupList} />
              </>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <ChannelStatusDialog />
              </Box>
            )}
          </>
        )}
        {channel == 3 && (
          <>
            {userInfo.yahooStatus >= 4 ? (
              <>
                {/* <StickySurvey /> */}
                <Overview groupList={groupList} />
              </>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <ChannelStatusDialog />
              </Box>
            )}
          </>
        )}
        {channel == 0 && (
          <>
            {/* <StickySurvey /> */}
            <Overview groupList={groupList} />
          </>
        )}
      </Container>
    </Box>
  );
};

export default TotalSales;
