/* eslint-disable*/

import {
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress
} from '@material-ui/core'; // eslint-disable-line
import { useSelector, useDispatch } from 'react-redux';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  selectAmazonBrandShareGraph,
  selectRakutenBrandShareGraph,
  selectYahooBrandShareGraph,
  fetchBrandItemShareData,
  brandItemShareStatus
} from 'feactures/category/brandShareSlice';
import BrandItemSharegraph from 'components/molecules/dashboard/BrandItemSharegraph';
import BrandItemShareTable from './BrandItemShareTable';
import { useEffect, useState } from 'react';

const BrandItemShare = () => {
  // 実際のデータはuseEffectで取得しreduxへ
  const brandShareAmazonGraphData = useSelector(selectAmazonBrandShareGraph);
  const brandShareRakutenGraphData = useSelector(selectRakutenBrandShareGraph);
  const brandShareYahooGraphData = useSelector(selectYahooBrandShareGraph);
  const isitemShareLoading = useSelector(brandItemShareStatus);
  const channel = useSelector(currentchannel);
  const [name, setName] = useState('');
  const dispatch = useDispatch();

  const dammydata = [
    { name: '商品A', percentage: 35.5, sales: 580000000 },
    { name: '商品B', percentage: 25.5, sales: 380000000 },
    { name: '商品C', percentage: 15.5, sales: 180000000 },
    { name: '商品D', percentage: 5.5, sales: 4000000 },
    { name: '商品E', percentage: 0.5, sales: 50000 }
  ];

  let brandShareGraphData = {
    sales: [],
    percentage: [],
    label: []
  };

  let brandItemShareData = {
    sales: [],
    product_name: [],
    percentage: []
  };

  let brandItemTableData = [];
  let title = '';

  if (channel == 1) {
    brandShareGraphData = brandShareAmazonGraphData.graphdata;
    brandItemShareData = brandShareAmazonGraphData.item_share_data;
    brandItemTableData = brandShareAmazonGraphData.item_share_table_data;
    title = 'ブランド';
  } else if (channel == 2) {
    brandShareGraphData = brandShareRakutenGraphData.graphdata;
    brandItemShareData = brandShareRakutenGraphData.item_share_data;
    brandItemTableData = brandShareRakutenGraphData.item_share_table_data;
    title = '店舗';
  } else if (channel == 3) {
    brandShareGraphData = brandShareYahooGraphData.graphdata;
    brandItemShareData = brandShareYahooGraphData.item_share_data;
    brandItemTableData = brandShareYahooGraphData.item_share_table_data;
    title = 'ブランド';
  }

  useEffect(() => {
    setName(brandShareGraphData.label[0]);
    dispatch(fetchBrandItemShareData({ brand: brandShareGraphData.label[0] }));
  }, []);

  const handleChange = (e) => {
    setName(e.target.value);
    dispatch(fetchBrandItemShareData({ brand: e.target.value }));
  };

  return (
    <Container
      maxWidth={false}
      sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
    >
      <Box
        mb={1}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Typography fontSize="14px" fontWeight="bold">
          {title}別商品シェア
        </Typography>
        <FormControl size="small">
          <InputLabel id="select-label" sx={{ fontSize: '14px' }}>
            {title}を選択
          </InputLabel>
          <Select
            sx={{
              width: 200,
              backgroundColor: '#FFFFFF',
              fontSize: '14px'
            }}
            onChange={handleChange}
            label={`${title}を選択`}
            labelId="select-label"
            defaultValue={brandShareGraphData.label[0]}
          >
            {brandShareGraphData.label.map(
              (item, index) =>
                item !== 'その他' && (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl>
      </Box>
      {name === '' ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            py: 10
          }}
        >
          {title}を選択してください
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {isitemShareLoading ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                height: '150px'
              }}
            >
              <p>グラフの読み込み中…</p>
              <CircularProgress />
            </Box>
          ) : brandItemShareData.product_name.length == 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              sx={{ width: '100%', height: '150px' }}
            >
              <Typography fontSize={15}>データがありません</Typography>
            </Box>
          ) : (
            <>
              <Box sx={{ width: '50%' }}>
                <BrandItemSharegraph
                  graphdata={brandItemShareData}
                  name={name}
                />
              </Box>
              <BrandItemShareTable data={brandItemTableData} />
            </>
          )}
        </Box>
      )}
    </Container>
  );
};

export default BrandItemShare;
