/* eslint-disable*/
import * as React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  changeYearMonth,
  changeYearMonth2,
  resetKubunYearMonth,
  currentmonth,
  comparemonth,
  currentlastmonth,
  amazonCurrentlastmonth,
  rakutenCurrentlastmonth,
  amazonMonthList,
  rakutenMonthList
  // lastmonth
} from 'feactures/section/sectionSlice';
import * as moment from 'moment/moment';
import 'moment/locale/ja';
import { fetchCategoryGraphData } from 'feactures/category/categoryGraphSlice';
import {
  fetchCategoryRankData,
  fetchCategoryRisingData
} from 'feactures/category/categoryRankSlice';
import { fetchAsyncMyproducts } from 'feactures/product/myproductSlice';
import { fetchAsyncOtherproducts } from 'feactures/product/otherproductSlice';
import { fetchCategoryNo1Sales } from 'feactures/category/categoryNo1Slice';
// import { fetchCategoryShareGraphData } from 'feactures/category/categoryShareSlice';
import {
  fetchBrandShareGraphData,
  fetchBrandShareListData
} from 'feactures/category/brandShareSlice';
import { fetchCategoryBrandGraphData } from 'feactures/brand/categoryBrandGraphSlice';
import {
  fetchCategoryBrandShareGraphData,
  fetchCategoryOwnBrandShareGraphData
} from 'feactures/brand/categoryBrandShareSlice';
import { fetchBrandRankData } from 'feactures/brand/brandRankSlice';
import { changeDate2 } from 'feactures/date/dateSlice';
import theme from 'theme';
import { makeStyles } from '@material-ui/styles';
import {
  fetchOtherSales,
  fetchOwnSales,
  getOtherRegisterMeasuresProductSales,
  getOwnRegisterMeasuresProductSales,
  setDetailClose
} from 'feactures/measure/measureSlice';
import { currentchannel } from 'feactures/channel/channelSlice';

const useStyles = makeStyles({
  customSelect: {
    color: '#575757',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: '#E6E6E6'
    }
  },
  formControl: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[400],
        borderWidth: 1
      }
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400]
    }
  }
});

const dispatchTable = {
  ownProduct: [fetchAsyncMyproducts()],
  otherProduct: [fetchAsyncOtherproducts()],
  categoryTop100: [fetchCategoryGraphData(), fetchCategoryRankData()],
  category: [
    fetchCategoryGraphData(),
    fetchCategoryRankData(),
    fetchCategoryRisingData(),
    // fetchCategoryShareGraphData(),
    fetchBrandShareGraphData(),
    fetchBrandShareListData()
  ],
  brandAnalysis: [
    fetchCategoryBrandGraphData(),
    fetchBrandRankData(),
    fetchCategoryBrandShareGraphData()
  ],
  brandComparison: [
    fetchOwnSales(),
    fetchCategoryOwnBrandShareGraphData(),
    fetchCategoryBrandGraphData(),
    fetchCategoryBrandShareGraphData()
  ],
  measureAnalysis: [fetchOwnSales(), fetchCategoryBrandGraphData()],
  productMeasures: [
    setDetailClose(),
    getOwnRegisterMeasuresProductSales(),
    getOtherRegisterMeasuresProductSales()
  ],
  categoryNo1: [fetchCategoryNo1Sales()]
  // monitoring: [fetchGroups(), fetchMonitorPost()],
  // monitoring2: [fetchGroups(), fetchMonitorPost2()]
};

const NewPullDown = (props) => {
  const dispatch = useDispatch();
  let yokoziku = [];
  const { page, label, setYokoziku, graphLoading } = props;
  const selectMonthDate = useSelector(currentmonth);
  const compareMonthDate = useSelector(comparemonth);
  const channel = useSelector(currentchannel);
  const amazonLastmonth = useSelector(amazonCurrentlastmonth);
  const rakutenLastmonth = useSelector(rakutenCurrentlastmonth);
  const amazonList = useSelector(amazonMonthList);
  const rakutenList = useSelector(rakutenMonthList);
  const [isInitialized, setIsInitialized] = React.useState(false); // 初期化完了フラグ

  // const lastMonth = moment().add(-1, 'month');
  let selectMonth = []; //選択年月リスト
  let start = moment('2023-03');
  let lastmonth = amazonLastmonth;
  if (channel == 1) {
    selectMonth = amazonList;
  } else if (channel == 2) {
    start = moment('2023-08');
    lastmonth = rakutenLastmonth;
    selectMonth = rakutenList;
  }
  // for (let d = start; start.diff(lastmonth) <= 0; ) {
  //   selectMonth.push(d.format('YYYY-MM'));
  //   d.add(1, 'month');
  // }
  // selectMonth = selectMonth.reverse();

  // 横軸の更新関数
  const list_12_term = (endmonth) => {
    const yokoziku = [];
    let start = moment(endmonth).subtract(11, 'month');
    let end = moment(endmonth);
    while (start.diff(end) <= 0) {
      yokoziku.push(start.format('Y/M'));
      start.add(1, 'month');
    }
    setYokoziku(yokoziku);
  };

  useEffect(() => {
    const initialize = async () => {
      await dispatch(resetKubunYearMonth());
      setIsInitialized(true); // 初期化完了フラグを設定
    };
    initialize();
  }, [dispatch]);

  useEffect(() => {
    //初期化処理が完了した時だけ実行
    if (isInitialized && selectMonthDate) {
      list_12_term(selectMonthDate);
      [...dispatchTable[page]].forEach((r) => dispatch(r)); // dispatchTableのreducerの数だけdispatchさせる
    }
  }, [isInitialized, selectMonthDate, dispatch, page, dispatchTable]);

  const handleChange = (e) => {
    if (page === 'monitoring2') {
      const start = moment(e.target.value)
        .startOf('month')
        .format('YYYY-MM-DD');
      const end = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
      list_12_term(e.target.value);
      dispatch(changeYearMonth2(e.target.value));
      dispatch(changeDate2({ start2: start, end2: end }));
      [...dispatchTable[page]].map((r, index) => dispatch(r)); //dispatchTableのreducerの数だけdispatchさせる
    } else {
      list_12_term(e.target.value);
      dispatch(changeYearMonth(e.target.value));
      [...dispatchTable[page]].map((r, index) => dispatch(r)); //dispatchTableのreducerの数だけdispatchさせる
    }
  };

  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <Select
        className={classes.customSelect}
        value={page === 'monitoring2' ? compareMonthDate : selectMonthDate}
        onChange={handleChange}
        disabled={graphLoading}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          margin: 0,
          padding: 0,
          width: 110,
          height: 30,
          backgroundColor: '#FFFFFF',
          fontSize: '14px'
        }}
      >
        {selectMonth.map((yearMonth, index) => {
          return (
            <MenuItem value={yearMonth} key={index}>
              {yearMonth}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default NewPullDown;
