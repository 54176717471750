/* eslint-disable*/
import { MenuItem, Select } from '@material-ui/core';
import {
  selectedMeasure,
  changeMeasure
} from 'feactures/measure/newMeasureSlice';
import { useSelector, useDispatch } from 'react-redux';
import { currentchannel } from 'feactures/channel/channelSlice';

const MeasurePullDown = () => {
  const dispatch = useDispatch();
  const measure = useSelector(selectedMeasure);
  const channel = useSelector(currentchannel);

  const handleChange = (e) => {
    let title = '';
    switch (e.target.value) {
      case 'rank':
        title = 'ランキング';
        break;
      case 'price':
        title = '価格';
        break;
      case 'real_price':
        title = '実質価格';
        break;
      case 'point':
        title = 'ポイント';
        break;
      case 'coupon':
        title = 'クーポン';
        break;
      case 'sale':
        title = channel == 1 ? 'セール割引' : '楽天SUPER DEAL';
        break;
      case 'title_change':
        title = 'タイトル変更回数';
        break;
      case 'review':
        title = 'レビュー平均';
        break;
      case 'review_num':
        title = 'レビュー数';
        break;
    }
    dispatch(changeMeasure({ measure: e.target.value, title: title }));
  };

  return (
    <Select
      sx={{
        margin: 0,
        padding: 0,
        width: 170,
        height: 30,
        backgroundColor: '#FFFFFF',
        fontSize: '14px'
      }}
      value={measure}
      onChange={handleChange}
    >
      <MenuItem value={'rank'}>ランキング</MenuItem>
      <MenuItem value={'price'}>価格</MenuItem>
      <MenuItem value={'real_price'}>実質価格</MenuItem>
      {channel == 2 && <MenuItem value={'point'}>ポイント</MenuItem>}
      <MenuItem value={'coupon'}>クーポン</MenuItem>
      <MenuItem value={'sale'}>
        {channel == 1 ? '割引セール' : '楽天SUPER DEAL'}
      </MenuItem>
      {channel == 2 && (
        <MenuItem value={'title_change'}>タイトル変更回数</MenuItem>
      )}
      <MenuItem value={'review'}>レビュー平均</MenuItem>
      <MenuItem value={'review_num'}>レビュー数</MenuItem>
    </Select>
  );
};

export default MeasurePullDown;
