/* eslint-disable*/
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  IconButton
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  userinfo,
  fetchUserStatus,
  fetchAccountStatus,
  connectionModalNotDisplay,
  connectionModalStatus,
  changeConnectionModalDisplay
} from 'feactures/user/userSlice';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ConnectionConfirmModal from './ConnectionConfirmModal';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const steps = [
  'アカウント作成',
  '審査中\n(約2営業日)',
  'データ接続\n(審査期間中も設定可)',
  'Oxcim利用可能'
];

// amazonStatus：
// 1-3：承認完了、データ取得中
// 4：データ取得が完了したとき
// 審査の状況で
// 5:メール送付完了

// rakutenStatus  1-2：ユーザー追加の承認が完了したとき(鈴木さん実装分）
// 3-5：API連携設定が完了したとき　(初回登録完了のメール送信前に5にする)
// 6：データ取得中のとき(今はまだ手動だが、データ取得中past_report開始時に6)
// 7：データ取得が完了(データ取得完了時に7にしてかつ審査ステータスをみる。完了時に審査
// 8: メール送付完了

// ヤフー：
// 1-5：承認が完了したとき
// 6：データ取得中のとき
// 7：データ取得が完了したとき
// 8: メール送付完了

const useStyles = makeStyles(() => ({
  root: {
    '& .Mui-active > .MuiStepConnector-line': { borderColor: '#728DC4' },
    '& .Mui-completed > .MuiStepConnector-line': { borderColor: '#728DC4' }
  }
}));

//カーソルが当たったときの吹き出し
export const HtmlTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip {...props} placement={placement} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    maxWidth: 280
  }
}));

const ConnectionBoxBlue = styled(Box)(({ theme }) => ({
  borderRadius: '4px',
  width: '230px',
  height: '150px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#728DC4',
  boxShadow: '2px 3px 5px #425272',
  position: 'relative',
  '&:hover': {
    backgroundColor: '#839ac7'
  }
}));

const ConnectionBoxGray = styled(Box)(({ theme }) => ({
  borderRadius: '4px',
  width: '230px',
  height: '150px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#eeeeee',
  boxShadow: '2px 3px 5px #5c5c5c',
  position: 'relative',
  '&:hover': {
    backgroundColor: '#c9c9c9'
  }
}));

const ConnectionBoxGrayDisabled = styled(Box)(({ theme }) => ({
  borderRadius: '4px',
  width: '230px',
  height: '150px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#eeeeee',
  boxShadow: '2px 3px 5px #5c5c5c',
  position: 'relative'
}));

const SubTitleBoxGray = styled(Box)(({ theme }) => ({
  color: 'white',
  borderRadius: '20px',
  backgroundColor: '#4d4d4d',
  padding: '5px 10px',
  position: 'absolute',
  top: '-10px',
  fontSize: '12px'
}));

const SubTitleBoxBlue = styled(Box)(({ theme }) => ({
  color: 'white',
  borderRadius: '20px',
  backgroundColor: '#375eaa',
  padding: '5px 10px',
  position: 'absolute',
  top: '-10px',
  fontSize: '12px'
}));

const ConnectionStatus3 = () => {
  const c = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(userinfo);
  const [stepValue, setStepValue] = useState(2);
  const [open, setOpen] = useState(false);
  const [modalNum, setModalNum] = useState(1);

  // 施策分析のトライアルに申込済みかどうかのフラグ
  const [trialRegisteredFlag, setTrialRegisteredFlag] = useState(false);

  useEffect(() => {
    if (user.trial_mall) {
      if (user.trial_mall.includes('1') && user.trial_mall.includes('2')) {
        setTrialRegisteredFlag(
          user.trial.Amazon.end_date !== '' && user.trial.楽天.end_date !== ''
        );
      } else if (user.trial_mall.includes('1')) {
        setTrialRegisteredFlag(user.trial.Amazon.end_date !== '');
      } else if (user.trial_mall.includes('2')) {
        setTrialRegisteredFlag(user.trial.楽天.end_date !== '');
      }
    }
  }, []);

  const handleClose = () => {
    dispatch(changeConnectionModalDisplay());
  };

  const handleOpen = (num) => {
    setModalNum(num);
    setOpen(true);
  };

  function StyledStepIcon(props) {
    const { active, completed, className } = props;

    return completed ? (
      <TaskAltIcon
        sx={{
          color: '#728DC4'
        }}
      />
    ) : active ? (
      <Box
        sx={{
          color: '#728DC4',
          borderRadius: '50%',
          border: '1px solid #728DC4',
          width: '25px',
          height: '25px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography>{props.icon}</Typography>
      </Box>
    ) : (
      <Box
        sx={{
          color: '#929292',
          borderRadius: '50%',
          border: '1px solid #929292',
          width: '25px',
          height: '25px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography>{props.icon}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '450px',
        width: '950px',
        padding: '10px'
      }}
    >
      <Container sx={{ width: 'auto' }}>
        {trialRegisteredFlag && (
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 1,
              right: 1
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
        <Box sx={{ mb: 1, fontWeight: 'bold' }}>
          <Stepper activeStep={stepValue} alternativeLabel className={c.root}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  style={{ whiteSpace: 'pre-line' }}
                  StepIconComponent={StyledStepIcon}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              my: 10,
              width: '100%'
            }}
          >
            {trialRegisteredFlag ? (
              <>
                <ConnectionBoxBlue onClick={() => handleOpen(1)}>
                  <SubTitleBoxBlue>データ接続して使用開始</SubTitleBoxBlue>
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'bold'}
                    color={'#fff'}
                  >
                    全体実績・KPIビュー
                  </Typography>
                  <Typography fontSize={'12px'} color={'#fff'}>
                    モール機能でモニタリングに使える！
                  </Typography>
                </ConnectionBoxBlue>
                <ConnectionBoxBlue onClick={() => handleOpen(2)}>
                  <SubTitleBoxBlue>データ接続してトライアル</SubTitleBoxBlue>
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'bold'}
                    color={'#fff'}
                  >
                    マーケットビュー
                  </Typography>
                  <Typography fontSize={'12px'} color={'#fff'}>
                    ターゲット戦略の参考にできる！
                  </Typography>
                </ConnectionBoxBlue>
                <ConnectionBoxGrayDisabled>
                  <SubTitleBoxGray>トライアル実施済</SubTitleBoxGray>
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'bold'}
                    color={'#4d4d4d'}
                  >
                    競合ビュー
                  </Typography>
                  <Typography fontSize={'12px'} color={'#4d4d4d'}>
                    戦術/施策の参考にしたい！
                  </Typography>
                </ConnectionBoxGrayDisabled>
              </>
            ) : (
              <>
                <HtmlTooltip
                  placement="top"
                  title={
                    <span style={{ whiteSpace: 'pre-line' }}>
                      競合ビューのトライアルを開始してください！
                    </span>
                  }
                >
                  <ConnectionBoxGrayDisabled>
                    <SubTitleBoxGray>データ接続して使用開始</SubTitleBoxGray>
                    <Typography
                      fontSize={'18px'}
                      fontWeight={'bold'}
                      color={'#4d4d4d'}
                    >
                      全体実績・KPIビュー
                    </Typography>
                    <Typography fontSize={'12px'} color={'#4d4d4d'}>
                      モール機能でモニタリングに使える！
                    </Typography>
                  </ConnectionBoxGrayDisabled>
                </HtmlTooltip>
                <HtmlTooltip
                  placement="top"
                  title={
                    <span style={{ whiteSpace: 'pre-line' }}>
                      競合ビューのトライアルを開始してください！
                    </span>
                  }
                >
                  <ConnectionBoxGrayDisabled>
                    <SubTitleBoxGray>データ接続してトライアル</SubTitleBoxGray>
                    <Typography
                      fontSize={'18px'}
                      fontWeight={'bold'}
                      color={'#4d4d4d'}
                    >
                      マーケットビュー
                    </Typography>
                    <Typography fontSize={'12px'} color={'#4d4d4d'}>
                      ターゲット戦略の参考にできる！
                    </Typography>
                  </ConnectionBoxGrayDisabled>
                </HtmlTooltip>
                <ConnectionBoxBlue onClick={() => handleOpen(3)}>
                  {/* <SubTitleBoxBlue>今すぐトライアルを開始</SubTitleBoxBlue> */}
                  <SubTitleBoxBlue>データ接続してトライアル</SubTitleBoxBlue>
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'bold'}
                    color={'white'}
                  >
                    競合ビュー
                  </Typography>
                  <Typography fontSize={'12px'} color={'white'}>
                    戦術/施策の参考にしたい！
                  </Typography>
                </ConnectionBoxBlue>
              </>
            )}
          </Box>
          {trialRegisteredFlag && (
            <Button onClick={() => dispatch(connectionModalNotDisplay())}>
              今後表示しない
            </Button>
          )}
        </Box>
      </Container>
      <ConnectionConfirmModal
        open={open}
        setOpen={setOpen}
        modalNum={modalNum}
        setModalNum={setModalNum}
      />
    </Box>
  );
};

export default ConnectionStatus3;
