/* eslint-disable */
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  useTheme,
  colors,
  Typography
} from '@material-ui/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useSelector } from 'react-redux';
import { currentmonth } from 'feactures/section/sectionSlice';

Chart.register(ChartDataLabels);

const BrandSharegraph = (props) => {
  const { graphdata } = props; // eslint-disable-line
  const yearmonth = useSelector(currentmonth);
  let monthlySales = 0;
  let calcTotal = 0;
  let tanni = '';
  if (graphdata.total >= 1000000000000) {
    calcTotal = graphdata.total / 1000000000000;
    monthlySales = calcTotal.toFixed(1);
    tanni = '兆円';
  } else if (graphdata.total >= 100000000) {
    monthlySales = Math.round(graphdata.total / 100000000);
    tanni = '億円';
  } else if (graphdata.total >= 10000) {
    monthlySales = Math.round(graphdata.total / 10000);
    tanni = '万円';
  } else if (graphdata.total < 10000) {
    monthlySales = 1;
    tanni = '万円';
  } else if ((graphdata.total = 0)) {
    monthlySales = 0;
    tanni = '円';
  }

  const theme = useTheme();
  const bgColorList = [
    '#738DC4',
    '#D0D8ED',
    '#B7C2E2',
    '#5C71A6',
    '#9FAED7',
    '#84A0CE'
  ];
  const data = {
    datasets: [
      {
        data: graphdata.percentage.filter((num) => num > 0),
        backgroundColor: graphdata.label.map((item, index) =>
          item == 'その他' ? '#A0A0A0' : bgColorList[index]
        ),
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
        pointStyle: 'line'
      }
    ],
    labels: graphdata.label
  };

  const options = {
    cutout: '70%',
    layout: { padding: { top: 30, bottom: 30 } },
    maintainAspectRatio: false,
    responsive: true,
    height: '100%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        filter: (tooltipItem) => {
          const dataIndex = tooltipItem.dataIndex;
          const data = tooltipItem.dataset.data;

          // 差分が小さい場合のみツールチップを表示
          if (dataIndex === 0) {
            return false; // 最初の要素はツールチップを非表示
          }
          const diff = Math.abs(data[dataIndex] - data[dataIndex - 1]);
          return diff <= 1; // 差分が1以下の場合に表示
        },
        callbacks: {
          label: (tooltipItem) => {
            const dataIndex = tooltipItem.dataIndex;
            const value = tooltipItem.raw;
            const label = graphdata.label[dataIndex];

            return `${label}: ${value}%`;
          }
        }
      },
      datalabels: {
        display: (context) => {
          const index = context.dataIndex;
          const data = context.dataset.data;

          // 最初の要素は必ず表示
          if (index === 0) {
            return true;
          }

          // 前の要素との差分を計算
          const diff = Math.abs(data[index] - data[index - 1]);

          // 差分が1以下の場合は非表示、それ以外は表示
          return diff > 1;
        },
        align: 'end',
        anchor: 'end',
        offset: 12,
        color: theme.palette.text.secondary,
        font: {
          family: "'M PLUS 1p', sans-serif"
        },
        formatter: (value, context) => {
          let productName = graphdata.label[context.dataIndex];

          // ラベルに改行を挿入する（例: 12文字ごと）
          let parts = productName.match(/.{1,12}/g);

          if (parts) {
            parts[parts.length - 1] += ` ${value}%`;
          }

          return parts;
        }
      }
    }
  };

  return (
    <Card {...props} sx={{ padding: 0 }}>
      <CardContent
        sx={{
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          ':last-child': { pb: 0 }
        }}
      >
        <Box
          sx={{
            height: 250,
            width: '100%',
            position: 'relative'
          }}
        >
          <Doughnut
            data={data}
            options={options}
            sx={{
              position: 'absolute', // Doughnutのpositionをabsoluteに指定
              top: '50%', // 上から50%の位置
              left: '50%', // 左から50%の位置
              transform: 'translate(-50%, -50%)' // 自身の50%分移動して最終的に中央に配置
            }}
          />
          <Box
            sx={{
              position: 'absolute', // Boxのpositionをabsoluteに指定
              top: '50%', // 上から50%の位置
              left: '50%', // 左から50%の位置
              transform: 'translate(-50%, -50%)', // 自身の50%分移動して最終的に中央に配置
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography fontWeight="bold" color="#7288C5" fontSize="20px">
              {monthlySales.toLocaleString()}
              <Typography
                component={'span'}
                color="black"
                fontWeight="bold"
                fontSize="20px"
              >
                {tanni}
              </Typography>
            </Typography>
            <Typography fontSize="14px" fontWeight={'bold'}>
              {yearmonth.replace('-', '年')}月
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '10px'
          }}
        >
          {graphdata.label.map((label, index) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  width: '10px',
                  height: '10px',
                  backgroundColor:
                    label == 'その他' ? '#A0A0A0' : bgColorList[index]
                }}
              ></Box>
              <Typography component={'span'}>{label}</Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default BrandSharegraph;
