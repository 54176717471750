/* eslint-disable*/

import {
  Box,
  Container,
  Grid,
  TextField,
  colors,
  Paper,
  Typography,
  Tooltip,
  IconButton
} from '@material-ui/core'; // eslint-disable-line
import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  amazon_100items,
  rakuten_100items,
  yahoo_100items
} from 'feactures/category/categoryRankSlice';
import {
  amazonCategory,
  rakutenCategory,
  yahooCategory
} from 'feactures/category/categorySlice';
import RankingList from './RankingList';
import { currentchannel } from 'feactures/channel/channelSlice';
import download_icon from '../../layout/dashboard/icons/download_icon.svg';
import {
  exportStatus_categoryRank,
  fetchExport_categoryRank
} from 'feactures/monitoring/exportSlice';

const RankingTotal = (props) => {
  const { yokoziku } = props;
  const a_100items = useSelector(amazon_100items);
  const r_100items = useSelector(rakuten_100items);
  const y_100items = useSelector(yahoo_100items);
  const channel = useSelector(currentchannel);
  const exportStatus = useSelector(exportStatus_categoryRank);
  const dispatch = useDispatch();

  return (
    <Container maxWidth={false} sx={{ mt: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          fontSize="14px"
          fontWeight="bold"
          sx={{ paddingTop: '12px', paddingBottom: '12px' }}
        >
          選択カテゴリのTOP100商品
        </Typography>
        <Tooltip
          title={
            exportStatus
              ? 'CSVダウンロード中...'
              : (channel == 1 && a_100items.length == 0) ||
                (channel == 2 && r_100items.length == 0) ||
                (channel == 3 && y_100items.length == 0)
              ? ''
              : 'CSVダウンロード'
          }
          placement="top"
        >
          <span>
            <IconButton
              onClick={() => {
                dispatch(fetchExport_categoryRank());
              }}
              disabled={
                exportStatus ||
                (channel == 1 && a_100items.length == 0) ||
                (channel == 2 && r_100items.length == 0) ||
                (channel == 3 && y_100items.length == 0)
              }
            >
              <img
                src={download_icon}
                alt="ダウンロード"
                style={{ width: '20px' }}
              />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      {channel == 1 ? (
        <RankingList
          top100items={a_100items.length > 0 ? a_100items : []}
          yokoziku={yokoziku}
        />
      ) : null}
      {channel == 2 ? (
        <RankingList
          top100items={r_100items.length > 0 ? r_100items : []}
          yokoziku={yokoziku}
        />
      ) : null}
      {channel == 3 ? (
        <RankingList
          top100items={y_100items.length > 0 ? y_100items : []}
          yokoziku={yokoziku}
        />
      ) : null}
    </Container>
  );
};

export default RankingTotal;
