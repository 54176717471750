/* eslint-disable*/
import {
  Card,
  CardContent,
  Box,
  Container,
  Grid,
  Typography,
  CircularProgress,
  useTheme,
  Button
} from '@material-ui/core'; /* eslint-disable*/
import KpiDatePicker from 'components/atoms/button/KpiDatePicker';
import { useEffect, useState, createContext, useMemo, memo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  selectMonitoringAsin,
  selectMonitoringGroup,
  currentGroup,
  fetchMonitorPost
} from 'feactures/monitoring/monitoringSlice';
import { userinfo } from 'feactures/user/userSlice';
import KpiMonitoring from './KpiMonitoring';
import ConnectionDialog from 'components/atoms/modal/ConnectionDialog';
import KPIButtongroup from 'components/atoms/group/KPIButtonGroup';
import { groups } from 'feactures/monitoring/groupSlice';
import BreadList from 'components/atoms/list/BreadList';
import StickyAnnotation from 'components/atoms/annotation/StickyAnnotation';
import * as moment from 'moment/moment';
import KpiSummaryGroupTable from 'components/organisms/content/kpi_view/KpiSummaryGroupTable';
import ItemSalesList from 'components/organisms/content/overview/ItemSalesList';
import GroupItemSalesList from 'components/organisms/content/kpi_view/GroupItemSalesList';
import 'moment/locale/ja';
moment.locale('ja');

export const KpiSummaryBoard = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const channel = useSelector(currentchannel);
  const groupList = useSelector(groups);
  const selectedGroup = useSelector(currentGroup);
  const userInfo = useSelector(userinfo);
  const [term, setTerm] = useState([]);
  const [page, setPage] = useState('monitoring');
  const location = useLocation();

  // グループ別KPIサマリまたは商品別KPIサマリページの場合
  useEffect(() => {
    if (location.pathname === '/app/monitoring/asin') {
      setPage('monitoringAsin');
    } else if (location.pathname === '/app/monitoring/group') {
      setPage('monitoringGroup');
    } else if (location.pathname === '/app/monitoring') {
      setPage('monitoring');
      dispatch(fetchMonitorPost());
    }

    if (
      location.pathname === '/app/monitoring/group' &&
      selectedGroup.id === ''
    ) {
      navigate('/app/monitoring');
    }
  }, [location.pathname]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#FFF8E5',
          height: 'auto',
          minHeight: '100vh',
          width: '100%',
          pb: '80px'
        }}
      >
        <Container maxWidth={false} sx={{ width: 'auto', p: 0 }}>
          <Box
            position="sticky"
            top="42px"
            bgcolor="#FFF8E5"
            zIndex={3}
            height="auto"
          >
            {/* {page === 'monitoringAsin' && channel == 2 && <StickyAnnotation />} */}
            <BreadList />
            <Box
              sx={{
                display: 'flex',
                borderTop: 1,
                borderColor: '#FFFFFF',
                alignItems: 'flex-end'
              }}
            >
              <Box mr={3}>
                <KpiDatePicker setTerm={setTerm} page={page} />
              </Box>
              <Box>
                {/* <Typography fontSize="14px">&nbsp;</Typography> */}
                <KPIButtongroup amazonOnly={true} page={page} />
              </Box>
            </Box>
          </Box>

          {channel == 1 && (
            <>
              <>
                <KpiMonitoring term={term} page={page} />
                {page === 'monitoring' && groupList.length > 0 ? (
                  <Box mb={2} display="flex" justifyContent="center">
                    <Box
                      width="100%"
                      bgcolor="#FFFFFF"
                      borderRadius={3}
                      border={1}
                      borderColor="#D9D9D9"
                      p={1}
                    >
                      <KpiSummaryGroupTable />
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
                {page === 'monitoring' && (
                  <Box mb={2} display="flex" justifyContent="center">
                    <Box
                      width="100%"
                      bgcolor="#FFFFFF"
                      borderRadius={3}
                      border={1}
                      borderColor="#D9D9D9"
                      p={1}
                    >
                      <ItemSalesList pathName={page} />
                    </Box>
                  </Box>
                )}
                {page === 'monitoringGroup' && (
                  <Box mb={2} display="flex" justifyContent="center">
                    <Box
                      width="100%"
                      bgcolor="#FFFFFF"
                      borderRadius={3}
                      border={1}
                      borderColor="#D9D9D9"
                      p={1}
                    >
                      <GroupItemSalesList pathName={page} />
                    </Box>
                  </Box>
                )}
              </>
            </>
          )}
          {channel == 2 && (
            <>
              <>
                <KpiMonitoring term={term} page={page} />
                {page === 'monitoring' && groupList.length > 0 ? (
                  <Box mb={2} display="flex" justifyContent="center">
                    <Box
                      width="100%"
                      bgcolor="#FFFFFF"
                      borderRadius={3}
                      border={1}
                      borderColor="#D9D9D9"
                      p={1}
                    >
                      <KpiSummaryGroupTable />
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
                {page === 'monitoring' && (
                  <Box mb={2} display="flex" justifyContent="center">
                    <Box
                      width="100%"
                      bgcolor="#FFFFFF"
                      borderRadius={3}
                      border={1}
                      borderColor="#D9D9D9"
                      p={1}
                    >
                      <ItemSalesList pathName={page} />
                    </Box>
                  </Box>
                )}
                {page === 'monitoringGroup' && (
                  <Box mb={2} display="flex" justifyContent="center">
                    <Box
                      width="100%"
                      bgcolor="#FFFFFF"
                      borderRadius={3}
                      border={1}
                      borderColor="#D9D9D9"
                      p={1}
                    >
                      <GroupItemSalesList pathName={page} />
                    </Box>
                  </Box>
                )}
              </>
            </>
          )}
          {channel == 3 && (
            <>
              <>
                <KpiMonitoring term={term} page={page} />
                {page === 'monitoring' && groupList.length > 0 ? (
                  <Box mb={2} display="flex" justifyContent="center">
                    <Box
                      width="100%"
                      bgcolor="#FFFFFF"
                      borderRadius={3}
                      border={1}
                      borderColor="#D9D9D9"
                      p={1}
                    >
                      <KpiSummaryGroupTable />
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
                {page === 'monitoring' && (
                  <Box mb={2} display="flex" justifyContent="center">
                    <Box
                      width="100%"
                      bgcolor="#FFFFFF"
                      borderRadius={3}
                      border={1}
                      borderColor="#D9D9D9"
                      p={1}
                    >
                      <ItemSalesList pathName={page} />
                    </Box>
                  </Box>
                )}
                {page === 'monitoringGroup' && (
                  <Box mb={2} display="flex" justifyContent="center">
                    <Box
                      width="100%"
                      bgcolor="#FFFFFF"
                      borderRadius={3}
                      border={1}
                      borderColor="#D9D9D9"
                      p={1}
                    >
                      <GroupItemSalesList pathName={page} />
                    </Box>
                  </Box>
                )}
              </>
            </>
          )}
          {channel == 0 && (
            <>
              <KpiMonitoring term={term} page={page} />
              {page === 'monitoring' && groupList.length > 0 ? (
                <Box mb={2} display="flex" justifyContent="center">
                  <Box
                    width="100%"
                    bgcolor="#FFFFFF"
                    borderRadius={3}
                    border={1}
                    borderColor="#D9D9D9"
                    p={1}
                  >
                    <KpiSummaryGroupTable />
                  </Box>
                </Box>
              ) : (
                <></>
              )}
              {page === 'monitoring' && (
                <Box mb={2} display="flex" justifyContent="center">
                  <Box
                    width="100%"
                    bgcolor="#FFFFFF"
                    borderRadius={3}
                    border={1}
                    borderColor="#D9D9D9"
                    p={1}
                  >
                    <ItemSalesList pathName={page} />
                  </Box>
                </Box>
              )}
              {page === 'monitoringGroup' && (
                <Box mb={2} display="flex" justifyContent="center">
                  <Box
                    width="100%"
                    bgcolor="#FFFFFF"
                    borderRadius={3}
                    border={1}
                    borderColor="#D9D9D9"
                    p={1}
                  >
                    <GroupItemSalesList pathName={page} />
                  </Box>
                </Box>
              )}
            </>
          )}
        </Container>
      </Box>
    </>
  );
});

export default KpiSummaryBoard;
