/* eslint-disable*/

import { Box, Container, Typography } from '@material-ui/core'; // eslint-disable-line
import { useSelector, useDispatch } from 'react-redux';
import {
  amazon_top_5,
  rakuten_top_5,
  yahoo_top_5
} from 'feactures/category/categoryRankSlice';

import { currentchannel } from 'feactures/channel/channelSlice';
import RisingTop5List from './RisingTop5List';

const RisingItemTable = (props) => {
  const { yokoziku } = props;
  const a_top_5 = useSelector(amazon_top_5);
  const r_top_5 = useSelector(rakuten_top_5);
  const y_top_5 = useSelector(yahoo_top_5);
  const channel = useSelector(currentchannel);

  return (
    <Container maxWidth={false} sx={{ my: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography
            fontSize="14px"
            fontWeight="bold"
            sx={{ paddingBottom: '8px' }}
          >
            急成長中商品TOP5
          </Typography>
          <Typography fontSize="12px" color={'gray'}>
            先月比で売上が急成長している商品
          </Typography>
        </Box>
      </Box>
      {channel == 1 ? (
        <RisingTop5List
          top5items={a_top_5.length > 0 ? a_top_5 : []}
          yokoziku={yokoziku}
        />
      ) : null}
      {channel == 2 ? (
        <RisingTop5List
          top5items={r_top_5.length > 0 ? r_top_5 : []}
          yokoziku={yokoziku}
        />
      ) : null}
      {channel == 3 ? (
        <RisingTop5List
          top5items={y_top_5.length > 0 ? y_top_5 : []}
          yokoziku={yokoziku}
        />
      ) : null}
    </Container>
  );
};

export default RisingItemTable;
