/* eslint-disable */
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  useTheme,
  colors,
  Typography
} from '@material-ui/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useSelector } from 'react-redux';
import { currentmonth } from 'feactures/section/sectionSlice';

Chart.register(ChartDataLabels);

const BrandItemSharegraph = (props) => {
  const { graphdata, name } = props; // eslint-disable-line
  const theme = useTheme();
  const bgColorList = [
    '#738DC4',
    '#D0D8ED',
    '#B7C2E2',
    '#5C71A6',
    '#9FAED7',
    '#84A0CE'
  ];
  const data = {
    datasets: [
      {
        data: graphdata.percentage.filter((num) => num > 0),
        backgroundColor: graphdata.product_name.map((item, index) =>
          item == 'その他' ? '#A0A0A0' : bgColorList[index]
        ),
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
        pointStyle: 'line'
      }
    ],
    labels: graphdata.product_name
  };

  const options = {
    cutout: '70%',
    layout: { padding: { top: 30, bottom: 30 } },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    height: '100%',
    tooltips: {
      enabled: true
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom', // 凡例の表示位置を設定
        labels: {
          boxWidth: 10
        }
      },
      tooltip: {
        // filter: function (item) {
        //   return item.dataIndex >= 5;
        // },
        callbacks: {
          label: function (tooltipItem) {
            let data = tooltipItem.raw;
            let productName = tooltipItem.label;

            // 20文字ごとに改行を挿入する
            let parts = productName.match(/.{1,20}/g);

            if (parts) {
              parts[parts.length - 1] += ` ${data}%`;
            }

            return parts;
          }
        }
      },
      datalabels: {
        display: false,
        align: 'end',
        anchor: 'end',
        offset: 15,
        color: theme.palette.text.secondary,
        font: {
          family: "'M PLUS 1p', sans-serif"
        }
      }
    }
  };

  return (
    <Card {...props} sx={{ padding: 0 }}>
      <CardContent
        sx={{
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            height: 250,
            width: '100%',
            position: 'relative'
          }}
        >
          <Doughnut
            data={data}
            options={options}
            sx={{
              zIndex: 100,
              position: 'absolute', // Doughnutのpositionをabsoluteに指定
              top: '50%', // 上から50%の位置
              left: '50%', // 左から50%の位置
              transform: 'translate(-50%, -50%)' // 自身の50%分移動して最終的に中央に配置
            }}
          />
          <Box
            sx={{
              position: 'absolute', // Boxのpositionをabsoluteに指定
              top: '50%', // 上から50%の位置
              left: '50%', // 左から50%の位置
              transform: 'translate(-50%, -50%)', // 自身の50%分移動して最終的に中央に配置
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80px'
            }}
          >
            <Typography fontWeight="bold" color="black" fontSize="14px">
              {name}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BrandItemSharegraph;
