/* eslint-disable */
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Button,
  Link,
  Tooltip
} from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { currentchannel } from 'feactures/channel/channelSlice';
import ItemDetailDialog from 'components/atoms/modal/ItemDetailDialog';
import {
  fetchItemDetailGraph,
  itemDetailDisplay,
  setItemDetail,
  setItemDetailDisplayFlag
} from 'feactures/category/categoryRankSlice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff'
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#f0f0f0'
  },
  borderBottom: '1px solid gray'
}));

const RankingList = (props) => {
  const { top100items, yokoziku } = props;
  const dispatch = useDispatch();

  const combined = top100items.map((top100item, i) => ({
    rank: i + 1,
    ...top100item
  }));

  const [orderDesc, setOrderDesc] = useState(true);
  const [checkedColumn, setCheckedColumn] = useState('rank');
  const [sortArr, setSortArr] = useState(combined);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const channel = useSelector(currentchannel);
  const displayFlag = useSelector(itemDetailDisplay);

  const url = 'https://m.media-amazon.com/images/I/';

  const handleChange = (column) => {
    if (column === checkedColumn && orderDesc === true) {
      //最後に並び替えたカラムと同じカラムで、現在の並び順が降順なので昇順に並び替える
      if (column === 'rank') {
        setOrderDesc(false);
        const ascSorted = [...sortArr].sort((a, b) => b[column] - a[column]);
        setSortArr(ascSorted);
      } else {
        setOrderDesc(false);
        const ascSorted = [...sortArr].sort((a, b) => a[column] - b[column]);
        setSortArr(ascSorted);
      }
    } else if (column === checkedColumn && orderDesc === false) {
      //最後に並び替えたカラムと同じカラムで、現在の並び順が昇順なので降順に並び替える
      if (column === 'rank') {
        setOrderDesc(true);
        const descSorted = [...sortArr].sort((a, b) => a[column] - b[column]);
        setSortArr(descSorted);
      } else {
        setOrderDesc(true);
        const descSorted = [...sortArr].sort((a, b) => b[column] - a[column]);
        setSortArr(descSorted);
      }
    } else {
      // 新しいカラムを降順に並び替える
      setCheckedColumn(column);
      if (column === 'rank') {
        setOrderDesc(true);
        const descSorted = [...sortArr].sort((a, b) => a[column] - b[column]);
        setSortArr(descSorted);
      } else {
        setOrderDesc(true);
        const descSorted = [...sortArr].sort((a, b) => b[column] - a[column]);
        setSortArr(descSorted);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const tableStyles = makeStyles({
    tableHead: {
      height: 44
    }
  });

  const classes = tableStyles();

  const handleClick = (row, shop_code) => {
    let item_id = row.item_id;
    if (channel == 2) {
      item_id = row.item_id.split(':')[1];
    }
    const data = {
      rank: row.rank,
      img: row.img,
      shopCode: shop_code,
      asin: item_id,
      item_name: row.name,
      price: row.price,
      unit_sold: row.unit_sold,
      sales: row.sales
    };
    dispatch(setItemDetailDisplayFlag(true));
    dispatch(setItemDetail(data));
    dispatch(fetchItemDetailGraph(data));
  };
  return (
    <>
      {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}
      <ItemDetailDialog yokoziku={yokoziku} />
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table
          stickyHeader
          size="small"
          aria-label="a dense table"
          sx={{ border: 'none' }}
        >
          <TableHead sx={{ maxHeight: '38px' }}>
            <StyledTableRow>
              <StyledTableCell
                align="left"
                component="th"
                scope="row"
                sx={{
                  minWidth: '65px'
                }}
              >
                順位
              </StyledTableCell>
              <StyledTableCell sx={{ width: '70px' }}></StyledTableCell>
              {/* {channel == 1 ? (
                <StyledTableCell align="left">ASIN</StyledTableCell>
              ) : (
                <StyledTableCell align="left">
                  ショップ名
                  <br />
                  商品管理番号
                </StyledTableCell>
              )} */}
              <StyledTableCell align="left">商品名</StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  minWidth: '120px'
                }}
              >
                <Button
                  color="inherit"
                  sx={{
                    height: '32.5px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'start',
                    paddingLeft: 0
                  }}
                  onClick={() => {
                    handleChange('price');
                  }}
                >
                  価格
                  {checkedColumn !== 'price' && (
                    <Box width="20.5px" height="20.5px"></Box>
                  )}
                  {checkedColumn === 'price' && (
                    <ExpandMore
                      expand={orderDesc}
                      onClick={() => {
                        handleChange('price');
                      }}
                      aria-expanded={orderDesc}
                      aria-label="sort"
                      sx={{ padding: 0 }}
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  )}
                </Button>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  minWidth: '120px'
                }}
              >
                <Button
                  onClick={() => {
                    handleChange('unit_sold');
                  }}
                  color="inherit"
                  sx={{
                    height: '32.5px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'start',
                    paddingLeft: 0
                  }}
                >
                  販売個数
                  {checkedColumn !== 'unit_sold' && (
                    <Box width="20.5px" height="20.5px"></Box>
                  )}
                  {checkedColumn === 'unit_sold' && (
                    <ExpandMore
                      expand={orderDesc}
                      aria-expanded={orderDesc}
                      aria-label="sort"
                      sx={{ padding: 0 }}
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  )}
                </Button>
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ padding: 0 }}>
                <Button
                  onClick={() => {
                    handleChange('rank');
                  }}
                  color="inherit"
                  sx={{
                    height: '32.5px',
                    width: '100%',
                    justifyContent: 'start',
                    display: 'flex',
                    paddingLeft: 0
                  }}
                >
                  売上
                  {checkedColumn !== 'rank' && (
                    <Box width="20.5px" height="20.5px"></Box>
                  )}
                  {checkedColumn === 'rank' && (
                    <ExpandMore
                      expand={orderDesc}
                      aria-expanded={orderDesc}
                      aria-label="sort"
                      sx={{ padding: 0 }}
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  )}
                </Button>
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }}></StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {sortArr.length !== 0 ? (
              <>
                {sortArr
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const shopCode =
                      channel == 2 ? row.item_id.split(':')[0] : '';
                    return (
                      <StyledTableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          height: 100
                        }}
                        className={classes.tableHead}
                      >
                        <StyledTableCell align="left">
                          {row.rank}位
                        </StyledTableCell>
                        <StyledTableCell align="left" maxwidth={40}>
                          {row.item_id == '-:-' ? (
                            <Box>-</Box>
                          ) : (
                            <Link
                              href={
                                channel === 1
                                  ? `https://www.amazon.co.jp/dp/${row.item_id}`
                                  : `https://item.rakuten.co.jp/${shopCode}/${
                                      row.item_id.split(':')[1]
                                    }/`
                              }
                              target="_blank"
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                <Box
                                  sx={{
                                    height: 70,
                                    width: 70,
                                    display: 'inline-block'
                                  }}
                                >
                                  <img
                                    src={channel == 1 ? url + row.img : row.img}
                                    height="100%"
                                    loading="lazy"
                                    width="100%"
                                  />
                                </Box>
                              </Box>
                            </Link>
                          )}
                        </StyledTableCell>
                        {/* <StyledTableCell align="left">
                          {channel == 1 ? (
                            <Box>{row.item_id}</Box>
                          ) : row.item_id == '-:-' ? (
                            <Box>-</Box>
                          ) : (
                            <>
                              <Box>{shopCode}</Box>
                              <br />
                              <Box>{row.item_id.split(':')[1]}</Box>
                            </>
                          )}
                        </StyledTableCell> */}
                        <StyledTableCell align="left">
                          {row.item_id == '-:-' ? (
                            <Box>データが無いため表示できません</Box>
                          ) : (
                            <Link
                              underline="hover"
                              href={
                                channel === 1
                                  ? `https://www.amazon.co.jp/dp/${row.item_id}`
                                  : `https://item.rakuten.co.jp/${shopCode}/${
                                      row.item_id.split(':')[1]
                                    }/`
                              }
                              target="_blank"
                              rel="noopener"
                            >
                              <Box
                                sx={{
                                  height: 60,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'left'
                                }}
                                component="div"
                                my={2}
                                textOverflow="ellipsis"
                                overflow="hidden"
                              >
                                {row.name}
                              </Box>
                            </Link>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.price == 0
                            ? '-'
                            : '¥' + row.price.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.unit_sold == 0
                            ? '-'
                            : row.unit_sold.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.item_id == '-:-'
                            ? '-'
                            : '¥' + row.sales.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Button
                            variant="outlined"
                            sx={{
                              borderRadius: '4px',
                              borderColor: 'lightgray',
                              color: 'black',
                              px: 1,
                              minWidth: '50px'
                            }}
                            onClick={() => {
                              handleClick(row, shopCode);
                            }}
                            disabled={row.item_id == '-:-'}
                          >
                            詳細
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </>
            ) : (
              <StyledTableRow>
                <StyledTableCell>データがありません</StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 100]}
        count={sortArr.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default RankingList;
