/* eslint-disable*/
import {
  Box,
  Dialog,
  IconButton,
  Typography,
  Button,
  Rating
} from '@material-ui/core';
import React, { useState } from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import {
  performancereviewAmazonOtherItemData,
  performancereviewAmazonOwnItemData,
  performancereviewOtherDataLoading,
  performancereviewOwnDataLoading,
  performancereviewRakutenOtherItemData,
  performancereviewRakutenOwnItemData
} from 'feactures/performancereview/performancereviewSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';

const Review = (props) => {
  const { score, num, result, sectionName } = props;
  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography fontWeight="bold" fontSize="15px">
          {sectionName}のレビュー情報
        </Typography>
        {result != '-' ? (
          result ? (
            <CheckCircleRoundedIcon
              sx={{ color: 'rgba(113,140,198,1)', width: '25px' }}
            />
          ) : (
            <AddCircleOutlinedIcon
              sx={{
                color: '#C3C3C3',
                width: '25px',
                transform: 'rotate(45deg)'
              }}
            />
          )
        ) : (
          ''
        )}
      </Box>
      <Box display="flex" alignItems="center" gap={1} mt={2} ml={2}>
        <Typography fontSize="25px" fontWeight="bold">
          {score}
        </Typography>
        <Rating value={score} readOnly />
        <Typography color="text.secondary">{num}レビュー</Typography>
      </Box>
    </Box>
  );
};
const ReviewBox = (props) => {
  const { ownData, otherData, ownResult, otherResult } = props;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="start"
      gap={5}
    >
      <Review
        sectionName="自社"
        score={ownData.reviewScore}
        num={ownData.reviewNum}
        result={ownResult}
      />
      <Review
        sectionName="競合"
        score={otherData.reviewScore}
        num={otherData.reviewNum}
        result={otherResult}
      />
    </Box>
  );
};

const Title = (props) => {
  const { title, sectionName, result } = props;
  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography fontWeight="bold" fontSize="15px">
          {sectionName}のタイトル
        </Typography>
        {result != '-' ? (
          result ? (
            <CheckCircleRoundedIcon
              sx={{ color: 'rgba(113,140,198,1)', width: '25px' }}
            />
          ) : (
            <AddCircleOutlinedIcon
              sx={{
                color: '#C3C3C3',
                width: '25px',
                transform: 'rotate(45deg)'
              }}
            />
          )
        ) : (
          ''
        )}
      </Box>
      <Box mt={2} ml={2}>
        <Typography color="text.secondary">{title}</Typography>
      </Box>
    </Box>
  );
};
const TitleBox = (props) => {
  const { ownData, otherData, ownResult, otherResult } = props;
  return (
    <Box display="flex" flexDirection="column" alignItems="start" gap={3}>
      <Title sectionName="自社" title={ownData.itemName} result={ownResult} />
      <Title
        sectionName="競合"
        title={otherData.itemName}
        result={otherResult}
      />
    </Box>
  );
};

const Description = (props) => {
  const { description, sectionName, result } = props;
  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography fontWeight="bold" fontSize="15px">
          {sectionName}の商品説明
        </Typography>
        {result != '-' ? (
          result ? (
            <CheckCircleRoundedIcon
              sx={{ color: 'rgba(113,140,198,1)', width: '25px' }}
            />
          ) : (
            <AddCircleOutlinedIcon
              sx={{
                color: '#C3C3C3',
                width: '25px',
                transform: 'rotate(45deg)'
              }}
            />
          )
        ) : (
          ''
        )}
      </Box>
      <Box mt={2} ml={2}>
        <Typography color="text.secondary">{description}</Typography>
      </Box>
    </Box>
  );
};
const DescriptionBox = (props) => {
  const { ownData, otherData, ownResult, otherResult } = props;
  return (
    <Box display="flex" flexDirection="column" alignItems="start" gap={3}>
      <Description
        sectionName="自社"
        description={ownData.description}
        result={ownResult}
      />
      <Description
        sectionName="競合"
        description={otherData.description}
        result={otherResult}
      />
    </Box>
  );
};
const ImgList = (props) => {
  const { imgList, sectionName, result } = props;
  const url = 'https://m.media-amazon.com/images/I/';
  const [selectImg, setSelectImg] = useState(imgList[0]);
  return (
    <Box width="45%" height="100%" display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" gap={1} mb={2}>
        <Typography fontWeight="bold" fontSize="15px">
          {sectionName}の商品画像
        </Typography>
        {result != '-' ? (
          result ? (
            <CheckCircleRoundedIcon
              sx={{ color: 'rgba(113,140,198,1)', width: '25px' }}
            />
          ) : (
            <AddCircleOutlinedIcon
              sx={{
                color: '#C3C3C3',
                width: '25px',
                transform: 'rotate(45deg)'
              }}
            />
          )
        ) : (
          ''
        )}
      </Box>
      {imgList.length > 0 ? (
        <Box display="flex" height="100%" gap={2} alignItems="start">
          {/* 画像一覧 */}
          <Box
            sx={{
              overflowY: 'auto', // スクロールを画像一覧のみに限定
              maxHeight: '60vh',
              // border: '1px solid #C3C3C3',
              padding: 1,
              width: '20%' // 全体の幅に応じてサイズ調整
            }}
          >
            {imgList.map((imgUrl, index) => (
              <Box
                key={index}
                mb={1}
                sx={{
                  border: 1,
                  borderColor: '#C3C3C3',
                  cursor: 'pointer'
                }}
                onClick={() => setSelectImg(imgUrl)}
              >
                <img
                  alt="item_img"
                  src={url + imgUrl}
                  style={{
                    display: 'inline-block',
                    width: '100%',
                    aspectRatio: '1',
                    objectFit: 'contain'
                  }}
                />
              </Box>
            ))}
          </Box>

          {/* 選択した画像の表示 */}
          <Box
            sx={{
              width: '80%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              alt="item_img"
              src={url + selectImg}
              style={{
                display: 'inline-block',
                width: '90%',
                aspectRatio: '1',
                objectFit: 'contain'
              }}
            />
          </Box>
        </Box>
      ) : (
        <Typography color="text.secondary">画像のデータがありません</Typography>
      )}
    </Box>
  );
};
const ImgListBox = (props) => {
  const { ownData, otherData, ownResult, otherResult } = props;
  return (
    <Box
      display="flex"
      alignItems="start"
      justifyContent="space-around"
      gap={3}
    >
      <ImgList
        sectionName="自社"
        imgList={ownData.imgList}
        result={ownResult}
      />
      <ImgList
        sectionName="競合"
        imgList={otherData.imgList}
        result={otherResult}
      />
    </Box>
  );
};

const DetailAnalysisDialog = (props) => {
  const { name, ownResult, otherResult } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const ownLoading = useSelector(performancereviewOwnDataLoading);
  const otherLoading = useSelector(performancereviewOtherDataLoading);
  const ownAmazonData = useSelector(performancereviewAmazonOwnItemData);
  const ownRakutenData = useSelector(performancereviewRakutenOwnItemData);
  const otherAmazonData = useSelector(performancereviewAmazonOtherItemData);
  const otherRakutenData = useSelector(performancereviewRakutenOtherItemData);
  const channel = useSelector(currentchannel);
  let ownData;
  let otherData;
  if (channel == 1) {
    ownData = ownAmazonData;
    otherData = otherAmazonData;
  } else if (channel == 2) {
    ownData = ownRakutenData;
    otherData = otherRakutenData;
  }
  const reviewFlag =
    name == '適切なレビュー評価' || name == '適切なレビュー件数';
  const titleFlag = name == 'タイトルの情報網羅';
  const descriptionFlag = name == '十分な商品説明';
  const imgFlag = name == '適切な商品画像';

  return (
    <>
      <IconButton
        onClick={(event) => {
          event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
          setModalOpen(true); // モーダルを開く
        }}
      >
        <BarChartIcon />
      </IconButton>
      <Dialog
        open={modalOpen}
        onClose={(event) => {
          event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
          setModalOpen(false); // モーダルを閉じる
        }}
        onClick={(event) => {
          event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
        }}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            maxHeight: '90vh', // ダイアログの最大高さを画面の90%に制限
            maxWidth: '80%', // 横幅を適宜調整
            overflow: 'hidden' // ダイアログ全体のスクロールを防ぐ
          }
        }}
      >
        <Box p={3} minWidth="300px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography fontWeight="bold" fontSize="17px" mb={2}>
              {name}の詳細分析
            </Typography>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                setModalOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            display="flex"
            alignItems="start"
            justifyContent="space-around"
            gap={3}
            sx={{ height: 'calc(100% - 50px)' }} // 上部のヘッダー分を引いた高さを設定
          >
            {reviewFlag && (
              <ReviewBox
                ownData={ownData}
                otherData={otherData}
                ownResult={ownResult}
                otherResult={otherResult}
              />
            )}
            {titleFlag && (
              <TitleBox
                ownData={ownData}
                otherData={otherData}
                ownResult={ownResult}
                otherResult={otherResult}
              />
            )}
            {descriptionFlag && (
              <DescriptionBox
                ownData={ownData}
                otherData={otherData}
                ownResult={ownResult}
                otherResult={otherResult}
              />
            )}
            {imgFlag && (
              <ImgListBox
                ownData={ownData}
                otherData={otherData}
                ownResult={ownResult}
                otherResult={otherResult}
              />
            )}
          </Box>
          {/* <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={(event) => {
                event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
                setModalOpen(false); // モーダルを閉じる
              }}
              variant="contained"
              color="groupSelected"
            >
              閉じる
            </Button>
          </Box> */}
        </Box>
      </Dialog>
    </>
  );
};

export default DetailAnalysisDialog;
