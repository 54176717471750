/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchIndustry = createAsyncThunk(
  'survey_login/post',
  async (arg, thunkAPI) => {
    const res = await client.post(`${apiUrl}industry`);
    return res.data;
  }
);

export const fetchPremiumRegister = createAsyncThunk(
  'checkout/post',
  async (arg, thunkApi) => {
    const {
      plan,
      amazonPeriod,
      rakutenPeriod,
      yahooPeriod,
      userId,
      password,
      paymentMethod,
      corporateNumber,
      industry,
      campaign
    } = arg;
    const res = await client.post(`${apiUrl}premium_register`, {
      plan,
      amazonPeriod,
      rakutenPeriod,
      yahooPeriod,
      login_id: userId,
      password,
      paymentMethod,
      corporateNumber,
      industry,
      campaign
    });
    return res.data;
  }
);

export const fetchTrialRegister = createAsyncThunk(
  'checkout/post',
  async (arg, thunkApi) => {
    const { mall, userId, password, rLoginId, rLoginPass, amazonSellerType } =
      arg;
    const res = await client.post(`${apiUrl}trial_register`, {
      mall,
      login_id: userId,
      password,
      rLoginId,
      rLoginPass,
      amazonSellerType
    });
    return res.data;
  }
);

//施策分析のみトライアル
export const fetchMeasuresTrialRegister = createAsyncThunk(
  'measure_trial/post',
  async (arg, thunkApi) => {
    const { mall, userId } = arg;
    const res = await client.post(`${apiUrl}measures_trial_register`, {
      mall,
      login_id: userId
    });
    return res.data;
  }
);

// 新規アカウント用の競合ビューのみトライアル
export const fetchMeasuresTrialRegisterNewAccount = createAsyncThunk(
  'measures_trial/post/new',
  async (arg, thunkApi) => {
    const { mall, userId } = arg;
    const res = await client.post(
      `${apiUrl}measures_trial_register_for_new_account`,
      {
        mall,
        login_id: userId
      }
    );
    return res.data;
  }
);

// トライアル期間中の有償プラン申込
export const fetchTrialPremiumRegister = createAsyncThunk(
  'checkout/post',
  async (arg, thunkApi) => {
    const {
      plan,
      amazonPeriod,
      rakutenPeriod,
      yahooPeriod,
      userId,
      paymentMethod,
      corporateNumber,
      industry,
      campaign,
      startMonth,
      paymentNumber,
      ecMall,
      legalStatus
    } = arg;

    const res = await client.post(`${apiUrl}trial_premium_register`, {
      plan,
      amazonPeriod,
      rakutenPeriod,
      yahooPeriod,
      login_id: userId,
      paymentMethod,
      corporateNumber,
      industry,
      campaign,
      startMonth,
      paymentNumber,
      ecMall,
      legalStatus
    });
    return res.data;
  }
);

// アカウント開設時にトライアル申込をしたかどうかのフラグ
export const trialRegisterTiming = createAsyncThunk(
  'trial/timing',
  async (arg, thunkAPI) => {
    const accountId = thunkAPI.getState().user.accountId;

    const res = await client.post(`${apiUrl}trial_mall`, {
      account_id: accountId
    });

    return res.data;
  }
);

const premiumRegister = createSlice({
  name: 'premiumRegister',
  initialState: {
    industryList: [],
    trialMallList: [],
    amazonDataStatus: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchIndustry.fulfilled, (state, action) => {
      return {
        ...state,
        industryList: action.payload.industry_list
      };
    });
    builder.addCase(trialRegisterTiming.fulfilled, (state, action) => {
      return {
        ...state,
        trialMallList: action.payload.trial_mall
      };
    });
    builder.addCase(fetchTrialRegister.fulfilled, (state, action) => {
      return {
        ...state,
        amazonDataStatus: action.payload.amazon_status
      };
    });
    builder.addCase(
      fetchMeasuresTrialRegisterNewAccount.fulfilled,
      (state, action) => {
        return {
          ...state
        };
      }
    );
  }
});

export const industryList = (state) => state.premiumRegister.industryList;
export const trialMallList = (state) => state.premiumRegister.trialMallList;
export const amazonStatus = (state) => state.premiumRegister.amazonDataStatus;
export default premiumRegister.reducer;
