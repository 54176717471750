/* eslint-disable  */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  CircularProgress,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { Box, Grid } from '@material-ui/core';
import {
  deleteProducts,
  deleteProductsList
} from 'feactures/product/deleteProductSlice';
import { fetchAsyncMyproducts } from 'feactures/product/myproductSlice';
import {
  fetchAsyncOtherproducts,
  selectOtherproducts
} from 'feactures/product/otherproductSlice';
import {
  registerProducts,
  registerStatus
} from 'feactures/product/registerProductSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  errorResMessage,
  errorResStatus,
  setError
} from 'feactures/errorSlice';
import ColorButton from 'components/atoms/button/ColorButton';
import download_icon from '../../organisms/layout/dashboard/icons/download_icon.svg';
import {
  exportStatus_productAnalysis,
  fetchExport_productAnalysis
} from 'feactures/monitoring/exportSlice';
import { userinfo } from 'feactures/user/userSlice';
import FreePlanConfirmationDialog from './FreePlanConfirmationDialog';
import FreePlanUpgradeDialog from './FreePlanUpgradeDialog';

const SimpleModal = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [inputAsin, setInputAsin] = useState('');
  const [flag, setFlag] = useState(true);
  const errorMessage = useSelector(errorResMessage); //errorメッセージ
  const channel = useSelector(currentchannel);
  const deleteList = useSelector(deleteProductsList);
  const reginsterLoading = useSelector(registerStatus);
  const [inputError, setInputError] = useState('');
  const productArray = useSelector(
    (state) => state.otherproducts.otherproducts
  );
  const userInfo = useSelector(userinfo);

  const productList =
    channel === 1
      ? productArray[0]
      : channel === 2
      ? productArray[1]
      : productArray[2];

  const exportStatus = useSelector(exportStatus_productAnalysis);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false); // 確認ダイアログの状態
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false); // 無料プランのアップグレード確認ダイアログ

  // 有料プランフラグをuseStateで管理
  const [premiumPlan, setPremiumPlan] = useState(false);
  useEffect(() => {
    if (channel == 1) {
      setPremiumPlan(userInfo.plan.amazon.premium);
    } else if (channel == 2) {
      setPremiumPlan(userInfo.plan.rakuten.premium);
    } else {
      setPremiumPlan(userInfo.plan.yahoo.premium);
    }
  }, [channel, userInfo]);

  useEffect(() => {
    const containsSpecialCharacters = /[\s\\￥¥]/.test(inputAsin);
    if (containsSpecialCharacters) {
      setInputError('登録できない文字が含まれます');
      setFlag(true);
    } else if (channel == 2) {
      var asinArray = String(inputAsin).split(',');
      const pattern = /^[^:]+:[^:]+$/;
      for (const asin of asinArray) {
        if (pattern.test(asin)) {
          setFlag(false);
        } else {
          setFlag(true);
          if (inputAsin != '') {
            setInputError('「shop code:item id」の形で入力してください');
          }
        }
      }
    } else {
      if (inputAsin) {
        setFlag(false);
      } else {
        setFlag(true);
      }
    }
  }, [inputAsin]);

  const changeAsin = (e) => {
    e.preventDefault();
    dispatch(setError(''));
    setInputError('');
    const asinList = e.target.value;
    setInputAsin(asinList);
  };

  const registerProduct = async (asinArray) => {
    // 商品登録処理
    await dispatch(registerProducts({ asin_code: asinArray }))
      .then(unwrapResult) //登録成功時にモーダルを閉じ、他のデータをフェッチ
      .then(unwrapResult) //登録成功時にモーダルを閉じ、他のデータをフェッチ
      .then(() => {
        handleClose();
        dispatch(fetchAsyncMyproducts());
        dispatch(fetchAsyncOtherproducts());
      })
      .catch((err) => {
        setInputAsin(asinArray);
        console.error('登録エラー:', err);
      });
  };

  // ダイアログの登録ボタンを押下したとき
  const registerHandle = async () => {
    const asinArray = String(inputAsin).split(',');
    if (!premiumPlan) {
      // 無料プランの場合は確認ダイアログを表示
      setOpenConfirmationDialog(true);
    } else {
      // 有料プランの場合、商品登録を確認するダイアログを表示
      const confirmMessage = `${asinArray.length}個の商品を登録します`;
      const isConfirmed = window.confirm(confirmMessage); // ユーザーに確認ダイアログを表示
      if (isConfirmed) {
        registerProduct(asinArray); // 確認後に商品登録
      }
    }
  };

  const handleConfirmationClose = (isConfirmed) => {
    if (isConfirmed) {
      const asinArray = String(inputAsin).split(',');
      registerProduct(asinArray);
    }
    setOpenConfirmationDialog(false);
  };

  const handleUpgradeDialogClose = () => {
    setOpenUpgradeDialog(false);
  };

  const handleClickOpen = () => {
    // 無料プランで、すでに商品が登録されている場合もアップグレードダイアログを表示
    if (!premiumPlan && productList.length > 0) {
      setOpenUpgradeDialog(true);
    } else {
      setOpen(true);
    }
  };

  const deleteProductList = () => {
    if (!premiumPlan) {
      // 無料プランの場合はアップグレードダイアログを表示
      setOpenUpgradeDialog(true);
    } else {
      if (
        window.confirm(
          //eslint-disable-line
          '削除しますか？　※選択された商品のデータは今後蓄積されません'
        )
      ) {
        dispatch(deleteProducts());
        dispatch(fetchAsyncMyproducts());
        dispatch(fetchAsyncOtherproducts());
      } else {
        console.log('cancel');
      }
    }
  };

  const handleClose = () => {
    dispatch(setError('')); //表示されているエラーをリセット
    setOpen(false);
    dispatch(fetchAsyncOtherproducts());
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="flex-end"
        sx={{ pt: '4px' }}
      >
        <Box sx={{ '& button': { m: 1 } }}>
          <div>
            <Button
              variant="contained"
              color="detailButton"
              onClick={handleClickOpen}
              // disabled={channel === 3}
            >
              商品登録
            </Button>

            <Button
              disabled={deleteList?.length == 0}
              variant="outlined"
              color="detailButton"
              onClick={deleteProductList}
            >
              商品削除
            </Button>

            <Tooltip
              title={
                exportStatus
                  ? 'CSVダウンロード中...'
                  : (channel == 1 && productList.length == 0) ||
                    (channel == 2 && productList.length == 0) ||
                    (channel == 3 && productList.length == 0)
                  ? ''
                  : 'CSVダウンロード'
              }
              placement="top"
            >
              <span>
                <IconButton
                  onClick={() => {
                    dispatch(fetchExport_productAnalysis());
                  }}
                  disabled={
                    exportStatus ||
                    (channel == 1 && productList.length == 0) ||
                    (channel == 2 && productList.length == 0) ||
                    (channel == 3 && productList.length == 0)
                  }
                >
                  <img
                    src={download_icon}
                    alt="ダウンロード"
                    style={{ width: '20px' }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </Box>
      </Grid>

      <Dialog open={open} onClose={handleClose} maxWidth="700px">
        <DialogTitle>商品登録</DialogTitle>
        <DialogContent sx={{ pt: '10px !important' }}>
          {/* <SimpleField handleClose={handleClose} /> */}
          <TextField
            id="outlined-search"
            label={
              channel == 1
                ? 'ASIN'
                : channel == 2
                ? 'ショップコード:商品管理番号'
                : '商品番号'
            }
            type="search"
            value={inputAsin}
            onChange={changeAsin}
            fullWidth
          />
          {inputError != '' && (
            <Typography color="red" style={{ whiteSpace: 'pre-line' }}>
              {inputError}
            </Typography>
          )}
          {errorMessage ? (
            <Typography color="red" style={{ whiteSpace: 'pre-line' }}>
              {errorMessage}
            </Typography>
          ) : (
            <Typography>
              {channel === 2 && (
                <>
                  商品登録は、ショップコードと商品管理番号をコロン( :
                  )区切りで設定して下さい
                  <br />
                </>
              )}
              複数商品を登録する際は、カンマで区切ってください
              （最大で100個まで登録可能）
              <br />
              {channel === 1 && <>※入力例：B2FBDK23, BLSIF6U7, BLDIEM5</>}
              {channel === 2 && (
                <>
                  ※入力例：oxcim-shop:1234567
                  <br />
                  ▼ショップコード確認方法
                  <br />
                  該当の商品ページを表示し、URLをご確認ください。
                  <br />
                  https://item.rakuten.co.jp/&#123;ショップコード&#125;/&#123;商品管理番号&#125;/
                </>
              )}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          {errorMessage == '1000位以内に入っていない商品が含まれます' ? (
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          ) : (
            <>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <ColorButton disabled={flag} onClick={registerHandle}>
                {reginsterLoading ? <CircularProgress size={15} /> : '登録'}
              </ColorButton>
            </>
          )}
        </DialogActions>
      </Dialog>

      {/* 無料プラン確認ダイアログ */}
      <FreePlanConfirmationDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={handleConfirmationClose}
      />

      {/* 無料プランアップグレードダイアログ */}
      <FreePlanUpgradeDialog
        open={openUpgradeDialog}
        onClose={handleUpgradeDialogClose}
      />
    </div>
  );
};
export default SimpleModal;
