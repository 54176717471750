/* eslint-disable */
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Button,
  Link,
  Tooltip,
  Typography
} from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { currentchannel } from 'feactures/channel/channelSlice';

const bgColorList = [
  '#738DC4',
  '#D0D8ED',
  '#B7C2E2',
  '#5C71A6',
  '#9FAED7',
  '#84A0CE'
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: '4px 8px'
}));

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#F3F4F6',
  padding: '8px'
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#f0f0f0'
  },
  height: '30px'
}));

const BrandItemShareTable = (props) => {
  const { data } = props;
  const combined = data.map((top5item, i) => ({
    rank: i + 1,
    ...top5item
  }));

  return (
    <>
      <TableContainer
        sx={{
          width: '49%',
          borderRadius: '10px',
          my: 2,
          boxShadow: '0px 2px 5px lightgray'
        }}
      >
        <Box
          sx={{
            fontSize: '12px',
            fontWeight: 'bold',
            backgroundColor: '#F9FAFB',
            padding: 2
          }}
        >
          商品別シェア詳細
        </Box>
        <Table stickyHeader size="small">
          <TableHead sx={{ maxHeight: '38px' }}>
            <StyledTableRow>
              <StyledTableHeader
                sx={{
                  minWidth: '40px'
                }}
              >
                順位
              </StyledTableHeader>
              <StyledTableHeader
                align="left"
                sx={{
                  minWidth: '120px',
                  maxWidth: '50%'
                }}
              >
                商品名
              </StyledTableHeader>
              <StyledTableHeader
                align="left"
                sx={{
                  minWidth: '65px'
                }}
              >
                シェア
              </StyledTableHeader>
              <StyledTableHeader align="left">売上</StyledTableHeader>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {combined.length !== 0 ? (
              <>
                {combined.map((row, index) => {
                  return (
                    <StyledTableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 }
                      }}
                    >
                      <StyledTableCell align="center">
                        {row.rank}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left',
                            gap: '10px',
                            width: '100%'
                          }}
                          component="div"
                          textOverflow="ellipsis"
                          overflow="hidden"
                        >
                          <Box
                            sx={{
                              width: '10px',
                              height: '10px',
                              borderRadius: '10px',
                              backgroundColor:
                                row.product_name == 'その他'
                                  ? '#A0A0A0'
                                  : bgColorList[index]
                            }}
                          ></Box>
                          <Typography sx={{ maxWidth: '93%' }}>
                            {row.product_name}
                          </Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.percentage}％
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.sales_sum == 0
                          ? '-'
                          : '¥' + row.sales_sum.toLocaleString()}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </>
            ) : (
              <StyledTableRow>
                <StyledTableCell>データがありません</StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BrandItemShareTable;
