/* eslint-disable*/

import { Container, Grid, colors, Typography } from '@material-ui/core'; // eslint-disable-line
import { useSelector } from 'react-redux';
import { currentchannel } from 'feactures/channel/channelSlice';
import BrandShareChangeGraph from 'components/molecules/dashboard/BrandShareChangeGraph';
import { monthlyShareDataList } from 'feactures/category/brandShareSlice';
import { useState } from 'react';

const BrandShareChange = (props) => {
  const { yokoziku } = props;
  let graphdata = useSelector(monthlyShareDataList);
  const channel = useSelector(currentchannel);
  let yoko = [...yokoziku];
  let title = '';

  if (channel == 1) {
    // Amazonは2023/2以前は表示させない
    const targetDate = '2023/2';
    const index = yokoziku.findIndex((date) => date === targetDate);
    if (index !== -1 && graphdata[0].data.length > 0) {
      yoko.splice(0, index + 1);
      // let newData = JSON.parse(JSON.stringify(graphdata)); // オブジェクト全体をコピー
      // newData.splice(0, index + 1); // graphdataを変更
      // graphdata = newData; // 新しいオブジェクトを代入
    }
    title = 'ブランド';
  } else if (channel == 2) {
    // 楽天は2023/7以前は表示させない
    const targetDate = '2023/7';
    const index = yokoziku.findIndex((date) => date === targetDate);
    if (index !== -1 && graphdata.length > 0) {
      yoko.splice(0, index + 1);
      // let newData = JSON.parse(JSON.stringify(graphdata)); // オブジェクト全体をコピー
      // newData.graphdata.splice(0, index + 1); // graphdataを変更
      // graphdata = newData; // 新しいオブジェクトを代入
    }
    title = '店舗';
  }

  return (
    <>
      <Container maxWidth={false} sx={{ mt: 2 }}>
        <Grid container rowSpacing={2.5} columnSpacing={1.5}>
          <Grid item xs={12}>
            <Typography fontSize="14px" fontWeight="bold">
              {title}シェア推移
            </Typography>
            <Typography fontSize="12px" color={'gray'} sx={{ mt: 1 }}>
              主要{title}の過去12ヵ月のシェア推移（単位：％）
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* 棒グラフ */}
            <BrandShareChangeGraph graphdata={graphdata} yokoziku={yoko} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default BrandShareChange;
