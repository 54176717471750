/* eslint-disable*/
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  performanceModalOpen,
  performancereviewAmazonOtherReviewResult,
  performancereviewAmazonOwnReviewResult,
  performancereviewOtherDataLoading,
  performancereviewOwnDataLoading,
  performancereviewRakutenOtherReviewResult,
  performancereviewRakutenOwnReviewResult,
  setPerformanceModalOpen
} from 'feactures/performancereview/performancereviewSlice';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import PerformancePullDown from 'components/atoms/button/PerformancePullDown';
import DetailAnalysisDialog from './DetailAnalysisDialog';

const DetailResultDialog = (props) => {
  const { index } = props;
  const dispatch = useDispatch();
  const modalOpen = useSelector(performanceModalOpen);
  const ownLoading = useSelector(performancereviewOwnDataLoading);
  const otherLoading = useSelector(performancereviewOtherDataLoading);
  const ownAmazonDataList = useSelector(performancereviewAmazonOwnReviewResult);
  const ownRakutenDataList = useSelector(
    performancereviewRakutenOwnReviewResult
  );
  const otherAmazonDataList = useSelector(
    performancereviewAmazonOtherReviewResult
  );
  const otherRakutenDataList = useSelector(
    performancereviewRakutenOtherReviewResult
  );
  const channel = useSelector(currentchannel);
  let ownDataList = [];
  let otherDataList = [];
  if (channel == 1) {
    ownDataList = ownAmazonDataList;
    otherDataList = otherAmazonDataList;
  } else if (channel == 2) {
    ownDataList = ownRakutenDataList;
    otherDataList = otherRakutenDataList;
  }
  const columns = ['項目', '自社', '競合', '詳細'];
  return (
    <>
      <Button
        color="groupSelected"
        variant="contained"
        onClick={(event) => {
          event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
          dispatch(setPerformanceModalOpen({ index, open: true })); // モーダルを開く
        }}
        size="small"
        sx={{ minWidth: '50px' }}
      >
        詳細
      </Button>
      <Dialog
        open={modalOpen.open && index == modalOpen.index}
        onClose={(event) => {
          event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
          dispatch(setPerformanceModalOpen({ index: 0, open: false })); // モーダルを閉じる
        }}
        onClick={(event) => {
          event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
        }}
        maxWidth="60％"
      >
        <Box p={3}>
          <Typography
            color="rgba(113,140,198,1)"
            fontWeight="bold"
            fontSize="17px"
            pb={2}
          >
            {ownDataList[index].name}の詳細
          </Typography>
          <PerformancePullDown graphLoading={ownLoading || otherLoading} />
          {ownLoading || otherLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="345px"
              height="200px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer
              component={Paper}
              sx={{ boxShadow: 0, position: 'relative' }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column, cindex) => (
                      <TableCell
                        key={column}
                        align={cindex == 0 ? 'left' : 'center'}
                      >
                        <Typography
                          color="rgba(113,140,198,1)"
                          fontWeight="bold"
                          fontSize="13px"
                        >
                          {column}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ownDataList[index].details.map((row, index1) => (
                    <TableRow key={index1}>
                      <TableCell>
                        <Typography
                          color="text.secondary"
                          fontWeight="bold"
                          fontSize="13px"
                        >
                          {row.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {row.evaluationFlag == 1 ? (
                          <CheckCircleRoundedIcon
                            sx={{ color: 'rgba(113,140,198,1)', width: '25px' }}
                          />
                        ) : (
                          <AddCircleOutlinedIcon
                            sx={{
                              color: '#C3C3C3',
                              width: '25px',
                              transform: 'rotate(45deg)'
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {otherDataList.length != 0 ? (
                          otherDataList[index].details[index1].evaluationFlag ==
                          1 ? (
                            <CheckCircleRoundedIcon
                              sx={{
                                color: 'rgba(113,140,198,1)',
                                width: '25px'
                              }}
                            />
                          ) : (
                            <AddCircleOutlinedIcon
                              sx={{
                                color: '#C3C3C3',
                                width: '25px',
                                transform: 'rotate(45deg)'
                              }}
                            />
                          )
                        ) : (
                          <Typography color="text.secondary">-</Typography>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <DetailAnalysisDialog
                          name={row.name}
                          ownResult={row.evaluationFlag}
                          otherResult={
                            otherDataList.length != 0
                              ? otherDataList[index].details[index1]
                                  .evaluationFlag
                              : '-'
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            pt={2}
          >
            <Button
              onClick={(event) => {
                event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
                dispatch(setPerformanceModalOpen({ index: 0, open: false })); // モーダルを閉じる
              }}
              variant="contained"
              color="groupSelected"
              sx={{ minWidth: '70%' }}
            >
              閉じる
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DetailResultDialog;
