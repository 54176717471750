/* eslint-disable*/
import { Box, IconButton, Typography, useTheme } from '@material-ui/core';
import {
  fetchDeleteSeoKeyword,
  fetchKeywordList,
  performanceSeoData,
  performanceSeoSetting,
  resetKeyword
} from 'feactures/performancereview/performancereviewSlice';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { unwrapResult } from '@reduxjs/toolkit';

const SeoCompareLineGraph = () => {
  const setting = useSelector(performanceSeoSetting);
  const seodata = useSelector(performanceSeoData);
  const theme = useTheme();
  const dispatch = useDispatch();
  let label = '';
  let organicColor = '';
  let sponsorColor = '';
  if (setting.compareType == 'own') {
    label = '自社';
    organicColor = '#ACABE3';
    sponsorColor = '#A3D7B5';
  } else if (setting.compareType == 'other') {
    label = '競合';
    organicColor = '#FFD88F';
    sponsorColor = '#FEA165';
  }

  const data = {
    datasets: [
      {
        label: `${label}オーガニック`,
        data: seodata.organic.map((x) =>
          x == -99 ? (x = '圏外') : x == 0 ? (x = 'データ無し') : (x = x)
        ),
        radius: 0,
        barThickness: 'flex',
        backgroundColor: organicColor,
        hoverBackgroundColor: organicColor,
        borderColor: organicColor,
        maxBarThickness: 20,
        pointRadius: 4,
        pointStyle: 'circle',
        pointBorderColor: organicColor,
        pointBackgroundColor: organicColor,
        pointBorderWidth: 1,
        lineTension: 0.25
      },
      {
        label: `${label}広告`,
        data: seodata.sponsor.map((x) =>
          x == -99 ? (x = '圏外') : x == 0 ? (x = 'データ無し') : (x = x)
        ),
        radius: 0,
        barThickness: 'flex',
        backgroundColor: sponsorColor,
        hoverBackgroundColor: sponsorColor,
        borderColor: sponsorColor,
        maxBarThickness: 20,
        pointRadius: 4,
        pointStyle: 'circle',
        pointBorderColor: sponsorColor,
        pointBackgroundColor: sponsorColor,
        pointBorderWidth: 1,
        lineTension: 0.25
      }
    ],
    labels: seodata.yokoziku
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        title: {
          display: false
        },
        ticks: {
          font: {
            size: '10px'
          }
        }
      },
      y: {
        reverse: true,
        ticks: {
          fontColor: theme.palette.text.secondary,
          font: {
            family: "'M PLUS 1p', sans-serif"
          },
          callback: function (value, index, values) {
            if (value === 0) {
              // 0を非表示にする
              return '';
            }
            let returnValue = value;
            returnValue = value + '位';
            return returnValue;
          }
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    },
    plugins: {
      tooltip: {
        titleFont: {
          family: "'M PLUS 1p', sans-serif"
        },
        bodyFont: {
          family: "'M PLUS 1p', sans-serif"
        },
        callbacks: {
          label: (tooltipItem) => {
            let value = tooltipItem.raw;
            return value > 0 ? `${tooltipItem.dataset.label}: ${value}` : ''; // 0以下は表示しない
          }
        }
      },
      title: {
        display: false,
        position: 'left'
      },
      legend: {
        labels: {
          boxWidth: 40,
          boxHeight: 10
          //   usePointStyle: true
          //   pointStyle: 'circle'
        },
        position: 'bottom'
      },
      datalabels: {
        display: false
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    cornerRadius: 20,
    layout: {
      maxwidth: '100px',
      padding: {
        left: 0,
        right: 20,
        top: 0,
        bottom: 0
      }
    }
  };

  const deleteKeyword = async () => {
    if (
      window.confirm(
        //eslint-disable-line
        'キーワードを削除しますか？※削除されたキーワードのデータは今後蓄積されません'
      )
    ) {
      await dispatch(fetchDeleteSeoKeyword())
        .then(unwrapResult) //registerProducts削除が成功（fullfilled）のときはモーダルを閉じる
        .then(() => {
          dispatch(fetchKeywordList());
          dispatch(resetKeyword());
          window.confirm('キーワード削除が完了しました');
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log('cancel');
    }
  };
  return (
    <Box height="70%">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1}
      >
        <Typography fontWeight="bold">{setting.keyword}</Typography>
        <IconButton
          onClick={(event) => {
            event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
            deleteKeyword();
          }}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography fontWeight="bold" color="text.secondary" mb={1}>
          月平均順位
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mb={1}>
        <Box width="48%" bgcolor="#F8FAFB" p="4px">
          <Typography color="text.secondary">{label}オーガニック</Typography>
          {seodata.organicRank == 0 ? (
            <Typography>圏外</Typography>
          ) : (
            <Box display="flex" alignItems="end">
              <Typography fontWeight="bold" fontSize="17px">
                {seodata.organicRank}
              </Typography>
              <Typography ml="4px" color="text.secondary">
                位
              </Typography>
            </Box>
          )}
        </Box>
        <Box width="48%" bgcolor="#F8FAFB" ml="1%" p="4px">
          <Typography color="text.secondary">{label}広告</Typography>
          {seodata.sponsorRank == 0 ? (
            <Typography>圏外</Typography>
          ) : (
            <Box display="flex" alignItems="end">
              <Typography fontWeight="bold" fontSize="17px">
                {seodata.sponsorRank}
              </Typography>
              <Typography ml="4px" color="text.secondary">
                位
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Line width="100%" data={data} options={options} />
    </Box>
  );
};

export default SeoCompareLineGraph;
