/* eslint-disable*/
import { styled } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Box,
  Typography,
  TableRow,
  TableCell,
  Button,
  Link,
  IconButton,
  Rating
} from '@material-ui/core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import ProductRankingDetailTable from 'components/organisms/content/measure/ProductRankingDetailTable';
import 'chartjs-adapter-moment';
import KeywordRankingGraphModal from 'components/atoms/modal/KeywordRankingGraph';
import KeywordRegisterModal from 'components/atoms/modal/KeywordRegisterModal';
import logo_url from './images/favicon.png';
import {
  keywordList,
  addDeleteList,
  removeDeleteList,
  getSeoGraphData
} from 'feactures/measure/newMeasureSlice';
import {
  currentchannel,
  currentchannelName
} from 'feactures/channel/channelSlice';
import { userinfo } from 'feactures/user/userSlice';

const BodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: '12px',
  textAlign: 'center',
  wordBreak: 'break-all',
  padding: '8px 2px'
}));

const StyledTableRow = styled(TableRow)((props) => ({
  '&:hover': {
    backgroundColor: props.hoverColor
  }
}));

const CustomButton = styled(Button)((props) => ({
  '&:hover': {
    backgroundColor: '#8ba4e7'
  }
}));

export default function MeasureAnalysisTableRow({ item, index }) {
  const url = 'https://m.media-amazon.com/images/I/';
  const dispatch = useDispatch();
  const userInfo = useSelector(userinfo);
  const channel = useSelector(currentchannel);
  const channelName = useSelector(currentchannelName);
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [keywordOpen, setKeywordOpen] = useState(false);
  const keyword_list = useSelector(keywordList);
  const filtered_keyword_list = keyword_list.filter(
    (keyword) =>
      item.item_id === keyword.item_id && item.shop_code === keyword.shop_code
  );

  const showDetailTable = () => {
    setExpanded(!expanded);
  };

  const handleOpen = (e, item_id, shop_code) => {
    // ここでキーワード追跡dispatch
    dispatch(getSeoGraphData({ item_id, shop_code }));
    setOpen(true);
    e.stopPropagation();
  };

  const handleKeywordOpen = (e) => {
    setKeywordOpen(true);
    e.stopPropagation();
  };

  const handleCheckbox = (e) => {
    if (e.target.checked) {
      dispatch(addDeleteList(item.id));
    } else {
      dispatch(removeDeleteList(item.id));
    }
    e.stopPropagation();
  };

  return (
    <>
      <StyledTableRow
        onClick={showDetailTable}
        hoverColor={'#f5f5f5'}
        key={item.id}
      >
        <BodyCell>{index + 1}</BodyCell>
        <BodyCell>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {item.id === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                bgcolor="#728DC4"
                borderRadius={5}
                px={1}
                py="4px"
              >
                <Typography color="#FFFFFF" fontWeight="bold">
                  Sample
                </Typography>
              </Box>
            ) : (
              <Checkbox
                onClick={handleCheckbox}
                disabled={!userInfo.plan[channelName]?.premium}
                sx={{
                  '&.MuiCheckbox-root.Mui-disabled': {
                    cursor: 'not-allowed',
                    pointerEvents: 'auto'
                  }
                }}
              />
            )}
            <CustomButton
              onClick={showDetailTable}
              variant="contained"
              color="detailButton"
              sx={{
                borderRadius: '50px',
                mt: 1
              }}
            >
              詳細
            </CustomButton>
          </Box>
        </BodyCell>
        <BodyCell>
          <Box
            sx={{
              width: '70px',
              height: '70px',
              borderRadius: '4px',
              mx: 'auto'
            }}
          >
            <img
              src={
                item['latest_data'] === '' ||
                item['latest_data']['img_url'] === '-' ||
                item['latest_data']['img_url'] === 0
                  ? logo_url
                  : channel == 1
                  ? url + item['latest_data']['img_url']
                  : item['latest_data']['img_url']
              }
              height="100%"
              loading="lazy"
              width="100%"
            />
          </Box>
        </BodyCell>
        <BodyCell>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                textAlign: 'left'
              }}
            >
              <Link
                sx={{
                  fontSize: '12px',
                  textDecoration: 'none'
                }}
                href={
                  item['latest_data'] === '' ||
                  item['latest_data']['item_id'] === 0 ||
                  item['latest_data']['shop_code'] === 0
                    ? ''
                    : channel === 1
                    ? `https://www.amazon.co.jp/dp/${item['item_id']}`
                    : `https://item.rakuten.co.jp/${item['shop_code']}/${item['item_id']}/`
                }
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                {item['latest_data'] === '' ||
                item['latest_data']['title'] == '0'
                  ? item['item_name']
                  : item['latest_data']['title']}
              </Link>
            </Box>
            <Typography sx={{ textAlign: 'left', mt: 1 }}>
              {item['shop_code'] !== '' && `${item['shop_code']}:`}
              {item['item_id']}
            </Typography>
          </Box>
        </BodyCell>
        <BodyCell>
          {item['latest_data'] === '' || item['latest_data']['category'] === '0'
            ? '-'
            : item['latest_data']['category']}
        </BodyCell>
        <BodyCell>
          {item['latest_data'] === '' || item['latest_data']['price'] === 0
            ? '-'
            : `¥${item['latest_data']['price'].toLocaleString()}`}
        </BodyCell>
        <BodyCell>
          {item['latest_data'] === '' || item['latest_data']['rank'] === 0
            ? '-'
            : item['latest_data']['rank'] === -99
            ? '圏外'
            : `${item['latest_data']['rank'].toLocaleString()}位`}
        </BodyCell>
        <BodyCell sx={{ textAlign: 'right' }}>
          <Rating
            value={
              item['latest_data'] === '' ? 0 : item['latest_data']['review']
            }
            precision={0.5}
            readOnly
            size="small"
          />
        </BodyCell>
        <BodyCell sx={{ textAlign: 'left', pl: '20px' }}>
          {item['latest_data'] === ''
            ? 0
            : item['latest_data']['review_num'].toLocaleString()}
        </BodyCell>
        <BodyCell>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Button
              onClick={(e) => handleOpen(e, item.item_id, item.shop_code)}
              disabled={channel == 1}
            >
              {filtered_keyword_list.length}個
            </Button>
            <CustomButton
              variant="contained"
              color="detailButton"
              sx={{
                borderRadius: '50px',
                mt: 1
                // zIndex: 10
              }}
              onClick={handleKeywordOpen}
              disabled={channel == 1}
            >
              キーワード登録
            </CustomButton>
          </Box>
        </BodyCell>
      </StyledTableRow>
      {expanded && (
        <TableRow>
          <TableCell colSpan={9} sx={{ px: '8px' }}>
            <ProductRankingDetailTable
              expanded={expanded}
              setExpanded={setExpanded}
              data={item['report']}
              itemName={item['item_name']}
            />
          </TableCell>
        </TableRow>
      )}
      <KeywordRankingGraphModal
        open={open}
        setOpen={setOpen}
        itemName={
          item['latest_data'] === '' || item['latest_data']['title'] === '0'
            ? item['item_name']
            : item['latest_data']['title']
        }
      />
      <KeywordRegisterModal
        open={keywordOpen}
        setOpen={setKeywordOpen}
        keywordList={filtered_keyword_list}
        itemId={item.item_id}
        shopCode={item.shop_code}
      />
    </>
  );
}
