/* eslint-disable*/
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  CircularProgress,
  Modal,
  Divider
} from '@material-ui/core'; // eslint-disable-line
import NewButtongroup from 'components/atoms/group/NewButtongroup';
import NewPullDown from 'components/atoms/button/NewPullDown';
import RankingTotal from 'components/organisms/content/category/RankingTotal';
import {
  categoryRankStatus,
  categoryTop5IsLoading
} from 'feactures/category/categoryRankSlice';
import { categorygraphStatus } from 'feactures/category/categoryGraphSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  amazonCategory,
  rakutenCategory,
  yahooCategory
} from 'feactures/category/categorySlice';
import {
  fetchFavoriteCategory,
  favoriteCategoryStatus
} from 'feactures/category/favoriteCategorySlice';
import {
  brandgraphShareStatus,
  monthlygraphStatus
} from 'feactures/category/brandShareSlice';
import StickyAnnotation from 'components/atoms/annotation/StickyAnnotation';
import NewDialogSelect from 'components/atoms/select/NewDialogSelect';
import FavoriteCategoryPullDown from 'components/atoms/button/FavoriteCategoryPullDown';
import CategoryTotal from 'components/organisms/content/category/CategoryTotal';
// import MarketTable from 'components/organisms/content/category/MarketTable';
// import CategoryShare from 'components/organisms/content/category/CategoryShare';
import BrandShare from 'components/organisms/content/category/BrandShare';
// import {
//   categorygraphShareStatus,
//   fetchCategoryShareGraphData
// } from 'feactures/category/categoryShareSlice';
// import CategoryTable from 'components/organisms/content/category/CategoryTable';
// import StickySurvey from 'components/atoms/survey/StickySurvey';
import MarketLabel from 'components/molecules/dashboard/MarketLabel';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { changePage } from 'feactures/date/dateSlice';
import RisingItemTable from 'components/organisms/content/category/RisingItemTable';
import BrandShareChange from 'components/organisms/content/category/BrandShareChange';
import BrandItemShare from 'components/organisms/content/category/BrandItemShare';
import FreePlanRegisterModal from 'components/atoms/modal/FreePlanRegisterModal';
import { userinfo } from 'feactures/user/userSlice';

const NewCategoryboard = ({
  freePlanRegisterModalOpen,
  updateFreePlanRegisterInfosSuccessFlag
}) => {
  const channel = useSelector(currentchannel);
  const top100loading = useSelector(categoryRankStatus);
  const top5loading = useSelector(categoryTop5IsLoading);
  const graphloading = useSelector(categorygraphStatus); //こっちの方がloading時間が長いので一旦こっち
  const brandShareLoading = useSelector(brandgraphShareStatus);
  const brandShareListLoading = useSelector(monthlygraphStatus);
  const amazonCategories = useSelector(amazonCategory);
  const rakutenCategories = useSelector(rakutenCategory);
  const yahooCategories = useSelector(yahooCategory);
  const favoriteCategoryLoading = useSelector(favoriteCategoryStatus);
  const userInfo = useSelector(userinfo);
  const [yokoziku, setYokoziku] = useState([]);
  const dispatch = useDispatch();

  // 戻るボタン押されたとき用
  useEffect(() => {
    dispatch(changePage('newCategoryBoard'));
  }, []);

  // useEffect(() => {
  //   // '選択済みの第一カテゴリがない時のみfetchAsyncCategory1をする
  //   if (
  //     Boolean(
  //       currentSelectAmazonCategory1 ||
  //         currentSelectRakutenCategory1 ||
  //         currentSelectYahooCategory1
  //     ) == false
  //   ) {
  //     dispatch(fetchFavoriteCategory());
  //     // dispatch(fetchAllCategoryGraphData());
  //     dispatch(fetchAsyncCategory1());
  //   }
  //   dispatch(fetchCategoryShareGraphData());
  //   dispatch(fetchCategoryGraphData());
  // }, [channel]);

  let categories = '';

  if (channel == 1) {
    categories = amazonCategories;
  } else if (channel == 2) {
    categories = rakutenCategories;
  } else if (channel == 3) {
    categories = yahooCategories;
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '100'
  };

  return (
    <>
      {favoriteCategoryLoading && (
        <Modal open={favoriteCategoryLoading}>
          <Box sx={style}>
            <p>カテゴリ読み込み中...</p>
            <CircularProgress />
          </Box>
        </Modal>
      )}
      <Box
        sx={{
          backgroundColor: '#FFF8E5',
          height: 'auto',
          minHeight: '100vh',
          width: '100%',
          pb: '80px'
        }}
      >
        <Container maxWidth={false} sx={{ width: 'auto', p: 0 }}>
          <Box
            position="sticky"
            top="42px"
            bgcolor="#FFF8E5"
            zIndex={3}
            height="auto"
          >
            {/* {channel == 2 && <StickyAnnotation />} */}
            <Box display="flex" alignItems="center" height="33px">
              <Typography fontSize="14px">マーケットビュー</Typography>
              <ArrowForwardIosOutlinedIcon
                sx={{ fontSize: '16px', mx: 1, color: 'gray' }}
              />
              <Typography fontSize="14px" fontWeight="bold">
                カテゴリ分析
              </Typography>
            </Box>
            <Box
              mb={2}
              sx={{
                display: 'flex',
                borderTop: 1,
                borderColor: '#FFFFFF',
                alignItems: 'end'
              }}
            >
              <Box mr={3}>
                {/* <Typography fontSize="14px">モール</Typography> */}
                <NewButtongroup amazonOnly={true} page="category" />
              </Box>
              {!freePlanRegisterModalOpen && (
                <>
                  <Box mr={3}>
                    {/* <Typography fontSize="14px">期間</Typography> */}
                    <NewPullDown
                      page="category"
                      label="期間"
                      setYokoziku={setYokoziku}
                      graphLoading={
                        graphloading ||
                        brandShareLoading ||
                        top100loading ||
                        brandShareListLoading ||
                        top5loading
                      }
                    />
                  </Box>
                  <Box mr={3}>
                    <Typography fontSize="14px">カテゴリを選択する</Typography>
                    <NewDialogSelect
                      categories={categories}
                      channel={channel}
                      page="category"
                      graphLoading={
                        graphloading ||
                        brandShareLoading ||
                        top100loading ||
                        brandShareListLoading ||
                        top5loading
                      }
                    />
                  </Box>
                  <Box>
                    <Typography fontSize="14px">お気に入りカテゴリ</Typography>
                    <FavoriteCategoryPullDown
                      page="category"
                      graphLoading={
                        graphloading ||
                        brandShareLoading ||
                        top100loading ||
                        brandShareListLoading ||
                        top5loading
                      }
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>
          {!freePlanRegisterModalOpen ? (
            <>
              <Box
                display="flex"
                alignItems="flex-end"
                justifyContent="space-between"
                mb={2}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '49%'
                  }}
                >
                  <MarketLabel />
                  <Box
                    bgcolor="#FFFFFF"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={3}
                    height="400px" //仮
                  >
                    {graphloading ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column'
                        }}
                      >
                        <p>グラフの読み込み中…</p>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <CategoryTotal yokoziku={yokoziku} />
                    )}
                  </Box>
                </Box>
                <Box
                  width="49%"
                  bgcolor="#FFFFFF"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={3}
                  height="400px" //仮
                >
                  {brandShareLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <p>グラフの読み込み中…</p>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <BrandShare />
                  )}
                </Box>
              </Box>
              <Box mb={2} display="flex" justifyContent="center">
                <Box width="100%" bgcolor="#FFFFFF" borderRadius={3}>
                  {brandShareListLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <p>グラフの読み込み中…</p>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%'
                      }}
                    >
                      <BrandShareChange yokoziku={yokoziku} />
                      <Divider sx={{ width: '95%', my: 3 }} />
                      <BrandItemShare />
                    </Box>
                  )}
                </Box>
              </Box>
              <Box mb={2} display="flex" justifyContent="center">
                <Box width="100%" bgcolor="#FFFFFF" borderRadius={3}>
                  {top5loading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <p>ランキングの読み込み中…</p>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <RisingItemTable yokoziku={yokoziku} />
                  )}
                </Box>
              </Box>
              <Box mb={2} display="flex" justifyContent="center">
                <Box width="100%" bgcolor="#FFFFFF" borderRadius={3}>
                  {top100loading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <p>ランキングの読み込み中…</p>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <RankingTotal yokoziku={yokoziku} />
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <FreePlanRegisterModal
              updateFreePlanRegisterInfosSuccessFlag={
                updateFreePlanRegisterInfosSuccessFlag
              }
              categories={categories}
            />
          )}
        </Container>
      </Box>
    </>
  );
};

export default NewCategoryboard;
