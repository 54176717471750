/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

//ユーザー情報変更
export const fetchChangeProfile = createAsyncThunk(
  'change_profile/post',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { userId, userName } = arg;
    const res = await client.post(`${apiUrl}change_profile`, {
      account_id: accountId,
      user_id: userId,
      user_name: userName
    });
    return res.data;
  }
);

//広告ID変更
export const fetchChangeAdvertiseId = createAsyncThunk(
  'change_advertise_id/post',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { saId, dspId } = arg;
    // console.log(saId, dspId);
    const res = await client.post(`${apiUrl}change_advertise_id`, {
      account_id: accountId,
      sa_id: saId,
      dsp_id: dspId
    });
    return res.data;
  }
);

// //楽天RMSAuthCheck
// export const fetchRmsAuthCheck = createAsyncThunk(
//   'check_rms_key/post',
//   async (arg, thunkAPI) => {
//     const { accountId, userId } = thunkAPI.getState().user;
//     const { serviceSecret, licenseKey, rCsvStatus, rLoginId, rLoginPass } = arg;
//     console.log('checkarg', arg);
//     const res = await client.post(`${apiUrl}check_rms_key`, {
//       account_id: accountId,
//       user_id: userId,
//       serviceSecret: serviceSecret,
//       licenseKey: licenseKey
//       // r_login_id: rLoginId,
//       // r_login_pass: rLoginPass,
//       // r_csv_status: rCsvStatus
//     });
//     return res.data;
//   }
// );

//楽天RMSKey&ID変更
export const fetchChangeRmsKey = createAsyncThunk(
  'change_rms_id/post',
  async (arg, thunkAPI) => {
    const { accountId, userId } = thunkAPI.getState().user;
    const { serviceSecret, licenseKey, rCsvStatus, rLoginId, rLoginPass } = arg;
    const res = await client.post(`${apiUrl}change_rms_id`, {
      account_id: accountId,
      user_id: userId,
      serviceSecret: serviceSecret,
      licenseKey: licenseKey,
      r_login_id: rLoginId,
      r_login_pass: rLoginPass,
      r_csv_status: rCsvStatus
    });
    return res.data;
  }
);

//パスワード変更
export const fetchChangePass = createAsyncThunk(
  'change_password/post',
  async (arg, thunkAPI) => {
    const { userId } = thunkAPI.getState().user;
    const { imaPass, newPass } = arg;
    const res = await client.post(`${apiUrl}change_password`, {
      user_id: userId,
      ima_pass: imaPass,
      new_pass: newPass
    });
    return res.data;
  }
);

//パスワード再発行
export const fetchReregisterPass = createAsyncThunk(
  'reregister_password/post',
  async (arg, thunkAPI) => {
    const { userId } = arg;
    const res = await client.post(`${apiUrl}reregister_password`, {
      user_id: userId
    });
    return res.data;
  }
);

//パスワード期限切れ更新
export const fetchUpdatePass = createAsyncThunk(
  'update_password/post',
  async (arg, thunkAPI) => {
    const { passwordExpiration } = thunkAPI.getState().user;
    const { newPass } = arg;
    const res = await client.post(`${apiUrl}update_password`, {
      user_id: passwordExpiration.loginId,
      new_pass: newPass
    });
    return res.data;
  }
);

//アカウント登録
export const fetchRegister = createAsyncThunk(
  'account/post',
  async (arg, thunkAPI) => {
    const {
      lastName,
      firstName,
      userId,
      password,
      company,
      tel,
      mobileNumber,
      url,
      accountName,
      ecMall,
      ecMallOther,
      otherTools,
      background,
      backgroundOther,
      source,
      sourceOther,
      itemUrl,
      amazonSellerType,
      trialMall
      // how,
      // issues,
      // amazonPlan,
      // rakutenPlan,
      // yahooPlan
    } = arg;
    const res = await client.post(`${apiUrl}register`, {
      first_name: firstName,
      last_name: lastName,
      user_id: userId,
      password: password,
      company: company,
      tel: tel,
      mobile_number: mobileNumber,
      company_url: url,
      account_name: accountName,
      amazon_seller_type: amazonSellerType,
      item_url: itemUrl,
      ec_mall: ecMall,
      ec_mall_other: ecMallOther,
      other_tools: otherTools,
      background,
      background_other: backgroundOther,
      source,
      source_other: sourceOther,
      trial_mall: trialMall
      // how: how,
      // issues: issues,
      // amazon_plan: amazonPlan,
      // rakuten_plan: rakutenPlan,
      // yahoo_plan: yahooPlan
    });
    return res.data;
  }
);

// 新規ユーザー登録
export const userRegister = createAsyncThunk(
  'user/register',
  async (arg, thunkAPI) => {
    // console.log('arg', arg);
    const { email, firstname, lastname, password, accountId, authority } = arg;
    const { amazonStatus, rakutenStatus, yahooStatus } =
      thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}user_register`, {
      login_id: email,
      account_id: accountId,
      first_name: firstname,
      last_name: lastname,
      password: password,
      authority: authority,
      amazonStatus: amazonStatus,
      rakutenStatus: rakutenStatus,
      yahooStatus: yahooStatus
    });
    return res.data;
  }
);

//ログイン画面で入力されたuserIDとpasswordをpost
export const fetchUser = createAsyncThunk(
  'user/post',
  async (arg, thunkAPI) => {
    const { userId, password, mfaCode } = arg;
    const { spapiCode, yahooAuthCode, mailFlag } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}verify`, {
      user_id: userId,
      password: password,
      mfaCode,
      spapi_oauth_code: spapiCode,
      yahoo_auth_code: yahooAuthCode,
      from_report_mail: mailFlag
    });
    return res.data;
  }
);

//アカウント登録完了時の自動ログイン
export const registerLogin = createAsyncThunk(
  'register_user/post',
  async (arg, thunkAPI) => {
    const { userId } = arg;
    const res = await client.post(`${apiUrl}register_login`, {
      user_id: userId
    });
    return res.data;
  }
);

export const fetchPlanStatus = createAsyncThunk(
  'planStatus/post',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}get_plan_status`, {
      account_id: accountId
    });
    return res.data;
  }
);

export const fetchAccountStatus = createAsyncThunk(
  'accountStatus/post',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}account_status`, {
      account_id: accountId
    });
    return res.data;
  }
);

export const fetchUserStatus = createAsyncThunk(
  'userStatus/post',
  async (arg, thunkAPI) => {
    const { userId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}user_status`, {
      user_id: userId
    });
    return res.data;
  }
);

export const fetchLogout = createAsyncThunk(
  'logout/post',
  async (_, thunkAPI) => {
    const { userId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}logout`, {
      user_id: userId
    });
    return res.data;
  }
);

export const fetchUserList = createAsyncThunk(
  'userList/post',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}userlist`, {
      account_id: accountId
    });
    return res.data;
  }
);

export const fetchChangeAuthority = createAsyncThunk(
  'authority/post',
  async (arg, thunkAPI) => {
    const { user_id, authority } = arg;
    const res = await client.post(`${apiUrl}change_authority`, {
      login_id: user_id,
      authority: authority
    });
    return res.data;
  }
);

// stripeからのリダイレクト時
export const redirectFetchUser = createAsyncThunk(
  'redirect_user/post',
  async (arg, thunkAPI) => {
    const { login_id, account_id, session_id } = arg;
    const res = await client.post(`${apiUrl}redirect_login`, {
      login_id: login_id,
      account_id: account_id,
      session_id: session_id
    });

    return res.data;
  }
);

// Freeプランへの変更
export const changeFreePlan = createAsyncThunk(
  'free_plan/post',
  async (arg, thunkApi) => {
    const login_id = thunkApi.getState().user.userId;
    const account_id = thunkApi.getState().user.accountId;
    try {
      const res = await client.post(`${apiUrl}change_free_plan`, {
        login_id: login_id,
        account_id: account_id,
        plan_id: 0
      });
      return res.data;
    } catch (error) {
      console.error('change free_plan error', error);
    }
  }
);

// マスターユーザー用アカウント一覧取得
export const fetchAccountList = createAsyncThunk(
  'acccount_list/post',
  async (arg, thunkApi) => {
    const { searchWord } = arg;
    const res = await client.post(`${apiUrl}get_account_list`, {
      search_word: searchWord
    });
    return res.data;
  }
);

// マスターユーザー用ログイン
export const fetchMasterUser = createAsyncThunk(
  'master_user/post',
  async (arg, thunkApi) => {
    const { account, userId, password } = arg;
    const account_id = account.id;
    const res = await client.post(`${apiUrl}master_login`, {
      account_id,
      user_id: userId,
      password
    });
    return res.data;
  }
);

// 自動審査
export const autoJudging = createAsyncThunk(
  'auto_judging/post',
  async (arg, thunkApi) => {
    const { userId, company } = arg;
    const res = await client.post(`${apiUrl}auto_judging`, {
      userId: userId,
      company: company
    });
    return res.data;
  }
);

// データ接続モーダルを今後表示しない
export const connectionModalNotDisplay = createAsyncThunk(
  'connection_modal/display',
  async (arg, thunkApi) => {
    const account_id = thunkApi.getState().user.accountId;
    const res = await client.post(`${apiUrl}connection_modal_display`, {
      accountId: account_id
    });
    return res.data;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLoggedIn: false,
    isFirstLogin: false, // 初回ログインかどうかのフラグ
    freePlanPreselectedItems: '',
    currentAccount: {
      type: 'main',
      id: '',
      name: '',
      rLoginId: '',
      rLoginPass: ''
    },
    accountId: '',
    accountName: '',
    accountStatus: '',
    amazonStatus: '',
    rakutenStatus: '',
    yahooStatus: '',
    userName: '',
    first_name: '',
    last_name: '',
    userId: '',
    saId: '',
    dspId: '',
    serviceSecret: '',
    licenseKey: '',
    rLoginId: '',
    rLoginPass: '',
    rCsvStatus: '',
    spapiCode: '',
    yahooAuthCode: '',
    modalStatus: true,
    mailFlag: false,
    passwordExpiration: { status: false, currentPW: '', loginId: '' },
    authority: '',
    createdAt: '',
    userList: [],
    familyList: [],
    connectionModalDisplay: 0,
    trial_mall: '',
    plan: {
      amazon: { status: 0, plan: 0, premium: false },
      rakuten: { status: 0, plan: 0, premium: false },
      yahoo: { status: 0, plan: 0, premium: false }
    },
    inputRMS: {
      inputRLoginId: '',
      inputRLoginPass: '',
      inputName: '',
      inputId: '',
      isParent: true
    },
    trial: {
      Amazon: { trial: false },
      楽天: { trial: false },
      yahoo: { trial: false },
      advertise: false,
      trial_notice: false
    },
    accountList: [],
    loading: false
  },

  reducers: {
    modalClose(state, action) {
      state.modalStatus = action.payload;
    },
    logout(state, action) {
      return {
        ...state,
        isLoggedIn: false,
        accountId: '',
        accountName: '',
        accountStatus: '',
        amazonStatus: '',
        rakutenStatus: '',
        yahooStatus: '',
        userName: '',
        first_name: '',
        last_name: '',
        userId: '',
        userStatus: '',
        saId: '',
        dspId: '',
        userList: [],
        serviceSecret: '',
        licenseKey: '',
        rLoginId: '',
        rLoginPass: '',
        rCsvStatus: '',
        spapiCode: '',
        yahooAuthCode: '',
        modalStatus: true,
        createdAt: '',
        authority: '',
        freePlanPreselectedItems: ''
      };
    },
    setSpAPIAuth(state, action) {
      state.spapiCode = action.payload;
    },
    setYahooAPIAuth(state, action) {
      state.yahooAuthCode = action.payload;
    },
    setMailFlag(state, action) {
      state.mailFlag = action.payload;
    },
    setPasswordExpired(state, action) {
      state.passwordExpiration.status = action.payload.status;
      state.passwordExpiration.currentPW = action.payload.currentPW;
      state.passwordExpiration.loginId = action.payload.loginId;
    },
    changeCurrentAccount(state, action) {
      state.currentAccount = action.payload;
    },
    setInputRmsInfo(state, action) {
      state.inputRMS.inputRLoginId = action.payload.rLoginId;
      state.inputRMS.inputRLoginPass = action.payload.rLoginPass;
      state.inputRMS.inputName = action.payload.name;
      state.inputRMS.inputId = action.payload.id;
      state.inputRMS.isParent = action.payload.parent_flag;
    },
    changeConnectionModalDisplay(state, action) {
      state.connectionModalDisplay = 0;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRegister.fulfilled, (state, action) => {
      console.log(action.payload);
    });
    builder.addCase(fetchRegister.rejected, (state) => {
      console.log('reject account');
    });
    builder.addCase(registerLogin.fulfilled, (state, action) => {
      return {
        ...state,
        isLoggedIn: true,
        isFirstLogin: true, // アカウント開設後のリダイレクトの場合は初回ログインをtrueにする
        currentAccount: {
          type: 'main',
          id: action.payload.accountId,
          name: action.payload.accountName,
          rLoginId: action.payload.rLoginPass,
          rLoginPass: action.payload.rLoginPass
        },
        accountId: action.payload.accountId,
        accountName: action.payload.accountName,
        accountStatus: action.payload.accountStatus,
        userName: action.payload.userName,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        userId: action.payload.userId,
        userStatus: action.payload.userStatus,
        amazonStatus: action.payload.amazonStatus,
        rakutenStatus: action.payload.rakutenStatus,
        yahooStatus: action.payload.yahooStatus,
        saId: action.payload.saId,
        dspId: action.payload.dspId,
        userList: action.payload.userList,
        serviceSecret: action.payload.serviceSecret,
        licenseKey: action.payload.licenseKey,
        rLoginId: action.payload.rLoginId,
        rLoginPass: action.payload.rLoginPass,
        rCsvStatus: action.payload.rCsvStatus,
        authority: action.payload.authority,
        createdAt: action.payload.createdAt,
        plan: action.payload.plan,
        payment: action.payload.payment,
        trial: action.payload.trial,
        familyList: action.payload.family,
        trial_mall: action.payload.trialMall,
        connectionModalDisplay: action.payload.connectionModalDisplay,
        freePlanPreselectedItems: action.payload.freePlanPreselectedItems
      };
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      return {
        ...state,
        isLoggedIn: true,
        accountId: action.payload.accountId,
        accountName: action.payload.accountName,
        accountStatus: action.payload.accountStatus,
        userName: action.payload.userName,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        userId: action.payload.userId,
        userStatus: action.payload.userStatus,
        amazonStatus: action.payload.amazonStatus,
        rakutenStatus: action.payload.rakutenStatus,
        yahooStatus: action.payload.yahooStatus,
        saId: action.payload.saId,
        dspId: action.payload.dspId,
        userList: action.payload.userList,
        serviceSecret: action.payload.serviceSecret,
        licenseKey: action.payload.licenseKey,
        rLoginId: action.payload.rLoginId,
        rLoginPass: action.payload.rLoginPass,
        rCsvStatus: action.payload.rCsvStatus,
        authority: action.payload.authority,
        createdAt: action.payload.createdAt,
        plan: action.payload.plan,
        payment: action.payload.payment,
        trial: action.payload.trial,
        familyList: action.payload.family,
        trial_mall: action.payload.trialMall,
        connectionModalDisplay: action.payload.connectionModalDisplay,
        freePlanPreselectedItems: action.payload.freePlanPreselectedItems
      };
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      return action.payload;
    });
    builder.addCase(fetchMasterUser.fulfilled, (state, action) => {
      return {
        ...state,
        isLoggedIn: true,
        currentAccount: {
          type: 'main',
          id: action.payload.accountId,
          name: action.payload.accountName,
          rLoginId: action.payload.rLoginPass,
          rLoginPass: action.payload.rLoginPass
        },
        accountId: action.payload.accountId,
        accountName: action.payload.accountName,
        accountStatus: action.payload.accountStatus,
        userName: action.payload.userName,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        userId: action.payload.userId,
        userStatus: action.payload.userStatus,
        amazonStatus: action.payload.amazonStatus,
        rakutenStatus: action.payload.rakutenStatus,
        yahooStatus: action.payload.yahooStatus,
        saId: action.payload.saId,
        dspId: action.payload.dspId,
        userList: action.payload.userList,
        serviceSecret: action.payload.serviceSecret,
        licenseKey: action.payload.licenseKey,
        rLoginId: action.payload.rLoginId,
        rLoginPass: action.payload.rLoginPass,
        rCsvStatus: action.payload.rCsvStatus,
        authority: action.payload.authority,
        createdAt: action.payload.createdAt,
        plan: action.payload.plan,
        payment: action.payload.payment,
        trial: action.payload.trial,
        familyList: action.payload.family,
        trial_mall: action.payload.trialMall,
        connectionModalDisplay: action.payload.connectionModalDisplay
      };
    });
    builder.addCase(fetchMasterUser.rejected, (state, action) => {
      return action.payload;
    });

    builder.addCase(fetchAccountStatus.fulfilled, (state, action) => {
      return {
        ...state,
        accountStatus: action.payload.status,
        isFirstLogin: false
      };
    });
    builder.addCase(fetchUserStatus.fulfilled, (state, action) => {
      return {
        ...state,
        amazonStatus: action.payload.amazonStatus,
        rakutenStatus: action.payload.rakutenStatus,
        yahooStatus: action.payload.yahooStatus
      };
    });
    builder.addCase(fetchUserStatus.rejected, (state, action) => {
      return action.payload;
    });
    builder.addCase(fetchLogout.fulfilled, (state, action) => {
      return {
        ...state,
        isLoggedIn: false,
        accountId: '',
        accountName: '',
        accountStatus: '',
        amazonStatus: '',
        rakutenStatus: '',
        yahooStatus: '',
        userName: '',
        first_name: '',
        last_name: '',
        userId: '',
        userStatus: '',
        saId: '',
        dspId: '',
        userList: [],
        serviceSecret: '',
        licenseKey: '',
        rLoginId: '',
        rLoginPass: '',
        rCsvStatus: '',
        spapiCode: '',
        yahooAuthCode: '',
        authority: '',
        createdAt: '',
        payment: '',
        plan: {
          amazon: { status: 0, plan: 0, premium: false },
          rakuten: { status: 0, plan: 0, premium: false },
          yahoo: { status: 0, plan: 0, premium: false }
        },
        trial_mall: '',
        connectionModalDisplay: '',
        accountList: [],
        loading: false,
        freePlanPreselectedItems: ''
      };
    });
    builder.addCase(fetchLogout.rejected, (state, action) => {
      console.log(action.payload);
    });
    builder.addCase(fetchReregisterPass.fulfilled, (state, action) => {
      console.log(action.payload);
    });
    builder.addCase(fetchReregisterPass.rejected, (state) => {
      console.log('このmailアドレスは登録されていません');
    });
    builder.addCase(fetchUpdatePass.fulfilled, (state) => {
      state.passwordExpiration.status = false;
      state.passwordExpiration.currentPW = '';
      state.passwordExpiration.loginId = '';
    });
    builder.addCase(fetchChangeProfile.fulfilled, (state, action) => {
      state.accountName = action.payload.accountName;
      state.userName = action.payload.userName;
      state.userList = action.payload.userList;
      return state;
    });
    builder.addCase(fetchChangeProfile.rejected, (state) => {
      console.log('変更失敗');
    });
    builder.addCase(fetchChangeAdvertiseId.fulfilled, (state, action) => {
      state.saId = action.payload.saId;
      state.dspId = action.payload.dspId;
      return state;
    });
    builder.addCase(fetchChangeAdvertiseId.rejected, (state) => {
      console.log('変更失敗');
    });
    builder.addCase(fetchChangeRmsKey.fulfilled, (state, action) => {
      state.serviceSecret = action.payload.serviceSecret;
      state.licenseKey = action.payload.licenseKey;
      state.rLoginId = action.payload.rLoginId;
      state.rLoginPass = action.payload.rLoginPass;
      state.rCsvStatus = action.payload.rCsvStatus;
      return state;
    });
    builder.addCase(fetchChangeRmsKey.rejected, (state) => {
      console.log('変更失敗');
    });
    builder.addCase(userRegister.fulfilled, (state, action) => {
      return state;
    });
    builder.addCase(fetchUserList.fulfilled, (state, action) => {
      state.userList = action.payload.userList;
      return state;
    });
    builder.addCase(fetchChangeAuthority.fulfilled, (state, action) => {
      state.userList = action.payload.userList;
      return state;
    });
    builder.addCase(redirectFetchUser.fulfilled, (state, action) => {
      return {
        ...state,
        isLoggedIn: true,
        accountId: action.payload.accountId,
        accountName: action.payload.accountName,
        accountStatus: action.payload.accountStatus,
        userName: action.payload.userName,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        userId: action.payload.userId,
        userStatus: action.payload.userStatus,
        amazonStatus: action.payload.amazonStatus,
        rakutenStatus: action.payload.rakutenStatus,
        yahooStatus: action.payload.yahooStatus,
        saId: action.payload.saId,
        dspId: action.payload.dspId,
        userList: action.payload.userList,
        serviceSecret: action.payload.serviceSecret,
        licenseKey: action.payload.licenseKey,
        rLoginId: action.payload.rLoginId,
        rLoginPass: action.payload.rLoginPass,
        rCsvStatus: action.payload.rCsvStatus,
        authority: action.payload.authority,
        createdAt: action.payload.createdAt,
        plan: action.payload.plan,
        payment: action.payload.payment,
        trial_mall: action.payload.trialMall,
        connectionModalDisplay: action.payload.connectionModalDisplay
      };
    });
    builder.addCase(changeFreePlan.fulfilled, (state, action) => {
      state.plan = 0;
    });
    builder.addCase(connectionModalNotDisplay.fulfilled, (state, action) => {
      state.connectionModalDisplay = action.payload.display;
    });
    builder.addCase(fetchPlanStatus.fulfilled, (state, action) => {
      return {
        ...state,
        plan: action.payload.plan,
        trial: action.payload.trial
      };
    });
    builder.addCase(fetchAccountList.fulfilled, (state, action) => {
      return {
        ...state,
        accountList: action.payload.data,
        loading: false
      };
    });
    builder.addCase(fetchAccountList.pending, (state, action) => {
      return {
        ...state,
        loading: true
      };
    });
    builder.addCase(fetchAccountList.rejected, (state, action) => {
      return {
        ...state,
        accountList: [],
        loading: false
      };
    });
  }
});

export const {
  setSpAPIAuth,
  setYahooAPIAuth,
  logout,
  modalClose,
  setMailFlag,
  setPasswordExpired,
  changeCurrentAccount,
  setInputRmsInfo,
  changeConnectionModalDisplay
} = userSlice.actions;
export const userinfo = (state) => state.user;
export const userLoggedIn = (state) => state.user.isLoggedIn;
export const userFirstLogin = (state) => state.user.isFirstLogin;
export const modalOpenStatus = (state) => state.user.modalStatus;
export const currentAccount = (state) => state.user.currentAccount;
export const connectionModalStatus = (state) =>
  state.user.connectionModalDisplay;
export const accountList = (state) => state.user.accountList;
export const userLoading = (state) => state.user.loading;

export default userSlice.reducer;
