/* eslint-disable*/

import { Container, Grid, Typography, Box } from '@material-ui/core'; // eslint-disable-line
import { useSelector } from 'react-redux';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  selectAmazonBrandShareGraph,
  selectRakutenBrandShareGraph,
  selectYahooBrandShareGraph
} from 'feactures/category/brandShareSlice';
import BrandSharegraph from 'components/molecules/dashboard/BrandSharegraph';

const BrandShare = () => {
  // 実際のデータはuseEffectで取得しreduxへ
  const BrandShareAmazonGraphData = useSelector(selectAmazonBrandShareGraph);
  const BrandShareRakutenGraphData = useSelector(selectRakutenBrandShareGraph);
  const BrandShareYahooGraphData = useSelector(selectYahooBrandShareGraph);
  const channel = useSelector(currentchannel);

  let BrandShareGraphData = {
    sales: [],
    percentage: [],
    label: []
  };
  let title = '';

  if (channel == 1) {
    BrandShareGraphData = BrandShareAmazonGraphData.graphdata;
    title = 'ブランド';
  } else if (channel == 2) {
    BrandShareGraphData = BrandShareRakutenGraphData.graphdata;
    title = '店舗';
  } else if (channel == 3) {
    BrandShareGraphData = BrandShareYahooGraphData.graphdata;
    title = 'ブランド';
  }

  const sales = BrandShareGraphData.sales;
  const count = sales.filter((item) => item >= 1).length;
  const hasOneOrMore = count >= 1;

  return (
    <>
      <Container maxWidth={false}>
        <Grid container rowSpacing={0.3} columnSpacing={1.5} height={364.9}>
          <>
            <Grid itemxs={12} mb={1}>
              <Typography fontSize="14px" fontWeight="bold">
                {title}別売上シェア
              </Typography>
              <Typography fontSize="12px" color={'gray'}>
                主要{title}の売上シェア（％）
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {hasOneOrMore ? (
                <>
                  {/* 円グラフ */}
                  <BrandSharegraph graphdata={BrandShareGraphData} />
                </>
              ) : (
                <Box display="flex" justifyContent="center">
                  <Typography fontSize={15}>データがありません</Typography>
                </Box>
              )}
            </Grid>
          </>
        </Grid>
      </Container>
    </>
  );
};

export default BrandShare;
