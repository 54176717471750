/* eslint-disable */
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Link,
  Typography
} from '@material-ui/core';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { styled } from '@mui/material/styles';
import { currentchannel } from 'feactures/channel/channelSlice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff'
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#f0f0f0'
  },
  borderBottom: '1px solid gray'
}));

const RisingTop5List = (props) => {
  const { top5items, yokoziku } = props;

  const combined = top5items.map((top5item, i) => ({
    rank: i + 1,
    ...top5item
  }));

  const channel = useSelector(currentchannel);
  const url = 'https://m.media-amazon.com/images/I/';

  const tableStyles = makeStyles({
    tableHead: {
      height: 44
    }
  });

  const classes = tableStyles();

  return (
    <>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead sx={{ maxHeight: '38px' }}>
            <StyledTableRow>
              <StyledTableCell
                align="left"
                sx={{
                  minWidth: '65px'
                }}
              >
                順位
              </StyledTableCell>
              <StyledTableCell sx={{ width: '50px' }}></StyledTableCell>
              <StyledTableCell align="left">商品名</StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  minWidth: '120px'
                }}
              >
                価格
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  minWidth: '120px'
                }}
              >
                販売個数
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ padding: 0 }}>
                売上
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  minWidth: '160px',
                  textAlign: 'center'
                }}
              >
                先月比
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {combined.length !== 0 ? (
              <>
                {combined.map((row, index) => {
                  const shopCode =
                    channel == 2 ? row.item_id.split(':')[0] : '';
                  return (
                    <StyledTableRow key={index} className={classes.tableHead}>
                      <StyledTableCell align="left">
                        {row.rank}位
                      </StyledTableCell>
                      <StyledTableCell align="left" maxwidth={40}>
                        {row.item_id == '-:-' ? (
                          <Box>-</Box>
                        ) : (
                          <Link
                            href={
                              channel === 1
                                ? `https://www.amazon.co.jp/dp/${row.item_id}`
                                : `https://item.rakuten.co.jp/${shopCode}/${
                                    row.item_id.split(':')[1]
                                  }/`
                            }
                            target="_blank"
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <Box
                                sx={{
                                  height: 50,
                                  width: 50,
                                  display: 'inline-block'
                                }}
                              >
                                <img
                                  src={channel == 1 ? url + row.img : row.img}
                                  height="100%"
                                  loading="lazy"
                                  width="100%"
                                />
                              </Box>
                            </Box>
                          </Link>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.item_id == '-:-' ? (
                          <Box>データが無いため表示できません</Box>
                        ) : (
                          <Link
                            underline="hover"
                            href={
                              channel === 1
                                ? `https://www.amazon.co.jp/dp/${row.item_id}`
                                : `https://item.rakuten.co.jp/${shopCode}/${
                                    row.item_id.split(':')[1]
                                  }/`
                            }
                            target="_blank"
                            rel="noopener"
                          >
                            <Box
                              sx={{
                                maxHeight: 50,
                                display: 'flex',
                                alignItems: 'start',
                                justifyContent: 'left'
                              }}
                              component="div"
                              my={1}
                              textOverflow="ellipsis"
                              overflow="hidden"
                            >
                              {row.name}
                            </Box>
                          </Link>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.price == 0
                          ? '-'
                          : '¥' + row.price.toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.unit_sold == 0
                          ? '-'
                          : row.unit_sold.toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.sales == 0
                          ? '-'
                          : '¥' + row.sales.toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {row.pre_sales == 0 ? (
                              <Box>先月のデータが無いため表示できません</Box>
                            ) : (
                              <>
                                {row.rate > 0 ? (
                                  <ArrowUpwardIcon
                                    fontSize="small"
                                    sx={{ color: '#37C967' }}
                                  />
                                ) : row.rate < 0 ? (
                                  <ArrowDownwardIcon
                                    fontSize="small"
                                    sx={{ color: '#c93c37' }}
                                  />
                                ) : (
                                  <></>
                                )}
                                <Typography>
                                  {Math.round(row.rate)}％
                                </Typography>
                              </>
                            )}
                          </Box>
                          <Typography>
                            （ ¥{row.sales_deference.toLocaleString()} ）
                          </Typography>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </>
            ) : (
              <StyledTableRow sx={{ borderBottom: 'none' }}>
                <StyledTableCell
                  colSpan={7}
                  align="center"
                  sx={{ borderBottom: 'none', pt: 4 }}
                >
                  データがありません
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RisingTop5List;
