/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const channelSlice = createSlice({
  name: 'channel',
  initialState: {
    channel: 1,
    channelName: 'amazon',
    benchmark: '1' //1:期間比較
  },
  reducers: {
    registerAllMall: (state) => {
      state.channel = 0;
      state.channelName = 'allMall';
    },
    registerAmazon: (state) => {
      state.channel = 1;
      state.channelName = 'amazon';
    },
    registerRakuten: (state) => {
      state.channel = 2;
      state.channelName = 'rakuten';
    },
    registerYahoo: (state) => {
      state.channel = 3;
      state.channelName = 'yahoo';
    },
    changeChannel: (state, action) => {
      state.channel = action.payload.id;
      state.channelName = action.payload.name;
    },
    switchBenchmark: (state, action) => {
      // console.log('チェンジ比較', action.payload);
      state.benchmark = action.payload;
    },
    resetBenchmark0: (state, action) => {
      // console.log('0に', action.payload);
      state.benchmark = '0';
    }
  }
});

export const {
  registerAllMall,
  registerAmazon,
  registerRakuten,
  registerYahoo,
  switchBenchmark,
  resetBenchmark0,
  changeChannel
} = channelSlice.actions;

export const currentchannel = (state) => state.currentchannel.channel;
export const currentchannelName = (state) => state.currentchannel.channelName;
export const benchmark = (state) => state.currentchannel.benchmark;

export default channelSlice.reducer;
