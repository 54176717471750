/* eslint-disable */
import { useEffect, useState, useRef } from 'react';
import { Button, CssBaseline, ButtonGroup } from '@material-ui/core';
import {
  registerAmazon,
  registerRakuten,
  registerYahoo,
  currentchannel
} from 'feactures/channel/channelSlice';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMonitorPost } from 'feactures/monitoring/monitoringSlice';
import { fetchProcessKPIPost } from 'feactures/monitoring/processKPISlice';
import { fetchViewsPost } from 'feactures/monitoring/viewsSlice';
import { fetchCVRPost } from 'feactures/monitoring/CVRSlice';
import { fetchUnitPricePost } from 'feactures/monitoring/unitPriceSlice';
import { fetchAsyncMyproducts } from 'feactures/product/myproductSlice';
import { fetchAsyncOtherproducts } from 'feactures/product/otherproductSlice';
import { resetCheckGroup } from 'feactures/monitoring/groupSlice';
import {
  fetchAsinList,
  fetchAsyncMonitorAsin,
  setDisplayObject
} from 'feactures/monitoring/monitorAsinSlice';
// import { fetchCategoryShareGraphData } from 'feactures/category/categoryShareSlice';
import {
  fetchBrandShareGraphData,
  fetchBrandShareListData
} from 'feactures/category/brandShareSlice';
import { fetchCategoryGraphData } from 'feactures/category/categoryGraphSlice';
import {
  fetchCategoryRankData,
  fetchCategoryRisingData
} from 'feactures/category/categoryRankSlice';
import {
  fetchCategoryBrandShareGraphData,
  fetchCategoryOwnBrandShareGraphData
} from 'feactures/brand/categoryBrandShareSlice';
import { fetchCategoryBrandGraphData } from 'feactures/brand/categoryBrandGraphSlice';
import { fetchBrandRankData } from 'feactures/brand/brandRankSlice';
import {
  fetchOtherSales,
  fetchOwnSales,
  getOtherRegisterMeasuresProductSales,
  getOwnRegisterMeasuresProductSales,
  setDetailClose
} from 'feactures/measure/measureSlice';

const NewButtongroup = (props) => {
  const { amazonOnly, page } = props; //eslint-disable-line
  const [amazon, setAmazon] = useState(false);
  const [rakuten, setRakuten] = useState(false);
  const [yahoo, setYahoo] = useState(false);
  const [color1, setColor1] = useState('groupNoselected');
  const [color2, setColor2] = useState('groupNoselected');
  const [color3, setColor3] = useState('groupNoselected');
  const channel = useSelector(currentchannel);
  const dispatch = useDispatch();

  const dispatchTable = {
    // monitoring: [fetchMonitorPost()],
    sales: [fetchAsinList(), fetchAsyncMonitorAsin({ pageNum: 1 })],
    views: [fetchProcessKPIPost(), fetchViewsPost()],
    cvr: [fetchProcessKPIPost(), fetchCVRPost()],
    customerUnitPrice: [fetchProcessKPIPost(), fetchUnitPricePost()],
    categoryTop100: [],
    category: [
      // fetchCategoryShareGraphData(),
      fetchCategoryGraphData(),
      fetchCategoryRankData(),
      fetchCategoryRisingData(),
      fetchBrandShareGraphData(),
      fetchBrandShareListData()
    ],
    brandAnalysis: [
      fetchCategoryBrandShareGraphData(),
      fetchCategoryBrandGraphData(),
      fetchBrandRankData()
    ],
    brandComparison: [
      fetchOwnSales(),
      fetchCategoryOwnBrandShareGraphData(),
      fetchCategoryBrandGraphData(),
      fetchCategoryBrandShareGraphData()
    ],
    measureAnalysis: [fetchOwnSales(), fetchCategoryBrandGraphData()],
    categoryNo1: [],
    ownProduct: [fetchAsyncMyproducts()],
    otherProduct: [fetchAsyncOtherproducts()],
    productMeasures: [
      setDetailClose(),
      getOwnRegisterMeasuresProductSales(),
      getOtherRegisterMeasuresProductSales()
    ],
    categoryNo1: []
  };

  const [searchByUserFirstNamePromise, setSearchByUserFirstNamePromise] =
    useState();

  //1つ前のリクエストをキャンセルしている
  const dispatchHandle = (currentPage) => {
    //dispatchTableの数だけキャンセル処理を回す
    // console.log(searchByUserFirstNamePromise);
    if (searchByUserFirstNamePromise) {
      // searchByUserFirstNamePromise.map((r, index) => {
      // })
      // console.log('キャンセルID', searchByUserFirstNamePromise.requestId);
      if (
        searchByUserFirstNamePromise &&
        typeof searchByUserFirstNamePromise.abort === 'function'
      ) {
        searchByUserFirstNamePromise.abort();
      }
      // searchByUserFirstNamePromise?.abort();
    }
    if (dispatchTable[currentPage].length != 0) {
      //dispatchTableのreducerの数だけdispatchさせる
      [...dispatchTable[currentPage]].map((r, index) => {
        const promise = dispatch(r);
        setSearchByUserFirstNamePromise(promise);
        // console.log('新しいリクエスト', promise.requestId);
      });
    }
  };

  const changeHandle = (e) => {
    // console.log('changeハンドル発動');
    // console.log(e.currentTarget);
    if (e == 1) {
      changeAmazon();
      dispatch(registerAmazon());
    } else if (e == 2) {
      changeRakuten();
      dispatch(registerRakuten());
    } else if (e == 3) {
      changeYahoo();
      dispatch(registerYahoo());
    }
  };

  // チャンネルが変わったら発動 初回レンダリングさせない
  const useEffectCustom = (func, dependencyList) => {
    const fisrtFlgRef = useRef(true);

    useEffect(() => {
      changeHandle(channel);

      if (!fisrtFlgRef.current) {
        func();
      } else {
        fisrtFlgRef.current = false;
      }
    }, dependencyList);
  };

  useEffectCustom(() => {
    if (channel == 0) {
      dispatch(resetCheckGroup());
    }
    if (page) {
      // console.log('変わったのでfetch！ページ', page);
      dispatch(fetchAsinList());
      dispatch(setDisplayObject('top10'));
      window.scrollTo(0, 0);
      dispatchHandle(page);
    }
    // changeHandle(channel);
  }, [channel]);

  const changeAmazon = () => {
    if (amazon) {
      return;
    } else {
      setAmazon(true);
      setRakuten(false);
      setYahoo(false);
      setColor1('groupSelected');
      setColor2('groupNoselected');
      setColor3('groupNoselected');
    }
  };
  const changeRakuten = () => {
    if (rakuten) {
      return;
    } else {
      setAmazon(false);
      setRakuten(true);
      setYahoo(false);
      setColor1('groupNoselected');
      setColor2('groupSelected');
      setColor3('groupNoselected');
    }
  };
  const changeYahoo = () => {
    if (yahoo) {
      return;
    } else {
      setAmazon(false);
      setRakuten(false);
      setYahoo(true);
      setColor1('groupNoselected');
      setColor2('groupNoselected');
      setColor3('groupSelected');
    }
  };

  if (page != 'monitoring' && channel == 0) {
    // console.log('pageがmonitoring以外でチャンネルが0です');
    // changeAmazon();
    dispatch(registerAmazon());
    dispatch(fetchAsinList());
  }

  if (page == 'monitoring' && channel == 3) {
    // console.log('pageがview以外でチャンネルが３です');
    // changeAmazon();
    dispatch(registerAmazon());
    dispatch(fetchAsinList());
    dispatch(setDisplayObject('top10'));
  }

  return (
    <>
      <CssBaseline />
      <ButtonGroup color="inherit" sx={{ borderColor: '#BDBDBD' }}>
        <Button
          onClick={() => {
            changeAmazon(), dispatch(registerAmazon());
          }}
          variant="contained"
          color={color1}
          sx={{
            width: '80px',
            height: '30px',
            borderRadius: 1.5,
            border: 1,
            fontWeight: 'bold',
            borderColor: '#BDBDBD',
            boxShadow: 0
          }}
          value={1}
          // disabled={channel !== 1 && !page}
        >
          Amazon
        </Button>
        <Button
          onClick={() => {
            changeRakuten(), dispatch(registerRakuten());
          }}
          value={2}
          variant="contained"
          color={color2}
          sx={{
            width: '80px',
            height: '30px',
            borderRadius: 1.5,
            border: 1,
            fontWeight: 'bold',
            borderColor: '#BDBDBD',
            boxShadow: 0
          }}
          // disabled={channel !== 2 && !page}
        >
          楽天
        </Button>
        <Button
          disabled={true}
          onClick={() => {
            changeYahoo(), dispatch(registerYahoo());
          }}
          value={3}
          variant="contained"
          color={color3}
          sx={{
            width: '80px',
            height: '30px',
            borderRadius: 1.5,
            border: 1,
            fontWeight: 'bold',
            borderColor: '#BDBDBD',
            boxShadow: 0
          }}
        >
          ヤフー
        </Button>
      </ButtonGroup>
    </>
  );
};
export default NewButtongroup;
