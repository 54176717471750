/* eslint-disable*/
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  performancereviewAmazonOwnReviewResult,
  performancereviewRakutenOwnReviewResult,
  performancereviewOwnDataLoading,
  performancereviewAmazonOwnScore,
  performancereviewRakutenOwnScore,
  performanceModalOpen
} from 'feactures/performancereview/performancereviewSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import BasicResultDialog from './BasicResultDialog';
import SeoResultDialog from './SeoResultDialog';
import PriceResultDialog from './PriceResultDialog';
import DetailResultDialog from './DetailResultDialog';
import BasicResultMeasuresDialog from './BasicResultMeasuresDialog';

function Row(props) {
  const { data, index } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{ '& > *': { borderBottom: 0 }, cursor: 'pointer' }}
        onClick={() => setOpen(!open)}
      >
        <TableCell component="th" scope="row" sx={{ border: 0 }}>
          <Typography
            fontWeight="bold"
            color="rgba(113,140,198,1)"
            fontSize="13px"
          >
            {data.name}
          </Typography>
        </TableCell>
        <TableCell align="right" sx={{ border: 0 }} width="25px">
          <img
            alt="score"
            src={`/static/images/performanceReview/rank_${data.score}.png`}
            style={{
              display: 'inline-block',
              width: '25px'
            }}
          />
        </TableCell>
        <TableCell align="right" sx={{ border: 0 }} width="25px">
          {data.name == '2.検索' ? (
            <SeoResultDialog index={index} />
          ) : data.name == '2.価格' || data.name == '3.価格' ? (
            <PriceResultDialog index={index} />
          ) : data.name == '3.商品ページ' || data.name == '4.商品ページ' ? (
            <DetailResultDialog index={index} />
          ) : data.name == '1.集客施策' || data.name == '6.ストア' ? (
            <BasicResultMeasuresDialog index={index} />
          ) : (
            <BasicResultDialog index={index} />
          )}
        </TableCell>
        <TableCell align="center" sx={{ border: 0, pl: 0 }} width="30px">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {/*開閉する部分 */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {data.details.map((detail, index) => {
              return (
                <Box
                  sx={{ margin: 1 }}
                  p={1}
                  bgcolor="#FAFBFC"
                  borderRadius={1}
                  key={index}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography fontWeight="bold" fontSize="13px">
                      {detail.name}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      {detail.evaluationFlag == 1 ? (
                        <CheckCircleOutlineIcon
                          sx={{ color: '#00C367', width: '18px' }}
                        />
                      ) : (
                        <HighlightOffIcon
                          sx={{ color: 'red', width: '18px' }}
                        />
                      )}
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography color="text.secondary" minWidth="36px">
                      理由：
                    </Typography>
                    <Typography color="text.secondary">
                      {detail.reason}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CollapsibleTable(props) {
  const { dataList } = props;
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
      <Table aria-label="collapsible table">
        <TableBody>
          {dataList.map((data, index) => (
            <Row key={index} data={data} index={index} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const ReviewResult = () => {
  const isLoading = useSelector(performancereviewOwnDataLoading);
  const amazonDataList = useSelector(performancereviewAmazonOwnReviewResult);
  const rakutenDataList = useSelector(performancereviewRakutenOwnReviewResult);
  const amazonScore = useSelector(performancereviewAmazonOwnScore);
  const rakutenScore = useSelector(performancereviewRakutenOwnScore);
  const channel = useSelector(currentchannel);
  const modalOpen = useSelector(performanceModalOpen);
  let dataList = [];
  let score;
  if (channel == 1) {
    dataList = amazonDataList;
    score = amazonScore;
  } else if (channel == 2) {
    dataList = rakutenDataList;
    score = rakutenScore;
  }
  return (
    <Box
      width="49%"
      bgcolor="#FFFFFF"
      borderRadius={3}
      p={2}
      flexShrink={0}
      minHeight="200px"
    >
      <Typography
        fontWeight={'bold'}
        fontSize={'18px'}
        color="rgba(113,140,198,1)"
        pl={1}
      >
        ■診断結果
      </Typography>
      {isLoading && !modalOpen.open ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
        >
          <CircularProgress />
        </Box>
      ) : score == 'nodata' ? (
        <Box
          height="200px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>新規登録時はデータは翌日反映されます</Typography>
        </Box>
      ) : dataList.length == 0 ? (
        <Box
          height="200px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>商品を選択してください</Typography>
        </Box>
      ) : (
        <CollapsibleTable dataList={dataList} />
      )}
    </Box>
  );
};

export default ReviewResult;
