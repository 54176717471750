/* eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import theme from 'theme';
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Grid,
  Typography
} from '@mui/material';
import NewDialogSelect from '../select/NewDialogSelect';
import DialogCheckbox from '../select/DialogCheckbox';
import { fetchAsyncRegisterFreePlanDefaultMarketInfo } from 'feactures/market/freePlanRegisterSlice';
import { otherSelectedBrand } from 'feactures/brand/brandSlice';
import { isLoading } from 'feactures/category/categorySlice';
import {
  currentAcate1,
  currentRcate1,
  currentYcate1
} from 'feactures/category/categoryGraphSlice';
import { errorResMessage } from 'feactures/errorSlice';
import { currentchannel } from 'feactures/channel/channelSlice';

const FreePlanRegisterModal = ({
  categories,
  updateFreePlanRegisterInfosSuccessFlag
}) => {
  const dispatch = useDispatch();
  const [selectedCategoryStatus, setSelectedCategoryStatus] = useState(false);
  const [selectedBrandStatus, setSelectedBrandStatus] = useState(false);
  const [error, setError] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const channel = useSelector(currentchannel);
  const selectOtherBrand = useSelector(otherSelectedBrand);
  const currentSelectAmazonCategory1 = useSelector(currentAcate1);
  const currentSelectRakutenCategory1 = useSelector(currentRcate1);
  const currentSelectYahooCategory1 = useSelector(currentYcate1);

  const errorMessage = useSelector(errorResMessage);

  useEffect(() => {
    if (channel == 1) {
      setSelectedCategoryStatus(Boolean(currentSelectAmazonCategory1.value));
      setSelectedBrandStatus(Boolean(selectOtherBrand.amazon.brand));
    } else if (channel == 2) {
      setSelectedCategoryStatus(Boolean(currentSelectRakutenCategory1.value));
      setSelectedBrandStatus(Boolean(selectOtherBrand.rakuten.brand));
    } else {
      setSelectedCategoryStatus(Boolean(currentSelectYahooCategory1.value));
      setSelectedBrandStatus(Boolean(selectOtherBrand.yahoo.brand));
    }
  }, [
    channel,
    currentSelectAmazonCategory1,
    currentSelectRakutenCategory1,
    currentSelectYahooCategory1,
    selectOtherBrand
  ]);

  // 送信ボタンが押されたときの処理
  const handleSubmit = async () => {
    setError('');
    await dispatch(fetchAsyncRegisterFreePlanDefaultMarketInfo())
      .then(unwrapResult) //registerProductsで登録が成功（fullfilled）のときはモーダルを閉じる
      .then(() => {
        setError('');
        setShowSuccessMessage(true);
        // 登録が成功したら、2秒後にフラグをtrueに設定
        setTimeout(() => {
          updateFreePlanRegisterInfosSuccessFlag();
        }, 2000); // 2秒の遅延
      })
      .catch((err) => {
        setError('登録に失敗しました。もう一度お試しください。');
      });
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 600, margin: '0 auto', padding: 2 }}>
      <Typography sx={{ fontSize: '14px', marginBottom: 2 }}>
        ※表示したいカテゴリとブランドを登録してください
      </Typography>
      <Card sx={{ padding: 3 }}>
        <Typography sx={{ fontSize: '12px', marginBottom: 3 }}>
          無料プランをご利用いただくためには、初回に表示したいカテゴリとブランドの登録が必要です。
          <br />
          この登録は初回のみ行うことができ、その後は変更できませんのでご注意ください。
          <br />
          複数のカテゴリやブランドを選択するには、有料プランにお申し込みいただく必要があります。
        </Typography>
        <CardContent
          sx={{ backgroundColor: theme.palette.grey[200], padding: 3 }}
        >
          <Box display="flex" flexDirection="column" gap={3}>
            <Box>
              <Typography fontSize="14px" sx={{ marginBottom: 1 }}>
                カテゴリを選択
              </Typography>
              <NewDialogSelect
                categories={categories}
                channel={channel}
                page="freePlanRegisterModal"
                // graphLoading={
                // graphloading ||
                // brandShareLoading ||
                // top100loading ||
                // brandShareListLoading ||
                // top5loading
                // }
              />
            </Box>
            <Box>
              <Typography fontSize="14px" sx={{ marginBottom: 1 }}>
                {channel == 1 ? 'ブランド' : '店舗'}を選択
              </Typography>
              <DialogCheckbox
                channel={channel}
                page="freePlanRegisterModal"
                section="other"
              />
            </Box>
          </Box>
          <DialogActions sx={{ marginTop: 3 }}>
            {/* {errorMessage && (
              <Typography color="red" sx={{ mx: 3 }}>
                ※{errorMessage}
              </Typography>
            )} */}

            {error && (
              <Typography color="error" variant="body2" sx={{ marginTop: 2 }}>
                ※{error}
              </Typography>
            )}
            {/* 成功メッセージ */}
            {showSuccessMessage && (
              <>
                <Typography
                  color="success.main"
                  variant="body2"
                  sx={{ marginTop: 2 }}
                >
                  登録が完了しました！
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                  自動で遷移します...
                </Typography>
              </>
            )}
            {!showSuccessMessage && (
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                disabled={!selectedCategoryStatus || !selectedBrandStatus}
                sx={{ paddingX: 4 }}
              >
                登録
              </Button>
            )}
          </DialogActions>
        </CardContent>
      </Card>
    </Box>
  );
};

export default FreePlanRegisterModal;
