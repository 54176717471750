/* eslint-disable  */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Link,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import NewButtongroup from 'components/atoms/group/NewButtongroup';
import BreadList from 'components/atoms/list/BreadList';
import { currentchannel } from 'feactures/channel/channelSlice';
import KPIButtongroup from 'components/atoms/group/KPIButtonGroup';

const ChannelStatusDialog = ({ pageName }) => {
  const channel = useSelector(currentchannel);

  let displayedChannel = '';
  let url = '';

  if (channel == 1) {
    displayedChannel = 'Amazon';
    url =
      'https://www.oxcim.net/amazon%E3%83%87%E3%83%BC%E3%82%BF%E6%8E%A5%E7%B6%9A%E6%96%B9%E6%B3%95';
  } else if (channel == 2) {
    displayedChannel = '楽天市場';
    url =
      'https://www.oxcim.net/%E6%A5%BD%E5%A4%A9%E3%83%87%E3%83%BC%E3%82%BF%E6%8E%A5%E7%B6%9A';
  } else if (channel == 3) {
    displayedChannel = 'Yahoo!JAPAN';
    url =
      'https://www.oxcim.net/%E3%83%A4%E3%83%95%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%83%E3%83%94%E3%83%B3%E3%82%B0%E3%83%87%E3%83%BC%E3%82%BF%E6%8E%A5%E7%B6%9A';
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#FFF8E5',
          height: 'auto',
          minHeight: '100vh',
          width: '100%',
          pb: '80px'
        }}
      >
        <Container maxWidth={false} sx={{ width: 'auto', p: 0 }}>
          <Box
            position="sticky"
            top="42px"
            bgcolor="#FFF8E5"
            zIndex={3}
            height="auto"
          >
            <BreadList />
            <Box
              sx={{
                display: 'flex',
                borderTop: 1,
                borderColor: '#FFFFFF',
                alignItems: 'flex-end'
              }}
            >
              <Box mt={3}>
                {pageName === 'KPIビュー' || pageName === 'オーバービュー' ? (
                  <KPIButtongroup />
                ) : (
                  <NewButtongroup />
                )}
              </Box>
            </Box>
          </Box>
          <Box
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ p: 2 }}
          >
            <DialogTitle
              id="alert-dialog-title"
              fontSize="30px"
              textAlign="center"
            >
              {channel === 0
                ? `全体の${pageName}について`
                : `${displayedChannel}の${pageName}について`}
            </DialogTitle>

            <DialogContent sx={{ px: 10 }}>
              {channel == 0 ? (
                <DialogContentText
                  id="alert-dialog-description"
                  style={{
                    whiteSpace: 'pre-line',
                    textAlign: 'center',
                    color: '#575757',
                    fontSize: '20px'
                  }}
                >
                  KPIビューの閲覧にはAPIの権限設定が必要です。
                  <br />
                  Amazon及び楽天で権限の設定をお願いします。
                  <br />
                  <br />
                  Amazonの接続方法は
                  <Link
                    target="_blank"
                    href={
                      'https://www.oxcim.net/amazon%E3%83%87%E3%83%BC%E3%82%BF%E6%8E%A5%E7%B6%9A%E6%96%B9%E6%B3%95'
                    }
                    fontSize="20px"
                    underline="hover"
                  >
                    こちら
                  </Link>
                  から
                  <br />
                  楽天市場の接続方法は
                  <Link
                    target="_blank"
                    href={
                      'https://www.oxcim.net/%E6%A5%BD%E5%A4%A9%E3%83%87%E3%83%BC%E3%82%BF%E6%8E%A5%E7%B6%9A'
                    }
                    fontSize="20px"
                    underline="hover"
                  >
                    こちら
                  </Link>
                  から
                  <br />
                </DialogContentText>
              ) : (
                <DialogContentText
                  id="alert-dialog-description"
                  style={{
                    whiteSpace: 'pre-line',
                    color: '#575757',
                    textAlign: 'center',
                    fontSize: '20px'
                  }}
                >
                  {pageName}の閲覧にはAPIの権限設定が必要です。
                  <br />
                  接続方法は
                  <Link
                    target="_blank"
                    href={url}
                    fontSize="20px"
                    underline="hover"
                  >
                    こちら
                  </Link>
                  から
                  <br />
                </DialogContentText>
              )}
            </DialogContent>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default ChannelStatusDialog;
