/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchCategoryRankData = createAsyncThunk(
  'category/rank/post',
  async (_, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel } = thunkAPI.getState().currentchannel;
    const { userId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}category/rank`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      term: yearMonth,
      channel: channel,
      user_id: userId
    });
    return res.data;
  }
);
export const fetchCategoryRisingData = createAsyncThunk(
  'category/rising/post',
  async (_, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel } = thunkAPI.getState().currentchannel;
    const { userId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}category/rising`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      term: yearMonth,
      channel: channel,
      user_id: userId
    });
    return res.data;
  }
);
export const fetchItemDetailGraph = createAsyncThunk(
  'category/item/post',
  async (arg, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel } = thunkAPI.getState().currentchannel;
    const { userId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}category/item`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      term: yearMonth,
      channel: channel,
      user_id: userId,
      item_id: arg.asin,
      shop_code: arg.shopCode
    });
    return res.data;
  }
);

const fetchCategoryRankSlice = createSlice({
  name: 'categoryRank',
  initialState: {
    amazon_100items: [],
    rakuten_100items: [],
    yahoo_100items: [],
    amazon_top_5: [],
    rakuten_top_5: [],
    yahoo_top_5: [],
    itemDetail: {
      rank: '',
      img: '',
      shopCode: '',
      asin: '',
      item_name: '',
      price: '',
      unit_sold: '',
      sales: '',
      graphData: []
    },
    itemDetailLoading: false,
    itemDetailDisplay: false,
    isTop5Loading: false
  },
  reducers: {
    setItemDetailDisplayFlag(state, action) {
      return {
        ...state,
        itemDetailDisplay: action.payload
      };
    },
    setItemDetail(state, action) {
      return {
        ...state,
        itemDetail: {
          rank: action.payload.rank,
          img: action.payload.img,
          shopCode: action.payload.shopCode,
          asin: action.payload.asin,
          item_name: action.payload.item_name,
          price: action.payload.price,
          unit_sold: action.payload.unit_sold,
          sales: action.payload.sales,
          graphData: state.itemDetail.graphData
        }
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategoryRankData.fulfilled, (state, action) => {
      return {
        ...state,
        amazon_100items: action.payload.amazon_100items,
        rakuten_100items: action.payload.rakuten_100items,
        yahoo_100items: action.payload.yahoo_100items,
        isLoading: false
      };
    });
    builder.addCase(fetchCategoryRankData.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(fetchCategoryRankData.rejected, (state, action) => {
      return {
        ...state,
        amazon_100items: [],
        rakuten_100items: [],
        yahoo_100items: [],
        isLoading: false
      };
    });
    builder.addCase(fetchCategoryRisingData.fulfilled, (state, action) => {
      return {
        ...state,
        amazon_top_5: action.payload.amazon_top_5,
        rakuten_top_5: action.payload.rakuten_top_5,
        yahoo_top_5: action.payload.yahoo_top_5,
        isTop5Loading: false
      };
    });
    builder.addCase(fetchCategoryRisingData.pending, (state, action) => {
      return {
        ...state,
        isTop5Loading: true
      };
    });
    builder.addCase(fetchCategoryRisingData.rejected, (state, action) => {
      return {
        ...state,
        amazon_top_5: [],
        rakuten_top_5: [],
        yahoo_top_5: [],
        isTop5Loading: false
      };
    });
    builder.addCase(fetchItemDetailGraph.fulfilled, (state, action) => {
      return {
        ...state,
        itemDetail: {
          ...state.itemDetail,
          graphData: action.payload.graphdata
        },
        itemDetailLoading: false
      };
    });
    builder.addCase(fetchItemDetailGraph.pending, (state, action) => {
      return {
        ...state,
        itemDetailLoading: true
      };
    });
    builder.addCase(fetchItemDetailGraph.rejected, (state, action) => {
      return {
        ...state,
        itemDetail: {
          ...state.itemDetail,
          graphData: []
        },
        itemDetailLoading: false
      };
    });
  }
});

export const { setItemDetailDisplayFlag } = fetchCategoryRankSlice.actions;
export const { setItemDetail } = fetchCategoryRankSlice.actions;
export const amazon_100items = (state) => state.categoryrank.amazon_100items;
export const rakuten_100items = (state) => state.categoryrank.rakuten_100items;
export const yahoo_100items = (state) => state.categoryrank.yahoo_100items;
export const amazon_top_5 = (state) => state.categoryrank.amazon_top_5;
export const rakuten_top_5 = (state) => state.categoryrank.rakuten_top_5;
export const yahoo_top_5 = (state) => state.categoryrank.yahoo_top_5;
export const categoryRankStatus = (state) => state.categoryrank.isLoading;
export const categoryTop5IsLoading = (state) =>
  state.categoryrank.isTop5Loading;
export const itemDetail = (state) => state.categoryrank.itemDetail;
export const itemDetailLoading = (state) =>
  state.categoryrank.itemDetailLoading;
export const itemDetailDisplay = (state) =>
  state.categoryrank.itemDetailDisplay;

export default fetchCategoryRankSlice.reducer;
