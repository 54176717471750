/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchAsyncRegisterFreePlanDefaultMarketInfo = createAsyncThunk(
  'freePlan',
  async (_, thunkAPI) => {
    const { a_cate1, r_cate1, y_cate1 } = thunkAPI.getState().categorygraph;
    const { otherSelectedBrand } = thunkAPI.getState().brand;
    const { channel, channelName, benchmark } =
      thunkAPI.getState().currentchannel;
    const { accountId } = thunkAPI.getState().user;

    const res = await client.post(`${apiUrl}free_plan/default_market_info`, {
      account_id: accountId,
      channel: channel,
      category_id:
        channel == 1
          ? a_cate1.value
          : channel == 2
          ? r_cate1.value
          : y_cate1.value,
      category_name:
        channel == 1
          ? a_cate1.label
          : channel == 2
          ? r_cate1.label
          : y_cate1.label,
      brand_id: otherSelectedBrand[channelName].id,
      brand_name: otherSelectedBrand[channelName].brand
    });
    return res.data;
  }
);

const initialState = {
  freePlanRegisterSuccess: false
};
const fetchFreePlanDefaultMarketSlice = createSlice({
  name: 'freePlanInfo',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      fetchAsyncRegisterFreePlanDefaultMarketInfo.fulfilled,
      (state, action) => {
        return {
          ...state,
          freePlanRegisterInfosSuccess: true
        };
      }
    );
    builder.addCase(
      fetchAsyncRegisterFreePlanDefaultMarketInfo.rejected,
      (state, action) => {
        // console.log('登録失敗', action.payload);
        return {
          ...state,
          freePlanRegisterInfosSuccess: false
        };
      }
    );
  }
});

// export const freePlanRegisterInfosSuccess = (state) =>
//   state.freePlan.freePlanRegisterInfosSuccess;

export default fetchFreePlanDefaultMarketSlice.reducer;
